import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Images from '../Images';
import Comments from './Comp/Comments';

const Imagemodal = (props) =>{
    const[imagemodal, setImagemodal] = useState(true)
    return(
        <>
         <Modal show={imagemodal} className='thememodal postmodal' centered>
       
        <Modal.Body>
        <div className="modalbg">
                <Row>
                  <Col xl={9} lg={8} md={12} sm={12} xs={12}>
                    <div className="modalview">
                      <img src={Images.post2} className="img-fluid" />
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={12} sm={12} xs={12}>
                     <Comments />
                  </Col>
                      
                 
                      
             
              </Row>
              <button className='fa fa-times btn btn-link modalbtn' onClick={props.onDismiss}></button>
              </div>
        </Modal.Body>
      
      </Modal>
        </>
    )
}

export default Imagemodal