import React, { useEffect, useState } from 'react';
import Images from '../Images';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import Contact from './Contact';
import Hashtag from './Hashtag';
import ActiveUser from './ActiveUser';
import ActiveGroup from './ActiveGroup';
import Community from '../leftsection/Community';
import Suggestion from '../leftsection/Suggestion';
import Story from '../leftsection/Story';
import Nearby from './Nearby';
import RecommendEvent from './Recommendevent';

const RightSection = (props) =>{
  useEffect(() => {
    // top: calc(100vh - 1046px);
    var rightsec = document.getElementById("rightsection")
    var rightheight = rightsec.scrollHeight;
    // alert(rightheight)
    // rightsec.style.top = "calc(100vh - " + rightheight + "px)";
    rightsec.style.top =  "0px";
  })
    return(
      <>
    
        <section id='rightsection' className='sidesections'>
     
            {/* <div className='righthead gap-xxl-3 gap-2 d-flex justify-content-lg-start jc-between align-items-center my-3'>
              <div><button className='themebtn'>Invite</button></div>
              <div className='ms-xl-0 ms-auto'><button className='btn btn-link'><img src={Images.bell} className='img-fluid ' /></button></div>
              <Dropdown className='linkdrop'>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={Images.story1} className='img-fluid ' /> David Marvel
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/">Profile</Dropdown.Item>
                  <Dropdown.Item href="/">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            {/* <div className='mb-3'>
            <InputGroup className=" themesearch">
               <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span></InputGroup.Text>
                  <Form.Control placeholder="Search"
                    aria-label="What's happening ?"
                    aria-describedby="basic-addon2" />
            </InputGroup>
            </div> */}
            {props.rightsection == "prof" ?    
            <div className='gridcont'>
            <div className='whitebox mb-3'>
                <div className='d-flex jc-between align-items-end p-3'>
                  <p className='subhead'>Photos</p>
                  <div> <button className='morebtn'>More</button></div>
                </div>
                <div className='themebord p-0'></div>
                <div className='grid-4 p-3'>
                   <img src={Images.photo1} className='img-fluid' />
                   <img src={Images.photo2} className='img-fluid' />
                   <img src={Images.photo3} className='img-fluid' />
                   <img src={Images.photo4} className='img-fluid' />
                </div>
            </div>
              <div className='whitebox mb-3'>
                  <div className='d-flex jc-between align-items-end p-3'>
                    <p className='subhead'>Events</p>
                    <div> <button className='morebtn'>More</button></div>
                  </div>
                  <div className='themebord p-0'></div>
                  <div className='grid-6 p-3'>
                    <img src={Images.event1}/>
                    <img src={Images.event2}/>
                    <img src={Images.event3}/>
                    <img src={Images.event4}/>
                    <img src={Images.event5}/>
                    <img src={Images.event6}/>
                  </div>
              </div>
              <div className='whitebox mb-3'>
                  <div className='d-flex jc-between align-items-end p-3'>
                    <p className='subhead'>Videos</p>
                    <div> <button className='morebtn'>More</button></div>
                  </div>
                  <div className='themebord p-0'></div>
                  <div className='grid-6 p-3'>
                   <video src={Images.demo} ></video>
                   <video src={Images.demo} ></video>
                   <video src={Images.demo} ></video>
                   <video src={Images.demo} ></video>
                   <video src={Images.demo} ></video>
                   <video src={Images.demo} ></video>
                  </div>
              </div>
            </div> : props.rightsection == "event" ? 
            <div className='gridcont '>
                <div className='whitebox mb-3'>
              <RecommendEvent />
            </div>
              <div className='whitebox mb-3'>
              <Nearby />
            </div>
            </div> : 
             <div className='gridcont '>
             <div className='mb-3'>
               <Contact />
             </div>
             <div className='whitebox mb-3 dnonetill1199'>
               <Story />
             </div>
             <div className='whitebox mb-3'>
               <Hashtag />
             </div>
             <div className='whitebox mb-3'>
               <ActiveUser />
             </div>
             <div className='whitebox mb-3'>
               <ActiveGroup />
             </div>
             <div className='whitebox mb-3 dnonetill1199'>
               <Community />
             </div>
             <div className='whitebox mb-3 dnonetill1199'>
               <Suggestion />
             </div>
             </div>}
            
          
         
           
        </section>
      </>
    )
}

export default RightSection