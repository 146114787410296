import React, {  useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Images from '../Images';
import { Link } from 'react-router-dom';


const HidePost = () =>{
    return(

        <div className='postlists hidepost'>
          <div className='whitebox'>
            <div className='d-flex jc-between p-3'>
              <h6>Hidden group</h6>
              <Link to="/" className=' text-dark nodecore'>View all</Link>
            </div>
             <div className=' p-3 gap-2' >
              <div className='row'>
                <div className='col-md-6  px-2'>
                <div className='whitebox p-3'>
                  <div className='cont'>
                    <div className='d-flex align-items-center mb-2 gap-2'>
                        <img src={Images.prof} className='img-fluid prof' />
                        <div>
                            <h5 className='mb-0'>David</h5>
                            <p className='mb-0 f-14'>Lorem ipsum is dummy content</p>
                        </div>
                    </div>
                    <p className='themeclr mb-0'># Emergency meeting</p>
                    <p className='f-12 mb-3'>Lorem ipsum is a dummy content</p>
                    <div className='d-flex jc-between mb-2 d-unset-in425'> 
                      
                        <p className='mb-0 f-12'>6.00 AM . Jul 13, 2023</p>
                     <div className='d-flex gap-2'> 
                        <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' />12.8k</button>
                        <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/>1k</button>
                        <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/>1k</button>
                      </div>
                    </div>
                   <div>
                    <img src={Images.post1} className='img-fluid postimg' />
                   </div>
                   <div className='d-flex gap-2 mt-3'>
                   <button className='morebtn bordered w-50'><span className='fa fa-eye'></span> Unhide</button>
                      <button className='morebtn dark w-50'><span className='fa fa-trash'></span> Delete</button>
                      </div>
                   </div>
                  
                </div>
                </div>
                <div className='col-md-6 px-2'>
                <div className='whitebox p-3'>
                  <div className='cont'>
                    <div className='d-flex align-items-center mb-2 gap-2'>
                        <img src={Images.prof} className='img-fluid prof' />
                        <div>
                            <h5 className='mb-0'>David</h5>
                            <p className='mb-0 f-14'>Lorem ipsum is dummy content</p>
                        </div>
                    </div>
                    <p className='themeclr mb-0'># Emergency meeting</p>
                    <p className='f-12 mb-3'>Lorem ipsum is a dummy content</p>
                    <div className='d-flex jc-between mb-2 d-unset-in425'> 
                      
                        <p className='mb-0 f-12'>6.00 AM . Jul 13, 2023</p>
                     <div className='d-flex gap-2'> 
                        <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' />12.8k</button>
                        <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/>1k</button>
                        <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/>1k</button>
                      </div>
                    </div>
                   <div>
                    <img src={Images.post1} className='img-fluid postimg' />
                   </div>
                   <div className='d-flex gap-2 mt-3'>
                      <button className='morebtn bordered w-50'><span className='fa fa-eye'></span> Unhide</button>
                      <button className='morebtn dark w-50'><span className='fa fa-trash'></span> Delete</button>
                      </div>
                   </div>
                  
                </div>
                </div>
             
                
               
              </div>
                
             </div>
          </div>
    
       
    </div>
    )
}

export default HidePost