import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Images from '../Images';

const Description = (props) =>{
    const[description, setDescription] = useState(true)
    return(
        <>
         <Modal show={description} className='thememodal ' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title>Write description</Modal.Title>
          <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p>
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            <img src={Images.youtube} className='youtube img'/>
            <img src={Images.all} className='all img'/>
            <div className='position-relative'>
                <textarea className='form-control' placeholder='Description'>Description</textarea>
                <span className='fa fa-check'></span>
            </div>
            <div className='text-end  my-4'>
                <button className='morebtn  me-2'> Cancel </button>
                <button className='morebtn inverse'> Save </button>
            </div>
        </Modal.Body>
      
      </Modal>
        </>
    )
}

export default Description