import React, { useState } from 'react';
import { Dropdown, InputGroup, Modal, Form } from 'react-bootstrap';
import Images from '../Images';

const SearchGroup = (props) =>{
    const[search, setsearch] = useState(true)
    return(
        <>
         <Modal show={search} className='thememodal searchmodal' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title className='mb-3'>Search Group</Modal.Title>
          {/* <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p> */}
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body className='p-4'>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            {/* <img src={Images.youtube} className='youtube img'/> */}
            <img src={Images.all} className='all img'/>
            <div class="input-group mb-3 modalsearch">
  <div class="input-group-prepend">
    <span class="input-group-text"><i className='fa fa-search'></i> </span>
  </div>
  <input type="text" class="form-control" placeholder='Search this group' />
  <div class="input-group-append">
    <span class="input-group-text"><i className='fa fa-times'></i></span>
  </div>
</div>
         
            <p className='f-14 blue'>Explore this group</p>
            <ul>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <img src={Images.prof1} className="prof"/>
                        <div>
                            <h6 className='mb-0 '>Bitcoinword</h6>
                            <p className='mb-0 f-12'>@Bitcoinword</p>
                        </div>
                    </div> 
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <img src={Images.prof} className="prof"/>
                        <div>
                            <h6 className='mb-0 '>Bitcoinword</h6>
                            <p className='mb-0 f-12'>@Bitcoinword</p>
                        </div>
                    </div> 
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <img src={Images.prof1} className="prof"/>
                        <div>
                            <h6 className='mb-0 '>Bitcoinword</h6>
                            <p className='mb-0 f-12'>@Bitcoinword</p>
                        </div>
                    </div> 
                </li>
            </ul>
        
        {/* <div className='text-end'>
           <button className='morebtn dark me-2'>Delete</button>
           <button className='morebtn bordered'>Cancel</button>
          </div> */}
        </Modal.Body>
       
      
      </Modal>
        </>
    )
}

export default SearchGroup