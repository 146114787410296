import React, { useState } from 'react';
import Images from '../Images';
import Videomodal from '../Modals/VideoModal';

   

const Videos = () =>{
    const[videomodal, setVideomodal] = useState(false)
    return(
        <>
        <div className='d-grid part3'>
          { videomodal &&  <Videomodal onDismiss={() => setVideomodal(false)} />}
          <div className=''>
                <div className='position-relative mb-2'>
                <img src={Images.post1} className='thumb' />
                <button className='fa fa-play' onClick={() => setVideomodal(true)}></button>
                <div className='videotime'>
                    02.05
                </div>
               </div> 
               <p className='mb-2 f-14'>Lorem ipsum is a dummy content em ipsum is a dumm </p>
                <div className='text-secondary f-14'> 
                    <span className='linkbtn px-0 '> <img src={Images.fireblue} className='img-fluid me-1' /> 12k</span>
                    <span className='linkbtn px-0 mx-2'> <img src={Images.commentblue}  className='img-fluid me-1'/> 1k</span>
                    <span className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/> 1.2k</span>
                </div>
                <span className='text-secondary f-14'>1.50PM. July 13</span>
            </div>
            <div className=''>
                <div className='position-relative mb-2'>
                <img src={Images.post2} className='thumb' />
                <button className='fa fa-play' onClick={() => setVideomodal(true)}></button>
                <div className='videotime'>
                    02.05
                </div>
               </div> 
               <p className='mb-2 f-14'>Lorem ipsum is a dummy content em ipsum is a dumm </p>
                <div className='text-secondary f-14'> 
                    <span className='linkbtn px-0 '> <img src={Images.fireblue} className='img-fluid me-1' /> 12k</span>
                    <span className='linkbtn px-0 mx-2'> <img src={Images.commentblue}  className='img-fluid me-1'/> 1k</span>
                    <span className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/> 1.2k</span>
                </div>
                <span className='text-secondary f-14'>1.50PM. July 13</span>
            </div>
            <div className=''>
                <div className='position-relative mb-2'>
               
                    <img src={Images.post3} className='thumb'  />
              
                <button className='fa fa-play' onClick={() => setVideomodal(true)}></button>
                <div className='videotime'>
                    02.05
                </div>
               </div> 
               <p className='mb-2 f-14'>Lorem ipsum is a dummy content em ipsum is a dumm </p>
                <div className='text-secondary f-14'> 
                    <span className='linkbtn px-0 '> <img src={Images.fireblue} className='img-fluid me-1' /> 12k</span>
                    <span className='linkbtn px-0 mx-2'> <img src={Images.commentblue}  className='img-fluid me-1'/> 1k</span>
                    <span className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/> 1.2k</span>
                </div>
                <span className='text-secondary f-14'>1.50PM. July 13</span>
            </div>
            <div className=''>
                <div className='position-relative mb-2'>
                <img src={Images.post1} className='thumb' />
                <button className='fa fa-play' onClick={() => setVideomodal(true)}></button>
                <div className='videotime'>
                    02.05
                </div>
               </div> 
               <p className='mb-2 f-14'>Lorem ipsum is a dummy content em ipsum is a dumm </p>
                <div className='text-secondary f-14'> 
                    <span className='linkbtn px-0 '> <img src={Images.fireblue} className='img-fluid me-1' /> 12k</span>
                    <span className='linkbtn px-0 mx-2'> <img src={Images.commentblue}  className='img-fluid me-1'/> 1k</span>
                    <span className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/> 1.2k</span>
                </div>
                <span className='text-secondary f-14'>1.50PM. July 13</span>
            </div>
            <div className=''>
                <div className='position-relative mb-2'>
                <img src={Images.post2} className='thumb' />
                <button className='fa fa-play' onClick={() => setVideomodal(true)}></button>
                <div className='videotime'>
                    02.05
                </div>
               </div> 
               <p className='mb-2 f-14'>Lorem ipsum is a dummy content em ipsum is a dumm </p>
                <div className='text-secondary f-14'> 
                    <span className='linkbtn px-0 '> <img src={Images.fireblue} className='img-fluid me-1' /> 12k</span>
                    <span className='linkbtn px-0 mx-2'> <img src={Images.commentblue}  className='img-fluid me-1'/> 1k</span>
                    <span className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/> 1.2k</span>
                </div>
                <span className='text-secondary f-14'>1.50PM. July 13</span>
            </div>
            <div className=''>
                <div className='position-relative mb-2'>
               
                    <img src={Images.post3} className='thumb'  />
              
                <button className='fa fa-play' onClick={() => setVideomodal(true)}></button>
                <div className='videotime'>
                    02.05
                </div>
               </div> 
               <p className='mb-2 f-14'>Lorem ipsum is a dummy content em ipsum is a dumm </p>
                <div className='text-secondary f-14'> 
                    <span className='linkbtn px-0 '> <img src={Images.fireblue} className='img-fluid me-1' /> 12k</span>
                    <span className='linkbtn px-0 mx-2'> <img src={Images.commentblue}  className='img-fluid me-1'/> 1k</span>
                    <span className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/> 1.2k</span>
                </div>
                <span className='text-secondary f-14'>1.50PM. July 13</span>
            </div>
        </div>
       
        </>
    )
}


export default Videos