import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Images from '../Images';
import { Dropdown, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';

import Linksec from '../Rightsection.js/Linksec';
import Bio from '../Modals/Biomodal';
import About from '../leftsection/About';
import Community from '../Community';
import Imagemodal from '../Modals/ImageModal';
import Videomodal from '../Modals/VideoModal';
import CreatePost from '../Modals/CreatePost';
import EditPost from '../Modals/EditPost';
import DeletePost from '../Modals/DeletePost';
import SettingModal from '../Modals/SettingModal';
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";




const DiscoverEvent = () =>{
const[width, setWidth] = useState()
const[bio, setBio] = useState(false)
useEffect(() => {
  var bgwidth = document.getElementById("centersection").scrollWidth;
  setWidth(bgwidth)
},[])

const options1 = {
    loop: true,
    margin: 15,
    nav: true,
    dots: false,
    smartSpeed: 1200,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };

const Wrapper = styled.section 
  `
  &:before{
    content: "";
    position: fixed;
    background: #fff;
    height: 109px;
    top: 0;
    // left: 15px;
    // right: 0;
    margin: auto;
    z-index: 9;
    width:${width + "px"};
    border-radius: 0 0 11px 11px;
  }
 
  `

  const[imagemodal, setImagemodal] = useState(false)
  const[videomodal, setVideomodal] = useState(false)
  const[createpost, setCreatePost] = useState(false)
  const[editpost, setEditPost] = useState(false)
  const[deletepost, setDeletePost] = useState(false)
  const[setting, setSetting] = useState(false)
  const [checked, setChecked] = useState(false)




  const [startDate, setStartDate] = useState(new Date());
  
    return(
      <>

        {imagemodal && <Imagemodal onDismiss={() => setImagemodal(false)}/>}
        {videomodal && <Videomodal onDismiss={() => setVideomodal(false)}/>}
        {createpost && <CreatePost onDismiss={() => setCreatePost(false)}/>}
        {editpost && <EditPost onDismiss={() => setEditPost(false)}/>}
        {deletepost && <DeletePost onDismiss={() => setDeletePost(false)}/>}
        {setting && <SettingModal onDismiss={() => setSetting(false)}/>}
        {bio && <Bio onDismiss= {() => setBio(false)}/>}
       
        <section id='centersection' className='posts profile friends'>
           <div className='showin1199 showrightsec'><Linksec/></div>  
             <div className='postlists request'>
                {/* <Wrapper className='wrap'> */}
                    <Tabs defaultActiveKey="sports" id="uncontrolled-tab-example" className="my-2 themetab" >
                        <Tab eventKey="all" title="All">
                            <div className='whitebox px-sm-3 px-2 py-3 my-4'>
                                <div className='bordbtm topsearch mb-3'>
                                    <div className='pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start'>
                                        <h6 className='f-14 fw-500'>Discover Event</h6>
                                    </div>
                                </div>
                                <div className='row m-1 evenflow'>
                                    <div className='col-md-6 watchleft'>
                                        <div className='eventbanner'>
                                            <p className='f-12 mb-2'>Lorem ipsum is a dummy content</p>
                                            <h6>Highlight | Pakistan vs srilanka | T20I PCB | MA2A</h6>
                                        </div>
                                        <div className='d-flex jc-between'>
                                            <ul className='imglist'>
                                                <li><img src={Images.post1} className='img-fluid' /> </li>
                                                <li><img src={Images.post2} className='img-fluid' /> </li>
                                                <li><img src={Images.post3} className='img-fluid' /> </li>
                                                <li><img src={Images.post4} className='img-fluid' /> </li>
                                            </ul>
                                            
                                        </div> 
                                    </div>
                                    <div className='col-md-6 watchright'>
                                        <img src={Images.post1} className='img-fluid watchimg' />
                                        <div>
                                            <button className='btn'>Watch</button>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className='whitebox px-sm-3 px-2 py-3 my-4'>
                                <OwlCarousel className="owl-theme" {...options1}>
                                    <div className='eventcard'>
                                   <div className='evenbtn'>
                                   <Dropdown className="postdrop">
                                        <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-v"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                   </div>
                                        <img src={Images.friends1} className='eventimg'/>
                                         <div className='eventcont'> 
                                            <p className='blue f-12 mb-2'>SUN, 2 APR</p>
                                            <h6>Free Live Drawing workshop with Liz Steel</h6>
                                            <p className='text-secondary f-12'>Lorem ipsunm is a dummy content</p>
                                            <div className='bordbtm pb-2 d-flex jc-between align-items-center'>
                                                <div className='text-secondary f-12'><span className='fa fa-clock-o'></span> Ongoing</div>
                                                <div>  
                                                    <button className='morebtn small me-1'><i class="fa fa-share"></i></button>
                                                    <button className='morebtn small'><span className='far fa-bookmark'></span></button>
                                                </div>
                                            </div>
                                            <div className='py-2 d-flex jc-between align-items-center'> 
                                                <div className='d-flex align-items-center'>
                                                    <div className='lineimg'>
                                                        <img src={Images.prof} />
                                                        <img src={Images.prof} />
                                                        <img src={Images.prof} />
                                                    </div>  
                                                    <span className='text-secondary f-12 ms-5'>3.5k Interested</span>
                                                </div>
                                                <button className='btn morebtn'>Interested</button>
                                            </div>
                                        </div> 

                                    </div>
                                </OwlCarousel>
                            </div>
                        </Tab>
                        <Tab eventKey="sports" title="Sports">
                            <div className='whitebox p-3 mb-3'>
                                <div className='d-flex flex-1 gap-2'>
                                    {/* <select className='borderedselect iconselect form-control'>
                                        <option>&#xf3c5; My location</option>
                                    </select> */}
                                     <Dropdown className='w-full selectdrop'>
      <Dropdown.Toggle id="dropdown-basic" className='w-full f-12 flex-between bordered'>
      <span> <span className='fa fa-map-marker'></span> My location</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-2'>
        <input type="text"  className='form-control'/>
        <div className='flex-between f-14 my-2'>
            <div>
                <span className='fa fa-paper-plane me-2'></span>
                My location
            </div>
            <label class="custcheck radio">
                <input type="radio" name="radio" />
                <span class="checkmark"></span>
            </label>
        </div>
        <div className='flex-between f-14 my-2'>
            <div>
                <span className='fa fa-globe me-2'></span>
                Online
            </div>
            <label class="custcheck radio">
                <input type="radio" name="radio" />
                <span class="checkmark"></span>
            </label>
        </div>
       
      </Dropdown.Menu>
    </Dropdown>
<div>  <DatePicker showIcon placeholderText='Any date' selected={startDate} onChange={(date) => setStartDate(date)} className='form-control dateinput w-full'/></div> 
   <Dropdown className='w-full selectdrop'>
      <Dropdown.Toggle id="dropdown-basic" className='w-full f-12 flex-between bordered'>
      <span> <span className='fa fa-map-marker'></span> Category</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-2'>
        <div className='flex-between f-14 my-2'>
            <div>
                <span className='fa fa-paper-plane me-2'></span>
                Top
            </div>
            <label class="custcheck radio">
                <input type="radio" name="radio" />
                <span class="checkmark"></span>
            </label>
        </div>
        <div className='flex-between f-14 my-2'>
            <div>
                <span className='fa fa-globe me-2'></span>
               Friends
            </div>
            <label class="custcheck radio">
                <input type="radio" name="radio" />
                <span class="checkmark"></span>
            </label>
        </div>
       
      </Dropdown.Menu>
    </Dropdown>
</div>
                                <div className='d-sm-flex jc-between gap-2 mt-3'>
                                   <div className='themesearch w-full mb-sm-0 mb-3'>
                                    <input className='form-control' placeholder='search'/>
                                    </div> 
                                    <div className='ty d-flex jc-end gap-2'> 
                                        <div className={checked ? 'switch_div align-items-center d-flex gap-1 switch_div_length switch_div_1 active_check f-12' : 'switch_div align-items-center d-flex gap-1 switch_div_length switch_div_1 f-12'}>
                                            <div> 
                                              <span className='fa fa-globe circle p-1 f-12'></span>
                                            </div> Online
                                            <label className="switch">
                                                <input type="checkbox" role="switch" onChange={(e) => { console.log(e.target.checked); setChecked(e.target.checked) }} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className={checked ? 'switch_div align-items-center d-flex gap-1 switch_div_length switch_div_1 active_check f-12' : 'switch_div align-items-center d-flex gap-1 switch_div_length switch_div_1 f-12'}>
                                            <div> 
                                            <span className='fa fa-money circle p-1 f-12'></span>
                                            </div> Pay
                                            <label className="switch">
                                                <input type="checkbox" role="switch" onChange={(e) => { console.log(e.target.checked); setChecked(e.target.checked) }} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className='hoverlists'>
                                <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                    <div className='d-flex w-full align-items-center'>
                                        <img src={Images.story3} className='friendimg'/>
                                        <div className='w-full'>
                                            <Link to="/eventprof" className='nostyle text-dark'>
                                            <h6 className='mb-sm-0 mb-2'>David</h6></Link>
                                            <div className='showin575 shrink-0'>
                                                <button className='morebtn inverse me-2'>Interested</button>
                                                <Dropdown className="postdrop noafter d-inline-block">
                                        <Dropdown.Toggle variant="" className="morebtn small text-light noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-v"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                        </div>
                                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            <h6 className='mb-0 f-12 resultcont'>25 friends</h6>
                                        </div>
                                    </div>
                                    <div  className='rightbtn shrink-0 d-flex'>
                                        <button className='morebtn inverse me-2'>Interested</button>
                                        <Dropdown className="postdrop noafter">
                                        <Dropdown.Toggle variant="" className="morebtn small text-light noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-v"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                </li>
                                <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                    <div className='d-flex w-full align-items-center'>
                                        <img src={Images.story4} className='friendimg'/>
                                        <div className='w-full'>
                                           <Link to="/friendprof" className='nostyle text-dark'>
                                           <h6 className='mb-sm-0 mb-2'>David</h6></Link>
                                           <div className='showin575 shrink-0'>
                                                <button className='morebtn inverse me-2'>Interested</button>
                                                <Dropdown className="postdrop noafter d-inline-block">
                                        <Dropdown.Toggle variant="" className="morebtn small text-light noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-v"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                            </div>
                                            <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                                        </div>
                                        <div  className='rightbtn shrink-0 d-flex'>
                                        <button className='morebtn inverse me-2'>Interested</button>
                                        <Dropdown className="postdrop noafter">
                                        <Dropdown.Toggle variant="" className="morebtn small text-light noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-v"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                    </li>
                                    <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                <div className='d-flex w-full align-items-center'>
                                            <img src={Images.story6} className='friendimg'/>
                                            <div className='w-full'>
                                           <Link to="/friendprof" className='nostyle text-dark'>
                                           <h6 className='mb-sm-0 mb-2'>David</h6></Link>
                                           <div className='showin575 shrink-0'>
                                                <button className='morebtn inverse me-2'>Interested</button>
                                                <Dropdown className="postdrop noafter d-inline-block">
                                        <Dropdown.Toggle variant="" className="morebtn small text-light noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-v"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                            </div>
                                            <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                                        </div>
                                        <div  className='rightbtn shrink-0 d-flex'>
                                        <button className='morebtn inverse me-2'>Interested</button>
                                        <Dropdown className="postdrop noarrow noafter">
                                        <Dropdown.Toggle variant="" className="morebtn small text-light noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-v"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                    </li>
                            </ul>
                        </Tab>
                        <Tab eventKey="comedy" title="Comedy"> </Tab>
                        <Tab eventKey="Craft" title="Craft"> </Tab>
                        <Tab eventKey="Dance" title="Dance"> </Tab>
                        <Tab eventKey="Drinks" title="Drinks"> </Tab>
                        <Tab eventKey="Fitness" title="Fitness"> </Tab>
                        <Tab eventKey="More" title="More"> </Tab>
                        
                    </Tabs>
              {/* </Wrapper> */}
             </div>
             
        </section>
        </>
    )
}

export default DiscoverEvent