import React, { useState } from 'react';
import { Dropdown, InputGroup, Modal, Form } from 'react-bootstrap';
import Images from '../Images';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const InviteGuest = (props) =>{
    const[inviteguest, setInviteGuest] = useState(true)
    const [startDate, setStartDate] = useState(new Date());
    const[drop, setDrop] = useState(false)
    const[drop1, setDrop1] = useState(false)
    const[drop2, setDrop2] = useState(false)
    return(
        <>
         <Modal show={inviteguest} className='thememodal searchmodal' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title className='mb-3'>Invite</Modal.Title>
          {/* <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p> */}
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body className='p-4'>
            <div class="input-group mb-3 modalsearch">
                <div class="input-group-prepend">
                    <span class="input-group-text"><i className='fa fa-search'></i> </span>
                </div>
                <input type="text" class="form-control" placeholder='Description' />
            </div>
               
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            {/* <img src={Images.youtube} className='youtube img'/> */}
            <img src={Images.all} className='all img'/>
           
        
         <div className="whitebox mt-3">
        <div className="bordbtm px-sm-3 px-2 topsearch">
            <div className="pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start d-sm-flex jc-between align-items-center">
                <h6 className="fw-500 mb-0">Promote your event</h6>
                <button className='btn btn-link themeclr f-12 mb-0 ps-0'><span className='fa fa-cog themeclr'></span> Invitation link settings</button>
            </div>
        </div>
        <div className='d-sm-flex jc-between flex-1 p-3 mb-3'>
            <button className='btn modalbtn d-flex jc-between align-items-center me-2 f-12 mb-sm-0 mb-2'> <i className='fa fa-user-plus'></i> Suggested <span className='fa fa-chevron-right'></span></button>
            <button className='btn modalbtn d-flex jc-between align-items-center me-2 f-12'> <i className='fa fa-edit'></i> My groups <span className='fa fa-chevron-right'></span></button>
        </div>
    </div>

    <textarea className='form-control mb-3' rows="10"></textarea>
    <p className='f-12'>500 INVITATION LEFT</p>
         
        <div className='text-end'>
        <button className='morebtn py-2 me-2'>Cancel</button>
           <button className='morebtn dark py-2'>Send invitations</button>
          </div>
        </Modal.Body>
       
      
      </Modal>
        </>
    )
}

export default InviteGuest