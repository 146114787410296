import React, { useEffect, useRef } from 'react';
import Images from '../Images';
import { Link } from 'react-router-dom';
import GroupCard from '../Common/GroupCard';

const YourGroup = () =>{
 
    return(
        <div className='postlists '>
           <div className='whitebox mb-4'>
                <div className='d-flex jc-between p-3'>
                    <h6>All group you've joined</h6>
                    <Link to="/" className=' text-dark nodecore'>View all</Link>
                </div>
                <div className='scrollcards p-3'>
                  <GroupCard/>
                </div>

                <div className='scrollcards p-3'>
                  <GroupCard />
                </div>
            </div>

           
       </div>
    )
}

export default YourGroup