import React, { useState } from 'react';
import { Button, Container, Nav,Form, NavDropdown, Navbar, Dropdown, InputGroup, NavLink } from 'react-bootstrap';
import Images from '../Images';
import { Link } from 'react-router-dom';
const LandHead = () =>{
   
return(
        <>
       
          <Navbar bg="light" expand="xl" className='landheader'>
      <div className='px-3 d-flex justify-content-between w-full'>
        <Navbar.Brand href="#">
            <img src={Images.logo} className='img-fluid'/> 
        </Navbar.Brand>
            
        <Navbar.Toggle aria-controls="navbarScroll"/>
        <Navbar.Collapse id="navbarScroll" className='align-items-center'>
          <Nav
            className="my-2 my-lg-0 justify-content-start"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
           <NavLink href="/land">Home</NavLink>
             <NavLink href="/dfgf">Why</NavLink>
            <NavLink href="#action2">User Features</NavLink>
            <NavLink href="#action2">Technical Features</NavLink>
            <NavLink href="#action2">Site Features</NavLink>
            <NavLink href="#action2">Team</NavLink>
            <NavDropdown title="Pages" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">
                  <div><img src={Images.users} /> Team <span className='countbadge'>1K</span> </div>
                  <span className='fa fa-check'></span>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action4">
              <div><img src={Images.comment} /> Group <span className='countbadge'>1K</span> </div>
                  <span className='fa fa-check active'></span>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
              <div><img src={Images.clock} /> Events <span className='countbadge'>200</span> </div>
                  <span className='fa fa-check'></span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
      
          <div className='righthead gap-xxl-3 gap-2 d-flex justify-content-lg-start jc-between align-items-center ms-auto'>
          <div><Link to="/signup">
             <button className='themebtn py-2'>Signup with Password</button></Link></div>
          <div><Link to="/captchalogin"><button className='themebtn py-2'>Signup with wallet</button></Link></div>
             
             
            </div>
         
        </Navbar.Collapse>
      </div>
    </Navbar>
        </>
    )
}

export default LandHead