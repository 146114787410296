// import package
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';


// import action
import { register, walletregister,googleregister} from '../Actions/register';
import {getData} from '../Actions/setProfilepic'

const HelperRoute = () => {
    const dispatch = useDispatch();
    // const history = useHistory();
    // redux-state

    const token=localStorage.getItem("token")


    // function
    useEffect(() => {
        if (token) {
            getData(dispatch)
           
        }
    }, [token])

    return <div />
}

export default HelperRoute;