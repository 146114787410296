import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Images from './Images';
import { Dropdown, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import Linksec from './Rightsection.js/Linksec';
import Bio from './Modals/Biomodal';
import Imagemodal from './Modals/ImageModal';
import Videomodal from './Modals/VideoModal';
import CreatePost from './Modals/CreatePost';
import EditPost from './Modals/EditPost';
import DeletePost from './Modals/DeletePost';

import FollowModal from './Modals/FollowModal';

import SettingModal from './Modals/SettingModal';
import Message from './Modals/Message';

import Profileinfo from './profile/Profileinfo'
import About from './leftsection/About';
import Profilephotos from './profile/Profilephotos'
import Videos from './Common/Videos'
import Profilefeature from './profile/Profilefeature'
import { getData, getProfileData } from '../Actions/setProfilepic';
import { requestFollow } from '../Actions/requestfollow';


const Otherprofile = () => {
  const [width, setWidth] = useState()
  const [Edit, setEdit] = useState(false)
  const [bio, setBio] = useState(false)


  const [imagemodal, setImagemodal] = useState(false)
  const [videomodal, setVideomodal] = useState(false)
  const [createpost, setCreatePost] = useState(false)
  const [editpost, setEditPost] = useState(false)
  const [deletepost, setDeletePost] = useState(false)
  const [setting, setSetting] = useState(false)

  const [messagebox, setMessagebox] = useState(false);

  const [premium, setPremium] = useState(true);

  const [followmodal, setFollowmodal] = useState(false)


  const [profimg, setprofimg] = useState(
    [
      Images.photo1,
      Images.photo2,
      Images.photo3,
      Images.photo4,
      Images.photo1,
      Images.photo2,
      Images.photo3,
      Images.photo4
    ]
  )

  //
  const [userdata, setUserData] = useState({});
  const [userprofiledata, setUserProfileData] = useState({});
  const [followdata, setFollowData] = useState({});
console.log("follow_data", followdata)

  useEffect(() => {
    userData();
    userProfileData()
  }, [])

  var userData = async () => {
    try {
      var res = await getData();
      console.log("respond_Data", res, res?.data?.data?.userData)
      if (res.status == 200) {
        setUserData(res?.data?.data?.userData)
      }

    } catch (err) {
      console.log("errrrrrr", err)
    }
  }

  var userProfileData = async () => {

    try {
      let username = window.location.pathname.split('/')[2]?.toString();
      console.log("pathname", username);
      var res = await getProfileData(username);
      console.log("res_profile_data", res?.data?.data?.user);
      if (res?.status == 200) {
        setUserProfileData(res?.data?.data?.user)
      }
    } catch (err) {
      console.log("errrrrrrrr", err)
    }
  }


  const FollowRequest = async () => {

    try {
      console.log("userdata & userprofiledata", userdata, userprofiledata)
      let reqData = {
        from: userdata?._id,
        to: userprofiledata?._id,
        content: "send friendly request",
        type: "Follow"
      }
      console.log("reqData_follow", reqData)
      const res = await requestFollow(reqData);
      console.log("dfasdfasdfasdf", res?.data?.requestdata?.data);
      if (res.status == 200) {
        setFollowData(res?.data?.requestdata?.data)
      }
    } catch (err) {
      console.log("errrrrrrrr", err)
    }
  }


  useEffect(() => {
    var bgwidth = document.getElementById("centersection").scrollWidth;
    setWidth(bgwidth)
  }, [])
  const Wrapper = styled.section
    `
  &:before{
    content: "";
    position: fixed;
    background: #fff;
    height: 109px;
    top: 0;
    // left: 15px;
    // right: 0;
    margin: auto;
    z-index: 9;
    width:${width + "px"};
    border-radius: 0 0 11px 11px;
  }
 
  `



  return (
    <>
      {imagemodal && <Imagemodal onDismiss={() => setImagemodal(false)} />}
      {videomodal && <Videomodal onDismiss={() => setVideomodal(false)} />}
      {createpost && <CreatePost onDismiss={() => setCreatePost(false)} />}
      {editpost && <EditPost onDismiss={() => setEditPost(false)} />}
      {deletepost && <DeletePost onDismiss={() => setDeletePost(false)} />}
      {setting && <SettingModal onDismiss={() => setSetting(false)} />}
      {bio && <Bio onDismiss={() => setBio(false)} />}

      {followmodal && <FollowModal onDismiss={() => setFollowmodal(false)} />}


      {messagebox && <Message onDismiss={() => setMessagebox(false)} />}

      <section id='centersection' className='posts profile'>

        <div className='showin1199 showrightsec'><Linksec /></div>


        <div className='postlists'>
          <div className='whitebox '>
            <div className='profilewall'>
              <img src={Images.profilebg} className='img-fluid br-5 me-1' />
              <div className='wallcont'>
                <label>Blockchain crypto</label>
                {/* <h3>David Marvel</h3> */}
                <h3>{userprofiledata?.firstName}</h3>
              </div>

              {Edit ? <button className='btn btn-link p-0 editbtn'> <input type="file" /><span className='fa fa-camera f-12 text-dark'></span></button> : ""}
            </div>
            <div className='d-sm-flex jc-between gap-2 p-sm-3 p-2  pb-2 profilestat'>
              <div className='d-flex'>
                <div className='d-sm-flex d-none'>
                  <img src={Images.prof} className='img-fluid br-5 me-1 profimg' />
                </div>
                <div>
                  {/* //Bitcoinworld */}
                  <p className='d-flex mb-0 align-items-center'> <h5 className='mb-1'>{userprofiledata?.firstName}</h5>
                    {premium ? <badge className='btn btn_prem ms-2'><span className='fa fa-check f-12 text-white'></span>
                    </badge> : ""}
                  </p>

                    


                  <p className='d-flex mb-0'>   <p className='f-12 text-muted mb-sm-3 mb-2' onClick={() => setBio(true)}>Lorem ipsum is a dummy content
                    {Edit ? <button className='btn btn-link p-0 editbtn'><span className='fa fa-pencil f-12 text-secondary'></span></button> : ""}</p></p>
                  <div className='d-flex'>
                    {/* <button className='btn themebtn me-2' onClick={() => { setFollowmodal(true) }}>Follow</button> */}
                   {followdata?.status == "Accepted" ? <button className='btn themebtn me-2' onClick={() => { FollowRequest() }}> Following </button> : <> { followdata?.status == "Pending" ? <button className='btn themebtn me-2'>Requested</button> : <button className='btn themebtn me-2' onClick={() => { FollowRequest() }}> Follow </button> } </> }
                    <button className='btn bordbtn' onClick={() => { setFollowmodal(true) }}>183 Followers</button>
                  </div>
                </div>
              </div>
              <div className='mt-sm-0 mt-2'>
                <button className='morebtn me-2' onClick={() => setMessagebox(!messagebox)}>Message</button>
                <button className='morebtn px-2'><span className='fa fa-heart'></span></button>
              </div>
            </div>

          </div>
          <div className='pb-4'>

            <Tabs defaultActiveKey="post" id="uncontrolled-tab-example" className="my-2 themetab" >

              <Tab eventKey="post" title="Post">
                <Profileinfo />
                <div className='whitebox'>
                  <div className='px-sm-3 px-2 topsearch py-4'>
                    <About />
                  </div>
                </div>
                <div className='whitebox mt-3'>
                  <div className='px-sm-3 px-2 topsearch pt-4 pb-2'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                      <h6>Photos</h6>
                      <a className='view_link'>View All</a>
                    </div>

                    <div className='row'>
                      {profimg.slice(0, 4).map((img, val) =>

                        <div className='col-12 col-sm-6 col-md-3 mb-3 mb-md-3'>
                          <Profilephotos image={img} />

                        </div>
                      )}
                    </div>




                    {/* {profimg.map()=>
                        {
                            <Profilephotos />

                        }} */}
                  </div>
                </div>


                <div className='whitebox mt-3'>
                  <div className='px-sm-3 px-2 topsearch py-4'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                      <h6>Most Popular videos</h6>
                    </div>

                    <div className='allposts allpost_inside'>
                      <div className='position-relative'>
                        <div className='video_tag'>

                          <video width="320" height="240" controls muted autoPlay={"autoplay"} preLoad="auto" loop>

                            <source src={Images.watervideo} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>

                        {/* <video autoPlay={true} loop>
                        <source src={Images.demo} type="video/mp4" />
                      </video> */}


                      </div>
                      <div className='p-3 px-0'>
                        <div className='dopost mb-sm-3 mb-0'>
                          <div className='d-flex jc-between'>
                            <div className=''>

                              <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                              <button className='linkbtn px-0'> <img src={Images.commentblue} className='img-fluid me-1' /></button>
                              <button className='linkbtn px-0'> <img src={Images.shareblue} className='img-fluid me-1' /></button>
                              <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                              <button className='linkbtn ms-sm-2 ms-0 donate '>
                                <i class="fas fa-hand-holding-medical showin575"></i>
                                <span className='hidein575'>Donate</span> </button>
                            </div>

                            <div>
                              <button className='morebtn me-1 donate'> 17 minutes</button>
                              <button className='linkbtn px-0'> <img src={Images.eyeblue} className='img-fluid me-1' /></button>
                              <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                            </div>
                          </div>
                        </div>
                        <div className='mobilecomment'>
                          <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                          <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p>
                        </div>
                        <div className='commenttext'>
                          <p>
                            <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut
                            consectetur iure perspiciatis tempora excepturi quia saepe porro illum?
                            Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                          </p>
                        </div>
                        <div>
                          <div className='d-flex gap-1'>
                            <InputGroup className="graysearch ">
                              <Form.Control onClick={() => setCreatePost(true)}
                                placeholder="What's happening ?"
                                aria-label="What's happening ?"
                                aria-describedby="basic-addon2" />
                              <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                              <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                              <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                            </InputGroup>
                            <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='whitebox mt-3'>
                  <div className='px-sm-3 px-2 topsearch py-4'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                      <h6>Latest videos</h6>
                      <a className='view_link'>View All</a>
                    </div>
                    <Videos />
                  </div>
                </div>


                <div className='whitebox mt-3'>
                  <div className='px-sm-3 px-2 topsearch py-4'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                      <h6>Featured</h6>
                      <a className='view_link'>View All</a>
                    </div>
                    <Profilefeature />
                  </div>
                </div>

                <div className='allposts'>
                  <div className='position-relative'>
                    <img src={Images.post2} className='img-fluid' />
                    <div className='d-flex postdet'>
                      <div className='d-flex jc-between w-full align-items-center'>
                        <div className='d-flex align-items-center'>
                          <img src={Images.story1} className='img-fluid b-50 me-1' />
                          <div>
                            <p className='mb-0 text-light f-14 fw-500'>David</p>
                            <p className='mb-0 text-light'>Lorem ipsum</p>
                          </div>
                        </div>
                        <div>
                          <Dropdown className="postdrop">
                            <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                              <span className="fas fa-ellipsis-v"></span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => setEditPost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                              <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                              <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-3'>
                    <div className='dopost mb-sm-3 mb-0'>
                      <div className='d-flex jc-between'>
                        <div className=''>

                          <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                          <button className='linkbtn px-0'> <img src={Images.commentblue} className='img-fluid me-1' /></button>
                          <button className='linkbtn px-0'> <img src={Images.shareblue} className='img-fluid me-1' /></button>
                          <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                          <button className='linkbtn ms-sm-2 ms-0 donate '>
                            <i class="fas fa-hand-holding-medical showin575"></i>
                            <span className='hidein575'>Donate</span> </button>
                        </div>

                        <div>
                          <button className='morebtn me-1 donate'> 17 minutes</button>
                          <button className='linkbtn px-0'> <img src={Images.eyeblue} className='img-fluid me-1' /></button>
                          <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                        </div>
                      </div>
                    </div>
                    <div className='mobilecomment'>
                      <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                      <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p>
                    </div>
                    <div className='commenttext'>
                      <p>
                        <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut
                        consectetur iure perspiciatis tempora excepturi quia saepe porro illum?
                        Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                      </p>
                    </div>
                    <div>
                      <div className='d-flex gap-1'>
                        <InputGroup className="graysearch ">
                          <Form.Control onClick={() => setCreatePost(true)}
                            placeholder="What's happening ?"
                            aria-label="What's happening ?"
                            aria-describedby="basic-addon2" />
                          <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                          <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                          <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                        </InputGroup>
                        <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='allposts'>
                  <div className='position-relative'>
                    <img src={Images.post3} className='img-fluid' />
                    <div className='d-flex postdet'>
                      <div className='d-flex jc-between w-full align-items-center'>
                        <div className='d-flex align-items-center'>
                          <img src={Images.story1} className='img-fluid b-50 me-1' />
                          <div>
                            <p className='mb-0 text-light'>David</p>
                            <p className='mb-0 text-light'>Lorem ipsum</p>
                          </div>
                        </div>
                        <div>
                          <Dropdown className="postdrop">
                            <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                              <span className="fas fa-ellipsis-v"></span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => setEditPost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                              <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                              <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-3'>
                    <div className='dopost mb-sm-3 mb-0'>
                      <div className='d-flex jc-between'>
                        <div className=''>

                          <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                          <button className='linkbtn px-0'> <img src={Images.commentblue} className='img-fluid me-1' /></button>
                          <button className='linkbtn px-0'> <img src={Images.shareblue} className='img-fluid me-1' /></button>
                          <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                          <button className='linkbtn ms-sm-2 ms-0 donate '>
                            <i class="fas fa-hand-holding-medical showin575"></i>
                            <span className='hidein575'>Donate</span> </button>
                        </div>
                        <div>
                          <button className='morebtn me-1 donate'> 17 minutes</button>
                          <button className='linkbtn px-0'> <img src={Images.eyeblue} className='img-fluid me-1' /></button>
                          <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                        </div>
                      </div>
                    </div>
                    <div className='mobilecomment'>
                      <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                      <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p>
                    </div>
                    <div className='commenttext'>
                      <p>
                        <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut
                        consectetur iure perspiciatis tempora excepturi quia saepe porro illum?
                        Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                      </p>
                    </div>
                    <div>
                      <div className='d-flex gap-1'>
                        <InputGroup className="graysearch ">
                          <Form.Control onClick={() => setCreatePost(true)}
                            placeholder="What's happening ?"
                            aria-label="What's happening ?"
                            aria-describedby="basic-addon2" />
                          <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                          <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                          <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                        </InputGroup>
                        <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="about" title="About">
                <div className='whitebox mt-3'>
                  <div className='px-sm-3 px-2 topsearch py-4'>
                    <About />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="photos" title="Photos">
                <div className='whitebox mt-3'>
                  <div className='px-sm-3 px-2 topsearch py-4'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                      <h6>Photos</h6>
                    </div>
                    <div className='row'>
                      {profimg.map((img, val) =>

                        <div className='col-12 col-sm-4 col-md-3 mb-3 mb-md-3'>
                          <Profilephotos image={img} />

                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </Tab>
              <Tab eventKey="videos" title="Videos">

              </Tab>
            </Tabs>
          </div>
          {/* </Wrapper> */}
        </div>

      </section>
    </>
  )
}

export default Otherprofile