import React, { useState } from 'react';
import { Dropdown, InputGroup, Modal, Form } from 'react-bootstrap';
import Images from '../Images';

const EditPost = (props) =>{
    const[editpost, setEditPost] = useState(true)
    return(
        <>
         <Modal show={editpost} className='thememodal ' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title className='mb-3'>Edit Post</Modal.Title>
          {/* <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p> */}
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            <img src={Images.youtube} className='youtube img'/>
            <img src={Images.all} className='all img'/>
            <div className='d-flex jc-between mb-3'>
               <p className='mb-0'><img src={Images.prof} className='img-fluid' width="30px" /> Merlin</p>
                <select className='iconselect' aria-label="Default select example">
                    <option value="1"> &#xf0c0; Friends </option>
                    <option value="2"> &#xf57d; Public</option>
                    <option value="3"> &#xf27a; Private</option>
                </select>
            </div>
            <p className='f-12'>Lorem ipsum is a dummy content</p>
            <div className='mb-3 editpost'>
                <button className='closebtn br-50'><span className='fa fa-times'></span></button>
                <img src={Images.post1} className='img-fluid' />
                <div className='editpostbtn'>
                    <button className='me-2'><span className='fa fa-pencil'></span> Edit</button>
                    <button ><input type="file"/> <span className='fa fa-photo'></span> Add photo</button>
                </div>
            </div>
            <div className='d-flex'>
            <InputGroup className="graysearch">
              <Form.Control placeholder="Write a comment" />
              <InputGroup.Text id="basic-addon2">
                <button className="linkbtn px-0">
                  
                  <img src={Images.paperclipblue} />
                </button>
              </InputGroup.Text>
              <InputGroup.Text id="basic-addon2">
                <button className="linkbtn px-0">
                  <img src={Images.imageblue} />
                </button>
              </InputGroup.Text>
              <InputGroup.Text id="basic-addon2">
                <button className="linkbtn px-0">
                  <img src={Images.smileyblue} />
                </button>
              </InputGroup.Text>
            </InputGroup>
            </div>
           
          
        </Modal.Body>
        <Modal.Footer>
        <div className=' squarebtn my-3'>
                <button> Save <img src={Images.btnimg} /></button>
               
            </div>
        </Modal.Footer>
      
      </Modal>
        </>
    )
}

export default EditPost