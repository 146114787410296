import React, { useState } from 'react';
import Images from '../Images';


const Profilefeature = () =>{
const [premium, setPremium] = useState(true);
 

    return(
        <>
        <div className='row'>
            <div className='col-12 col-md-6 mb-3 mb-md-0'>
                <div className='card feate_card whitebox   border-0'>
                    <div className='card-body p-0'>
                    <div className='profilewall'>
                          <img src={Images.feature1} className='img-fluid br-5 me-1'/> 
                        
                         
                        </div>
                          <div className='d-sm-flex jc-between gap-2 p-sm-3 p-2  pb-2 profilestat'>
                          <div className='d-flex'>
                              <div className='d-sm-flex d-none'>
                                  <img src={Images.prof} className='img-fluid br-5 me-1 profimg'/> 
                              </div> 
                              <div>
                              <p className='d-flex mb-0 align-items-center'> <h5  className='mb-1'>Music Technology</h5>
                                {premium ?  <badge className='btn btn_prem ms-2'><span className='fa fa-check f-12 text-white'></span>
                                 </badge> : ""}
                                 </p>
                                  
                                  
                               
                                 <p className='d-flex mb-0'>   <p className='f-12 text-muted mb-sm-3 mb-2' 
                                 >13 March
                                 </p></p>
                              </div>
                              </div>

                            
                            
                            </div>
                            <p className='featr_dsc px-3'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>


                    </div>
                </div>
            </div>
           
            <div className='col-12 col-md-6 mb-3 mb-md-0'>
                <div className='card feate_card whitebox   border-0'>
                    <div className='card-body p-0'>
                    <div className='profilewall'>
                          <img src={Images.feature1} className='img-fluid br-5 me-1'/> 
                        
                         
                        </div>
                          <div className='d-sm-flex jc-between gap-2 p-sm-3 p-2  pb-2 profilestat'>
                          <div className='d-flex'>
                              <div className='d-sm-flex d-none'>
                                  <img src={Images.prof} className='img-fluid br-5 me-1 profimg'/> 
                              </div> 
                              <div>
                              <p className='d-flex mb-0 align-items-center'> <h5  className='mb-1'>Music Technology</h5>
                                {premium ?  <badge className='btn btn_prem ms-2'><span className='fa fa-check f-12 text-white'></span>
                                 </badge> : ""}
                                 </p>
                                  
                                  
                               
                                 <p className='d-flex mb-0'>   <p className='f-12 text-muted mb-sm-3 mb-2' 
                                 >13 March
                                 </p></p>
                              </div>
                              </div>

                            
                            
                            </div>
                            <p className='featr_dsc px-3'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>


                    </div>
                </div>
            </div>
        </div>                
        </>
    )
}

export default Profilefeature