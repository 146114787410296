import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Images from '../Images';
import { Link } from 'react-router-dom';

const Members = () =>{
    return(
        <div>
            <h6>Members</h6>
            <p className='f-12'>Lorem ipsum is a dummy content</p>
             <InputGroup className="themesearch ">
             <InputGroup.Text id="basic-addon2">
                <button className="linkbtn px-0">
                  <span className='fa fa-search'></span>
                  {/* <img src={Images.paperclipblue} /> */}
                </button>
              </InputGroup.Text>
              <Form.Control 
                placeholder="Search this group"
                aria-label="What's happening ?"
                aria-describedby="basic-addon2"
              />
         
  
            </InputGroup>
            <p className='f-14 mt-4'>New Members 8</p>
            <ul className='sidegroup'>
                <li className='d-flex jc-between my-2 align-items-center'> 
                    <div className='d-flex squareimg'>  
                        <img src={Images.story1} className='user br-50'/>
                        <div>
                            <Link to="/groupprof" class="smallhead nodecore">Blockchain world <span className='themeclr f-12'>. Admin</span></Link>
                            <p class="f9txt mb-0"> @bitcoinworld</p>
                        </div> 
                    </div>
                </li>
                <li className='d-flex jc-between my-2 align-items-center'> 
                    <div className='d-flex squareimg'>  
                        <img src={Images.story1} className='user br-50'/>
                        <div>
                            <Link to="/groupprof" class="smallhead nodecore">Blockchain world </Link>
                            <p class="f9txt mb-0"> @bitcoinworld</p>
                        </div> 
                    </div>
                </li>
                <li className='d-flex jc-between my-2 align-items-center'> 
                    <div className='d-flex squareimg'>  
                        <img src={Images.story1} className='user br-50'/>
                        <div>
                            <Link to="/groupprof" class="smallhead nodecore">Blockchain world </Link>
                            <p class="f9txt mb-0"> @bitcoinworld</p>
                        </div> 
                    </div>
                </li>
                <li className='d-flex jc-between my-2 align-items-center'> 
                    <div className='d-flex squareimg'>  
                        <img src={Images.story1} className='user br-50'/>
                        <div>
                            <Link to="/groupprof" class="smallhead nodecore">Blockchain world </Link>
                            <p class="f9txt mb-0"> @bitcoinworld</p>
                        </div> 
                    </div>
                </li>
                <li className='d-flex jc-between my-2 align-items-center'> 
                    <div className='d-flex squareimg'>  
                        <img src={Images.story1} className='user br-50'/>
                        <div>
                            <Link to="/groupprof" class="smallhead nodecore">Blockchain world </Link>
                            <p class="f9txt mb-0"> @bitcoinworld</p>
                        </div> 
                    </div>
                </li>
            </ul>
        </div>
    )
}


export default Members