import React , {useState} from "react";
import { Tab, Nav, Button, Form, Offcanvas} from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';

import BlockUser from "../Modals/BlockUser";
import UnblockUser from "../Modals/UnblockUser";
import ReportProfile from "../Modals/ReportProfile";
import Leavechannel from "../Modals/Leavechannel";
import DeleteChannel from "../Modals/DeleteChannel";

import Images from "../Images";
import Story1 from "../../Assets/Images/story/story1.png";
import Story2 from "../../Assets/Images/story/story2.png";
import viewprof from "../../Assets/Images/view_prof.png";
import blockuserimg from "../../Assets/Images/block_user.png";

import updatephoto from "../../Assets/Images/update_photo.png";
import invitephoto from "../../Assets/Images/invite_member.png";
import leavephoto from "../../Assets/Images/leave_channel.png";
import deletephoto from "../../Assets/Images/delete_channel.png";




const Message = (props) => {
  const [ischat, setischat] = useState(true);
  const [issettings, setissettings] = useState(false);
  const[blockusermodal, setBlockusermodal] = useState(false);
  const[unblockusermodal, setUnblockusermodal] = useState(false);
  const[reportprofilemodal, setReportprofilemodal] = useState(false);
  const[leavechannel, setLeavechannel] = useState(false);
  const[deletechannel, setDeletechannel] = useState(false);



  const [isBlocked,setIsBlocked] = useState(false);
  const [chatactive, setChatactive] = useState(0);
  const [closemsg, setClosemsg] = useState(false);
  const [showleft, setShowleft] = useState(false);

  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  
  const [charReq] = useState([
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
      isactive:true,
      isGroupchat:false
    },
    {
      img: Story2,
      name: "Bitcoinworld",
      subname: "@bitcoinworld and @crypto and 1 others",
      isactive:false,
      isGroupchat:true
    },
  ])
  return (
    <>
       {blockusermodal && <BlockUser onDismiss={() => setBlockusermodal(false)} onBlock={() => {setIsBlocked(true);setBlockusermodal(false)}} />}
       {unblockusermodal && <UnblockUser onDismiss={() => setUnblockusermodal(false)} onUnblock={() => {setIsBlocked(false);setUnblockusermodal(false)}} />}
       {reportprofilemodal && <ReportProfile onDismiss={() => setReportprofilemodal(false)} />}
       {leavechannel && <Leavechannel onDismiss={() => setLeavechannel(false)} />}
       {deletechannel && <DeleteChannel onDismiss={() => setDeletechannel(false)} />}

<div className="d-block d-xl-none">
<Button className="themebtn btn_open_theme" onClick={()=>{setShowleft(!showleft)}}
// onClick={handleShow}
>
        <i className="fa fa-bars" />
      </Button>
</div>
      

   

      <div className={showleft?"leftabbox leftboxmob":"leftabbox"}>
        <div className="msghead">
          <div>
            <Button className="morebtn small me-1" onClick={()=>{props.onDismissmodal(false) ; props.prevParent()}}>
              <span className="fa fa-arrow-left" aria-hidden="true"></span>
            </Button>
            <h6 className="h6">
              {!props.settings && !props.grpsettings ?"Message":"Settings"}
              
              </h6>
          </div>
          <div>
            <Button className="morebtn small chaticon" onClick={props.setChat}>
            <img src={Images.chaticon} className="goingimg" />

            </Button>
          </div>
        </div>
        <div className="leftab">
          {!props.settings && !props.grpsettings?
          <Tab.Container defaultActiveKey="going">
          <Nav>
            <Nav.Item>
              <Nav.Link eventKey="going">On going</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="request" className="ms-4">
                Requests
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>

            <Tab.Pane eventKey="going">
              {ischat?
                   <ul className="chat_req_ul">
                   {charReq.map((mchat, index) => (
                     <li className={chatactive == index?"active_chat":""} onClick={()=>{props.checkGroupchat(mchat.isGroupchat);setChatactive(index);setShowleft(!showleft);}}>
                       <div className="flexb">
                         <div className="newdet">
                           <div>
                             <img src={mchat.img} />
                           </div>
                         
                         </div>
                         <div>
                             <h6 className="h6">{mchat.name}</h6>
                             <p>{mchat.subname}</p>
                           </div>
                      
                       </div>
                     </li>
                   ))}
                 </ul>
                 :

       

              <div className="innertab">
                <div className="tabimg">
                  <img src={Images.message1} className="goingimg" />
                </div>
                <p>You still do not have any ongoing conversation</p>
              </div>
              }

            </Tab.Pane>
            <Tab.Pane eventKey="request">
              <div className="innertab">
                <div className="tabimg">
                  <img src={Images.message1} className="goingimg" />
                </div>
                <p>No new message request found</p>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        :
       <>
       {props.settings?
          <ul className="chat_req_ul ul_setting mt-3">
          <li>
            <Link to="/profile">
            <div className="flexb">
              <div className="newdet">
                <div>
                  <img src={viewprof} />
                </div>
              
              </div>
              <div>
                  <h6 className="h6">View Profile</h6>
                </div>
           
            </div>
            </Link>
          </li>
          <li onClick={()=>{isBlocked?setUnblockusermodal(true):setBlockusermodal(true)}}>
            <div className="flexb">
              <div className="newdet">
                <div>
                  <img src={blockuserimg} />
                </div>
              
              </div>
              <div>
                  <h6 className="h6">{isBlocked?"Blocked":"Block User"}</h6>
                </div>
           
            </div>
          </li>
          <li onClick={()=>{setReportprofilemodal(true)}}>
            <div className="flexb">
              <div className="newdet">
                <div>
                  <img src={blockuserimg} />
                </div>
              
              </div>
              <div>
                  <h6 className="h6">Report Profile</h6>
                </div>
           
            </div>
          </li>
      </ul>
      :
      <ul className="chat_req_ul ul_setting mt-3">
        {console.log(props.checkDetails,"props.checkDetails")}
      <li onClick={()=>{props.checkDetails(true);props.checkPhoto(false);}} className={props.detailSettings?"active":""}>
        <div className="flexb">
          <div className="newdet">
            <div>
              <img src={viewprof} />
            </div>
          
          </div>
          <div>
              <h6 className="h6">Details</h6>
            </div>
       
        </div>
      </li>
      <li onClick={()=>{props.checkPhoto(true); props.checkDetails(false);props.checkInvite(false);}}>
        <div className="flexb">
          <div className="newdet">
            <div>
              <img src={updatephoto} />
            </div>
          
          </div>
          <div>
              <h6 className="h6">Update group photo</h6>
            </div>
       
        </div>
      </li>
      <li onClick={()=>{props.checkInvite(true); props.checkDetails(false);props.checkPhoto(false);}}>
        <div className="flexb">
          <div className="newdet">
            <div>
              <img src={invitephoto} />
            </div>
          
          </div>
          <div>
              <h6 className="h6">Invite member</h6>
            </div>
       
        </div>
      </li>

      <li onClick={()=>{setLeavechannel(true)}}>
        <div className="flexb">
          <div className="newdet">
            <div>
              <img src={leavephoto} />
            </div>
          
          </div>
          <div>
              <h6 className="h6">Leave channel</h6>
            </div>
       
        </div>
      </li>

      <li onClick={()=>{setDeletechannel(true)}}>
        <div className="flexb">
          <div className="newdet">
            <div>
              <img src={deletephoto} />
            </div>
          
          </div>
          <div>
              <h6 className="h6">Delete channel</h6>
            </div>
       
        </div>
      </li>
  </ul>
      }
      
    
          </>
        }
        </div>
      </div>
    </>
  );
};

export default Message;
