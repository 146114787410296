import React, { useState } from 'react';
import Images from '../Images';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const GroupCard = () =>{
   const options={
        margin:10,
        responsiveClass: true,
        nav:false,
        dots:false,
        items:3,
        responsive:{
            0:{
                items:1,
            },
            600:{
                items:3,
            },
            1000:{
                items:3,
            }
        }
    }
    return(
        <>
        <OwlCarousel {...options} className='owl-theme'>
            <div class='item'>
                <div className='whitebox '>
                    <button className='btn btn-link p-0 text-dark close'><span className='fa fa-times text-light'></span></button>
                    <img src={Images.post1} className='img-fluid' />
                    <div className='cont'>
                        <h5>Nature world</h5>
                        <p className='f-12 text-secondary'>
                           You last visited 2h ago
                        </p>             
                    </div>
                    <button className='themebtn w-full'>Join group</button>
                </div>
            </div>
            <div class='item'>
                <div className='whitebox '>
                    <button className='btn btn-link p-0 text-dark close'><span className='fa fa-times text-light'></span></button>
                    <img src={Images.post1} className='img-fluid' />
                    <div className='cont'>
                        <h5>Nature world</h5>
                        <p className='f-12 text-secondary'>
                           You last visited 2h ago
                        </p>             
                    </div>
                    <button className='themebtn w-full'>Join group</button>
                </div>
            </div>
            <div class='item'>
                <div className='whitebox '>
                    <button className='btn btn-link p-0 text-dark close'><span className='fa fa-times text-light'></span></button>
                    <img src={Images.post1} className='img-fluid' />
                    <div className='cont'>
                        <h5>Nature world</h5>
                        <p className='f-12 text-secondary'>
                           You last visited 2h ago
                        </p>             
                    </div>
                    <button className='themebtn w-full'>Join group</button>
                </div>
            </div>
            <div class='item'>
                <div className='whitebox '>
                    <button className='btn btn-link p-0 text-dark close'><span className='fa fa-times text-light'></span></button>
                    <img src={Images.post1} className='img-fluid' />
                    <div className='cont'>
                        <h5>Nature world</h5>
                        <p className='f-12 text-secondary'>
                           You last visited 2h ago
                        </p>             
                    </div>
                    <button className='themebtn w-full'>Join group</button>
                </div>
            </div>
                      
        </OwlCarousel>
           
        </>
    )
}

export default GroupCard