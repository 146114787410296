import React, { useEffect, useState } from 'react';

import Header from '../Header';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Images from '../Images';
import Imagemodal from '../Modals/ImageModal';
import Videomodal from '../Modals/VideoModal';
import CreatePost from '../Modals/CreatePost';
import EditPost from '../Modals/EditPost';
import DeletePost from '../Modals/DeletePost';
import SettingModal from '../Modals/SettingModal';
import Bio from '../Modals/Biomodal';
import { Dropdown, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import Linksec from '../Rightsection.js/Linksec';
import About from '../leftsection/About';
import AllFriendsCont from '../Friend/AllFriendsCont';
import Community from '../Community';
import Posts from '../Posts';
import PostLists from '../Common/PostLists';

const EventProf = () =>{
 
  
  const[showbar, setShowBar] = useState(true)
  const[bio, setBio] = useState(false)

  
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])

  const[imagemodal, setImagemodal] = useState(false)
  const[videomodal, setVideomodal] = useState(false)
  const[createpost, setCreatePost] = useState(false)
  const[editpost, setEditPost] = useState(false)
  const[deletepost, setDeletePost] = useState(false)
  const[setting, setSetting] = useState(false)
return(
 <>
 
 {imagemodal && <Imagemodal onDismiss={() => setImagemodal(false)}/>}
 {videomodal && <Videomodal onDismiss={() => setVideomodal(false)}/>}
 {createpost && <CreatePost onDismiss={() => setCreatePost(false)}/>}
 {editpost && <EditPost onDismiss={() => setEditPost(false)}/>}
 {deletepost && <DeletePost onDismiss={() => setDeletePost(false)}/>}
 {setting && <SettingModal onDismiss={() => setSetting(false)}/>}
 {bio && <Bio onDismiss= {() => setBio(false)}/>}

 <Header />
<section id='home' className='eventprof'>
<button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
  <div className='themecontainer'>
  <div className='themewidth'>
     <LeftSection  link="events"/>
     <div id="content" className=' w-full d-flex'>
     
     <section id='centersection' className='posts profile'>
         
         <div className='showin1199 showrightsec'><Linksec/></div>  
         
            
            <div className='postlists'>
           <div className='whitebox '>
           <div className='row m-1 evenflow'>
              <div className='col-md-6 watchleft'>
                <div className='eventbanner'>
                  <p className='f-12 mb-2'>Lorem ipsum is a dummy content</p>
                  <h6>Highlight | Pakistan vs srilanka | T20I PCB | MA2A</h6>
                </div>
                {/* <div className='d-flex jc-between'>
                  <ul className='imglist'>
                    <li><img src={Images.post1} className='img-fluid' /> </li>
                    <li><img src={Images.post2} className='img-fluid' /> </li>
                    <li><img src={Images.post3} className='img-fluid' /> </li>
                    <li><img src={Images.post4} className='img-fluid' /> </li>
                  </ul>
                </div>  */}
              </div>
              <div className='col-md-6 watchright'>
                <img src={Images.post1} className='img-fluid watchimg' />
                <div>
                  <button className='btn'>Watch</button>
                </div>
              </div>
          </div> 
                      
            <div className='d-sm-flex jc-between gap-2 p-sm-3 p-2  pb-2 profilestat'>
                <div className='d-flex'>
                    <div className='d-sm-flex d-none'>
                      <div className=' br-5 me-1 profimg text'>
                        25
                      </div>
                    </div> 
                    <div>
                        <p className='f-12 mb-0'> 
                         FRIDAY, 13 JULY, 2023 AT UTC+05:30 
                        </p>
                                <h5  className='mb-1'>Cricket Live</h5>  
                                <p className='d-flex mb-0 f-12'>  Online</p>
                                <h6 className='d-flex mb-0 f-12'> 
                                    <span className='fa fa-clock me-1'></span>  Ongoing
                                </h6>
                            
                              <div className='d-flex align-items-center'>
                              <div className='lineimg'>
                                   <img src={Images.prof} />
                                   <img src={Images.prof} />
                                   <img src={Images.prof} />
                              </div>
                             <span className='themeclr f-12 ms-5'>3.5k Friends</span></div>
                             </div>
                           
                           </div>
                           <div className='mt-sm-0 mt-2'>
                               <button className='morebtn inverse me-2'>Interested</button>
                               {/* <button className='morebtn px-2'><span className='fa fa-ellipsis-h'></span></button> */}
                               <Dropdown className="postdrop noafter d-inline-block">
                                        <Dropdown.Toggle variant="" className="morebtn px-2 noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-h"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                             </div> 
                       </div>  
                           
                    </div>  
                               
                               
                       
               
          {/* <Wrapper className='wrap'> */}
               <Tabs defaultActiveKey="about" id="uncontrolled-tab-example" className="mb-2 themetab " >
             
                   <Tab eventKey="about" title="About">
                     
                          <PostLists />
                     
                   </Tab>
                   <Tab eventKey="discussion" title="Discussion" className='friends '>
                   <PostLists />
                   </Tab>
               </Tabs>
             {/* </Wrapper> */}
            </div>
            
       </section>
      {showbar && <RightSection  rightsection="prof" />}
      
     </div>
  </div>
  </div>
 
</section>
</> 
)
}


export default EventProf
