import axios from "../utils/setAuthtoken";
import * as config from "../config/config";
import {
    SET_USER_DETAILS,
} from '../constants/constants'
import isEmpty from "is-empty";

export const setAccountData = (data) => {
    // console.log('dats----------------------->',data)
    return {

        type: SET_USER_DETAILS,
        data: {
            userDetails:{
            EmailId: data.EmailId,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            profilePicture: data.profilePicture,
            walletAddress: data.walletAddress,
            userName: data.userName,
            displayName: data.displayName,
            Bio:data.Bio,
            backgroundImage:data.backgroundImage
            }
        },
    };
};



export const register = async (data, dispatch) => {
    try {
        console.log("register_register")
        let respData = await axios.post(config.backurl + "/api/register", data)
        if (respData.status == 200) {
            console.log("jjjj", respData);
        }
        if(!isEmpty(respData?.data?.data?.result)){
            dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("register", err);
    }
}



export const walletregister = async (data,dispatch) => {
    try {
        console.log("walletregister_register")
        let respData = await axios.post(config.backurl + "/api/walletregister", data)
        if (respData?.status == 200) {
            console.log("jjjj", respData);
        }
        if(!isEmpty(respData?.data?.data?.result)){
        dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("walletregister", err);
    }
}

export const googleregister = async (data,dispatch) => {
    try {
        console.log("googleregister_register")
        let respData = await axios.post(config.backurl + "/api/googleregister", data)
        if (respData.status == 200) {
            console.log("jjjj", respData);
        }
        if(!isEmpty(respData?.data?.data?.result)){
            dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("googleregister", err);
    }
}