import React, { useState } from 'react';
import Images from '../Images';

const Contact = () =>{
    return(
        <div id="contact">
           <div className='d-flex jc-between align-items-end  '>
                <p className='subhead'>Contacts</p>
                <div> 
                    <button className='morebtn'>More</button>
                </div>
           </div>
           <div className='themebord '></div>
           <ul className='storylists mt-3'>
                <li className='squareimg'>
                    <img src={Images.story1} className='img-fluid user' />
                    <p className='name'>David</p>
                </li>
                <li className='squareimg'>
                    <img src={Images.story2} className='img-fluid user' />
                    <p className='name'>Marvel</p>
                </li>
                <li className='squareimg'>
                    <img src={Images.story3} className='img-fluid user' />
                    <p className='name'>Greg</p>
                </li>
                <li className='squareimg'>
                    <img src={Images.story4} className='img-fluid user' />
                    <p className='name'>Gru</p>
                </li>
                <li className='squareimg'>
                    <img src={Images.story8} className='img-fluid user' />
                    <p className='name'>Adam</p>
                </li>
           </ul>
        </div>
    )
}


export default Contact