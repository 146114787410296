import React, { useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import Images from '../Images';
import { Form } from 'react-router-dom';

const CreatePost = (props) =>{
    const[createpost, setCreatePost] = useState(true)
    const[attach , setAttach] = useState(false)
    return(
        <>
         <Modal show={createpost} className='thememodal ' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title className='mb-3'>Create a Post</Modal.Title>
          {/* <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p> */}
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            <img src={Images.youtube} className='youtube img'/>
            <img src={Images.all} className='all img'/>
            <div className='d-flex jc-between mb-3'>
               <p className='mb-0'><img src={Images.prof} className='img-fluid' width="30px" /> Merlin</p>
              
       
                <select className='iconselect' aria-label="Default select example">
                    <option value="1"> &#xf0c0; Friends </option>
                    <option value="2"> &#xf57d; Public</option>
                    <option value="3"> &#xf27a; Private</option>
                </select>
            </div>
            <div className='position-relative'>
                <textarea className='form-control' rows="5">Whats in your mind?</textarea>
                {/* <span className='fa fa-check'></span> */}
            </div>
            <div className='mt-3 d-sm-inline-flex'>
                <button className='themebtn me-2'><span className="fa fa-youtube"></span> Live video</button>
                <button className='themebtn me-2'><span className="fa fa-flag"></span> Live Event</button>
              <div className='attach mt-sm-0 mt-2'> 
                   <button className='themebtn px-2' onClick={() => setAttach(!attach)}><span className='fa fa-paperclip f-14'></span></button>
                 {attach &&      <div>
                        <button className='btn' onClick={() => setAttach(false)}><span className='fa fa-laugh '></span></button>
                        <button className='btn' onClick={() => setAttach(false)}><span className='fa fa-gif '>GIF</span></button>
                        <button className='btn' onClick={() => setAttach(false)}><span className='fa fa-play '></span></button>
                        <button className='btn' onClick={() => setAttach(false)}><span className='fa fa-image '></span></button>
                    </div>}
               </div>
              
            </div>
            
        </Modal.Body>
        <Modal.Footer>
        <div className=' squarebtn my-3'>
                <button> Post <img src={Images.btnimg} /></button>
               
            </div>
        </Modal.Footer>
      
      </Modal>
        </>
    )
}

export default CreatePost