import React, { useState, useEffect } from 'react';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Images from '../Images';
import Header from '../Header';
import { Link } from 'react-router-dom';
import AllFriendsCont from './AllFriendsCont';
import Linksec from '../Rightsection.js/Linksec';

const AllFriends = () =>{
    const[showbar, setShowBar] = useState(true)
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])
    return(
        <>
         <Header />
        <section id="home" className='friends allfriends'>
        <button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
        <div className='themecontainer'>
            <div className='themewidth'>
                <LeftSection />
                    <div id="content" className=' w-full d-flex'>
                    <section id='centersection' className='posts mx-sm-4 mx-0'>
                    <div className='showin1199 showrightsec'><Linksec/></div>  
                        <div className='postlists request'> 
                            <div className=' p-3'>
                        <AllFriendsCont />
                        </div>
                        </div>
                        </section>
                        {showbar && <RightSection />}
                    </div>
            </div>
        </div>
        </section>
        </>
    )
}

export default AllFriends