import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Images from './Images';

const LeftCarousel = () =>{
    const options1 = {
        loop: true,
        margin: 20,
        nav: true,
        dots: false,
        smartSpeed: 1200,
        navText: [
          "<i class='fa fa-arrow-left'></i>",
          "<i class='fa fa-arrow-right'></i>",
        ],
        autoplay: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      };
    return(
        <>
         <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                  <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                </div>
                <div className='carouselnone'>
                <div className="position-relative pb-5">
                  <img className="imagecloud" src={Images.whitecloud} />
                </div>
                <div className="mt-4">
                  <OwlCarousel className="owl-theme" {...options1}>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                  </OwlCarousel>
                </div>
                <div className="position-relative pt-5">
                  <img className="imagecloud1" src={Images.whitecloud} />
                </div>
                </div>
        </>
    )
}

export default LeftCarousel