import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Header from '../Header';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Images from '../Images';
import Linksec from '../Rightsection.js/Linksec';

const ViewPlaces = () =>{
    const[showbar, setShowBar] = useState(true)
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])
  const[tooltip , setTooltip] = useState(false)
    return(
        <>
         <Header />
        <section id="home" className='friends'>
        <button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
        <div className='themecontainer'>
  <div className='themewidth'>
     <LeftSection />
     <div id="content" className=' w-full d-flex'>
     <section id='centersection' className='posts'>
     <div className='showin1199 showrightsec'><Linksec/></div>  
     <div className='postlists request'> 
        <div className='whitebox p-sm-4'>
            <div className='d-flex jc-between align-items-center bordbtm mb-3 pb-3 px-sm-0 px-3 pt-sm-0 pt-3'>
                <h5 className='mb-0'>View result </h5>
              <div className='shrink-0'>
              <Link to="/allplaces" className='nodecore'> <button className='morebtn inverse me-2'>View all</button></Link>
              </div>
            </div>
            <ul>
                <li className='d-flex jc-between align-items-center gap-2'>
                    <div className='d-flex w-full'>
                        <img src={Images.story4} className='friendimg'/>
                        <div className='w-full'>
                            <h6 className='mb-sm-0 mb-2'><button className='btn btn-link p-0 nodecore text-dark' onClick={() => setTooltip(!tooltip)}>London</button></h6> 
                            <Link to="/placeprof" className='nodecore'> <button className='morebtn w-full showin575'>View</button></Link>
                        <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        </div>
                    </div>
                    <div className='rightbtn'>
                       <Link to="/placeprof"> <button className='morebtn '>View</button></Link>
                    </div>
                   { tooltip && 
                    <div className='placetooltip'>
                        <div className='d-flex'>
                            <img src={Images.story4} className='friendimg'/>
                            <div>
                                <h6 className='mb-1'>London House/Flat/Spare room to rent</h6>
                                <Link to="/placeprof" className='nodecore'> <button className='morebtn  w-full showin575'>View</button></Link>
                                <p className='mb-1 f-12 text-muted'><span className='fa fa-globe-africa'></span> Public</p>
                                <p className='mb-1 f-12 text-muted'><span className='fa fa-users'></span> 353k members</p>
                                <div className='lineimg'>
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                </div>
                                <div>
                                    <button className='morebtn me-2'>Join group</button>
                                    <button className='morebtn'><span className='fa fa-ellipsis-h'></span> </button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </li>
                <li className='d-flex jc-between align-items-center gap-2'>
                    <div className='d-flex w-full'>
                        <img src={Images.story5} className='friendimg'/>
                        <div className='w-full'><h6 className='mb-sm-0 mb-2'>London Student Accommodation 2023-24</h6>
                        <Link to="/placeprof" className='nodecore'> <button className='morebtn  w-full showin575'>View</button></Link>
                        <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        </div>
                    </div>
                    <div>
                    <Link to="/placeprof" className='rightbtn'> <button className='morebtn '>View</button></Link>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center gap-2'>
                    <div className='d-flex w-full'>
                        <img src={Images.story6} className='friendimg'/>
                        <div className='w-full'><h6 className='mb-sm-0 mb-2'>London</h6>
                        <Link to="/placeprof" className='nodecore'> <button className='morebtn  w-full showin575'>View</button></Link>
                        <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        </div>
                    </div>
                    <div>
                    <Link to="/placeprof" className='rightbtn'> <button className='morebtn '>View</button></Link>
                    </div>
                </li>
            </ul>
        </div>

        <div className='whitebox p-sm-4 mt-3'>
        <div className='d-flex jc-between align-items-center bordbtm mb-3 pb-3 px-sm-0 px-3 pt-sm-0 pt-3'>
                <h5 className='mb-0'>Suggestion Request </h5>
              <div className='shrink-0'>
              <Link to="/allfriends"> <button className='morebtn inverse me-2'>View all</button></Link>
              </div>
            </div>
            <ul>
                <li className='d-flex jc-between align-items-center gap-2'>
                    <div className='d-flex w-full'>
                        <img src={Images.story1} className='friendimg'/>
                        <div className='w-full'><h6 className='mb-sm-0 mb-2'>London Student Accommodation 2023-24</h6>
                        <Link to="/placeprof" className='nodecore'> <button className='morebtn  w-full showin575'>View</button></Link>
                        <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        </div>
                    </div>
                    <div>
                    <Link to="/placeprof" className='rightbtn'> <button className='morebtn '>View</button></Link>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center gap-2'>
                    <div className='d-flex w-full'>
                        <img src={Images.story2} className='friendimg'/>
                        <div className='w-full'><h6 className='mb-sm-0 mb-2'>London House/Flat/Spare room to rent</h6>
                        <Link to="/placeprof" className='nodecore'> <button className='morebtn  w-full showin575'>View</button></Link>
                        <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        </div>
                    </div>
                    <div>
                    <Link to="/placeprof" className='rightbtn'> <button className='morebtn '>View</button></Link>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center gap-2'>
                    <div className='d-flex w-full'>
                        <img src={Images.story3} className='friendimg'/>
                        <div className='w-full'><h6 className='mb-sm-0 mb-2'>London</h6>
                        <Link to="/placeprof" className='nodecore'> <button className='morebtn  w-full showin575'>View</button></Link>
                        <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        </div>
                    </div>
                    <div>
                    <Link to="/placeprof" className='rightbtn'> <button className='morebtn '>View</button></Link>
                    </div>
                </li>
            </ul>
        </div>
     </div>
     </section>
        
      {showbar && <RightSection />}
      
     </div>
  </div>
  </div>
        </section>
        </>
    )
}

export default ViewPlaces