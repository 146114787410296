import React, { useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import Images from '../Images';
import Imagemodal from '../Modals/ImageModal';
import Videomodal from '../Modals/VideoModal';
import CreatePost from '../Modals/CreatePost';
import EditPost from '../Modals/EditPost';
import DeletePost from '../Modals/DeletePost';
import SettingModal from '../Modals/SettingModal';
import FeedGroup from './FeedGroup';
import HidePost from './HidePost';
import Friends from './Friends';

const AllFeeds = (props) =>{
  const[imagemodal, setImagemodal] = useState(false)
  const[videomodal, setVideomodal] = useState(false)
  const[createpost, setCreatePost] = useState(false)
  const[editpost, setEditPost] = useState(false)
  const[deletepost, setDeletePost] = useState(false)
  const[setting, setSetting] = useState(false)
    return(
      <>
        <div className='postlists'>
           {imagemodal && <Imagemodal onDismiss={() => setImagemodal(false)}/>}
                    {videomodal && <Videomodal onDismiss={() => setVideomodal(false)}/>}
                    {createpost && <CreatePost onDismiss={() => setCreatePost(false)}/>}
                    {editpost && <EditPost onDismiss={() => setEditPost(false)}/>}
                    {deletepost && <DeletePost onDismiss={() => setDeletePost(false)}/>}
                    {setting && <SettingModal onDismiss={() => setSetting(false)}/>}

                    
         
        <div className='allposts'>
            <div className='position-relative'>
                <div className='feedgrid'> 
                <div className='row'>
                    <div className='col col-12'> <img src={Images.post1} className='img-fluid' /></div>
                    <div className='col col-6'><img src={Images.post2} className='img-fluid' /></div>
                    <div className='col col-6'><img src={Images.post3} className='img-fluid' /></div>
                    <div className='col col-4'><img src={Images.post4} className='img-fluid' /></div>
                    <div className='col col-4'><img src={Images.post5} className='img-fluid' /></div>
                    <div className='col col-4'>  <img src={Images.photo1} className='img-fluid' /></div>

                </div> 
                </div>
              
                    <div className='d-flex postdet'>
                        <div className='d-flex jc-between w-full align-items-center'>
                            <div className='d-flex align-items-center'>
                                <img src={Images.story1} className='img-fluid b-50 me-1' />
                                <div>
                                    <p className='mb-0 text-light f-14 fw-500'>David</p>
                                    <p className='mb-0 text-light'>Lorem ipsum</p>
                                </div>
                            </div>
                            <div>
                            <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setCreatePost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-bookmark-o me-2"></span> Save</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-bell-o me-2"></span> Turn on Notification</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-star-o me-2"></span> Remove Favorite</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-eye-slash me-2"></span> Hide post</Dropdown.Item>
                              </Dropdown.Menu>
                  </Dropdown>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className='p-3'> 
                        <div className='dopost  mb-0'>
                        <div className='d-flex jc-between'>
                            <div className=''>
                              
                            <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                            <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                            <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                            <button className='morebtn ms-2 comment'> <span className='commentcount'>1</span> View all comments</button>
                            <button className='linkbtn ms-sm-2 ms-0 donate '>
                              <i class="fas fa-hand-holding-medical showin575"></i>
                            <span className='hidein575'>Donate</span> </button>
                            </div>
                            <div className=''>
                              <button className='morebtn me-1 donate'> 17 minutes</button>
                              <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                              <button className='linkbtn px-0 '><img src={Images.feedblue} /></button>
                            </div>
                        </div>
                      </div>
                      <div className='mobilecomment'>
                       <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                       <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                      </div>
                      {/* <div className='commenttext'>
                        <p>
                      <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                        consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                        Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                        </p>
                      </div> */}
                      <div>
                      {/* <div className='d-flex gap-1'>
                        <InputGroup className="graysearch ">
                            <Form.Control
                              placeholder="What's happening ?"
                              aria-label="What's happening ?"
                              aria-describedby="basic-addon2" />
                            <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                          <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                          <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                        </InputGroup>
                      <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                      </div> */}
                    </div>
                    </div>
        </div>
        <div className='allposts'>
            <div className='position-relative'>
                <img src={Images.post1} className='img-fluid' />
                    <div className='d-flex postdet'>
                        <div className='d-flex jc-between w-full align-items-center'>
                            <div className='d-flex align-items-center'>
                                <img src={Images.story1} className='img-fluid b-50 me-1' />
                                <div>
                                    <p className='mb-0 text-light f-14 fw-500'>David</p>
                                    <p className='mb-0 text-light'>Lorem ipsum</p>
                                </div>
                            </div>
                            <div>
                            <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setCreatePost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-bookmark-o me-2"></span> Save</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-bell-o me-2"></span> Turn on Notification</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-star-o me-2"></span> Remove Favorite</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-eye-slash me-2"></span> Hide post</Dropdown.Item>
                              </Dropdown.Menu>
                  </Dropdown>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className='p-3'> 
                        <div className='dopost  mb-0'>
                        <div className='d-flex jc-between'>
                            <div className=''>
                              
                            <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                            <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                            <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                            <button className='morebtn ms-2 comment'> <span className='commentcount'>1</span> View all comments</button>
                            <button className='linkbtn ms-sm-2 ms-0 donate '>
                              <i class="fas fa-hand-holding-medical showin575"></i>
                            <span className='hidein575'>Donate</span> </button>
                            </div>
                            <div className=''>
                              <button className='morebtn me-1 donate'> 17 minutes</button>
                              <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                              <button className='linkbtn px-0 '><img src={Images.feedblue} /></button>
                            </div>
                        </div>
                      </div>
                      <div className='mobilecomment'>
                       <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                       <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                      </div>
                      <div>
                    </div>
                    </div>
        </div>
        <div className='allposts'>
            <div className='position-relative'>
                <img src={Images.post3} className='img-fluid' />
                    <div className='d-flex postdet'>
                        <div className='d-flex jc-between w-full align-items-center'>
                            <div className='d-flex align-items-center'>
                                <img src={Images.story1} className='img-fluid b-50 me-1' />
                                <div>
                                    <p className='mb-0 text-light f-14 fw-500'>David</p>
                                    <p className='mb-0 text-light'>Lorem ipsum</p>
                                </div>
                            </div>
                            <div>
                            <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setCreatePost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-bookmark-o me-2"></span> Save</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-bell-o me-2"></span> Turn on Notification</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-star-o me-2"></span> Remove Favorite</Dropdown.Item>
                                <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-eye-slash me-2"></span> Hide post</Dropdown.Item>
                              </Dropdown.Menu>
                  </Dropdown>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className='p-3'> 
                        <div className='dopost  mb-0'>
                        <div className='d-flex jc-between'>
                            <div className=''>
                              
                            <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                            <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                            <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                            <button className='morebtn ms-2 comment'> <span className='commentcount'>1</span> View all comments</button>
                            <button className='linkbtn ms-sm-2 ms-0 donate '>
                              <i class="fas fa-hand-holding-medical showin575"></i>
                            <span className='hidein575'>Donate</span> </button>
                            </div>
                            <div className=''>
                              <button className='morebtn me-1 donate'> 17 minutes</button>
                              <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                              <button className='linkbtn px-0 '><img src={Images.feedblue} /></button>
                            </div>
                        </div>
                      </div>
                      <div className='mobilecomment'>
                       <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                       <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                      </div>
                      <div>
                     
                    </div>
                    </div>
        </div>
     
    </div>
   <Friends />
   <div className='mt-3'>    <FeedGroup /></div>

    <div className='mt-3'> <HidePost /></div>
   

    </>
    )
}

export default AllFeeds