import React, { useEffect, useState } from 'react';
import Images from './Images';
import { NavLink, useNavigate,useLocation } from 'react-router-dom';
import { Form, InputGroup, Tab, Tabs, Button } from 'react-bootstrap';
import { BsGoogle, BsApple } from 'react-icons/bs';
// import OwlCarousel from "react-owl-carousel-rtl";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LeftCarousel from './LeftCarousel';
import { register,walletregister,googleregister } from '../Actions/register';
import { FaRegCopy } from 'react-icons/fa';
import { ValidateInputDatas } from "./Validations/Validations"
import toast, { Toaster } from 'react-hot-toast';
import copy from "copy-to-clipboard";
import isEmpty from "is-empty";
import {setAuthToken} from '../utils/setAuthtoken';
import { useSelector, useDispatch } from 'react-redux'



const Setupaccount = () => {

    const [firstName, set_firstName] = useState("");
    const [lastName, set_LastName] = useState("");
    const [EmailId, set_EmailId] = useState("");
    const [phoneNumber, set_phoneNumber] = useState("");
    const [error, set_error] = useState("");
    //
    console.log("phoonemnopasdf", phoneNumber)
    const [dialcode, setDialCode] = useState('1')
    const [mobile, setMobile] = useState('');
    const[address,set_address] = useState('')
    const[user,set_user] = useState('')

    const history = useNavigate()
    const dispatch = useDispatch();
  

  const location = useLocation();
  console.log("location", location)

    const options1 = {
        loop: true,
        margin: 20,
        nav: true,
        dots: false,
        smartSpeed: 1200,
        navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }
    const onChange = (e) => {
        console.log("event", e)
        if (e.target.id == 'firstName')
            set_firstName(e.target.value)
        if (e.target.id == 'lastName')
            set_LastName(e.target.value)
        if (e.target.id == 'EmailId')
            set_EmailId(e.target.value)


    };

    const handlePhoneNumber = async (value, country) => {
        console.log('value', value, country)
        const { dialCode } = country;
        let newPhoneNo = value;
        console.log("ewPhoneNo.slice(dialCode.length", newPhoneNo.slice(dialCode.length))
        if (dialCode) {
            setDialCode(dialCode)
            set_phoneNumber(newPhoneNo.slice(dialCode.length))
        } else if (value) {
            set_phoneNumber(value)
        }
    }


    const onSubmit = async (e) => {
        console.log("Data")
        try {
            if(isEmpty(sessionStorage.getItem("accountInfo")) && isEmpty(location?.state?.key)){
            var data = {
                firstName: firstName,
                lastName: lastName,
                EmailId: EmailId,
                phoneNumber: "+" + dialcode + "." + phoneNumber,
            }
            const { errors, isValid } = await ValidateInputDatas(data, "Register")
            set_error(errors)
            console.log("_errors_errors", errors, isValid)
            if (Object.keys(errors).length == 0 && isValid === true) {
                var regdata = {
                    firstName: firstName,
                    lastName: lastName,
                    EmailId: EmailId,
                    phoneNumber: "+" + dialcode + "." + phoneNumber,
                    
                }
                var resp = await register(regdata,dispatch)
                var email = localStorage.setItem("Email", EmailId);
                console.log("register", resp, email)
            }
            if(resp?.data?.data?.message == "User already exists....!!!"){
                toast.success(`${resp?.data?.data?.message}`, {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            })}
            if (resp?.data?.data?.message != "User already exists....!!!") {
                history('/setuppassword')
            }
            else if (resp?.status == 500) {
                toast.error("Error !Try Again...", {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
            }
            }
            else if(location?.state?.key == "googelLogin" || location?.state?.key == "appleLogin"){
                console.log("google")
                var data = {
                    firstName: firstName,
                    lastName: lastName,
                    EmailId: EmailId,
                    phoneNumber: "+" + dialcode + "." + phoneNumber,
                }
                const { errors, isValid } = await ValidateInputDatas(data, "Register")
                set_error(errors)
                console.log("_errors_errors", errors, isValid)
                if (Object.keys(errors).length == 0 && isValid === true) {
                    var regdata = {
                        firstName: firstName,
                        lastName: lastName,
                        EmailId: EmailId,
                        phoneNumber: "+" + dialcode + "." + phoneNumber,
                        
                    }
                    var resp = await googleregister(regdata,dispatch)
                    var email = localStorage.setItem("Email", EmailId);
                    console.log("register", resp, email)
                    if (resp.data.data) {
                        setAuthToken(resp?.data?.data?.token)
                        history('/setprofilepicture')
                    }
                    else if (resp?.status == 500) {
                        toast.error("Error !Try Again...", {
                            style: {
                                minWidth: '300px',
                                minHeight: '55px'
                            }
                        })
                    }

                }
            }
            else{
                var data = {
                    firstName: firstName,
                    lastName: lastName,
                    EmailId: EmailId,
                    phoneNumber: "+" + dialcode + "." + phoneNumber,
                }
                const { errors, isValid } = await ValidateInputDatas(data, "Register")
                set_error(errors)
                console.log("_errors_errors_wallet", errors, isValid)
                if (Object.keys(errors).length == 0 && isValid === true) {
                    var regdata = {
                        firstName: firstName,
                        lastName: lastName,
                        EmailId: EmailId,
                        phoneNumber: "+" + dialcode + "." + phoneNumber,
                        walletAddress : address
                    }
                    var resp = await walletregister(regdata,dispatch)
                    setAuthToken(resp?.data?.data?.token)
                    var email = localStorage.setItem("Email", EmailId);
                    console.log("register_wallet", resp, email)
                }
                if(resp?.data?.data?.message == "Email already exists....!!!" || resp?.data?.data?.message == "Registered Successfully using Wallet"){
                    toast.success(`${resp?.data?.data?.message}`, {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })}
                if (resp?.data?.data?.message == "Email already exists....!!!") {
                    history('/setupaccount')
                }else if(resp?.data?.data?.message == "Registered Successfully using Wallet"){
                    history('/setprofilepicture')
                }
                else if (resp?.status == 500) {
                    toast.error("Error !Try Again...", {
                        style: {
                            minWidth: '300px',
                            minHeight: '55px'
                        }
                    })
                }
                
            }
        }
        catch (err) {
            console.log("registerwallet_err", err)
        }

    };
    useEffect(() => {
        // set_src(URL.createObjectURL())
        if (!isEmpty(location?.state)&& location.state.key == 'googleLogin') {
            set_user(location?.state)
            set_firstName(location?.state?.given_name)
            set_LastName(location?.state?.family_name)
            set_EmailId(location?.state?.email)
          console.log("Data Reflected ", location?.state)
        }else if(!isEmpty(location?.state)&& location.state.key == 'appleLogin'){
            set_EmailId(location?.state?.email)
            set_firstName(location?.state?.firstName)
            set_LastName(location?.state?.lastName)
            console.log("Data Reflected ", location?.state)
        }
      }, [location])


    useEffect(() => {
       console.log("HJHJHJHJHJHJHJ",sessionStorage.getItem("accountInfo")); 
       if(sessionStorage.getItem("accountInfo") !== null){
        set_address(sessionStorage.getItem("accountInfo"))
       }
   
    }, [])
    

   const copyToClipboard = () => {
    copy(address);
    toast.success("Address Copied Successfully", {
        style: {
            minWidth: '300px',
            minHeight: '55px'
        }
    })
}

    return (
        <>
            {/* <ToastContainer/> */}
            <div>
                <Toaster />
                <div className='signup'>
                    <div className='container'>
                        <div className='row mh-100'>
                            <div className='col-md-5 col-lg-6'>
                                <LeftCarousel />
                                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                                    <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                                </div>
                                <div className='carouselnone'>
                                    <div className='position-relative pb-5'>
                                        <img className='imagecloud' src={Images.whitecloud} />
                                    </div>
                                    <div className='mt-4'>
                                        <OwlCarousel className='owl-theme' {...options1}>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                    <div className='position-relative pt-5'>
                                        <img className='imagecloud1' src={Images.whitecloud} />
                                    </div>
                                </div> */}
                            </div>
                            <div className='col-md-7 col-lg-6'>
                                <div className='signupcard'>
                                    <h4 className='connectheading text-center'>Setup your Account</h4>
                                    <p className='subheading text-center mb-4'>Lorem Ipsum is simply dummy text of the printing</p>
                                    <img className='leftgraycloud' src={Images.gallery} />
                                    <img className='graycloud' src={Images.graycloud} />
                                    <img className='imagecloudsetup' src={Images.cloud} />
                                  {(sessionStorage.getItem("accountInfo") !== null) ? <div className='row justify-content-center mt-2'>
                                        <div className='col-lg-8 col-md-10 col-sm-9 col-12'>
                                            <div className='task mt-2'>
                                                <div className='d-flex align-items-center justify-content-center '>
                                                    <img className='metaimg' src={Images.meta} />
                                                    <h6 className='name mb-0'>Metamask</h6>
                                                </div>
                                                {address !== '' &&
                                                    <div className='tokens mt-3'>

                                                        <p>{<>{(address).slice(0, 7)}...{(address).slice((address).length - 9, (address).length - 1)}<FaRegCopy className='ms-2' onClick={copyToClipboard} /></>}</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
 
                                    </div>:
                                    <div className='align-items-center justify-content-center text-center'>
                                    <Link to="/connectwallet" className='position-relative'>
                                            <Button variant="primary" className='signupwallet'>
                                                Connect Wallet</Button>
                                            <img className='signupicon' src={Images.wallet1} />
                                            </Link>
                                            </div>}
                                    <div className='bordbt bordbt1'></div>

                                    <div className='setupfield'>
                                        <img className='darkgraycloudsetup' src={Images.darkgraycloud} />
                                        <p className='detected mt-3 mb-3 text-center'>No ENS Address Detected</p>

                                        <Form>
                                            <Form.Group className="mb-3" >
                                                <Form.Control type="text" placeholder="First Name" id='firstName' value={firstName} onChange={(e) => { onChange(e) }} />
                                                <span className="text-danger  mb-3 d-block text-left">{error.firstName}</span>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Control type="text" placeholder="Last Name" id='lastName' value={lastName} onChange={(e) => { onChange(e) }} />
                                                <span className="text-danger  mb-3 d-block text-left">{error.lastName}</span>
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Control type="email" placeholder="Email Id" id='EmailId' value={EmailId} onChange={(e) => { onChange(e) }} />
                                                <span className="text-danger  mb-3 d-block text-left">{error.EmailId}</span>
                                            </Form.Group>
                                            {/* {console.log("dialcode + mobile", dialcode, phoneNumber)} */}
                                            <PhoneInput
                                                country='us'
                                                placeholder=''
                                                value={dialcode + phoneNumber}
                                                onChange={handlePhoneNumber}
                                                autoComplete="new-password"
                                                specialLabel={false}

                                            />
                                            <span className="text-danger  mb-3 d-block text-left">{error.phoneNumber}</span>
                                        </Form>
                                        <p className='condition mt-3 mb-2'> <span className='loginnow'><Link to="/">Terms and Conditions</Link></span> printing</p>
                                    </div>

                                    <div className='allimages'>
                                        {/* <img className='imagecloud' src={Images.cloud} /> */}
                                        {/* <img className='darkgraycloud' src={Images.darkgraycloud} /> */}
                                        <img className='commenticon' src={Images.all} />
                                        <img className='youtubeicon' src={Images.youtube} />
                                        <div className='pt-4 pb-4 text-center'>
                                            <Link  onClick={onSubmit} className=' position-relative'>
                                            <Button variant="primary" className='signupwallet'>
                                                Proceed</Button>
                                            <img className='signupicon' src={Images.wallet1} />
                                            </Link>
                                        </div>

                                    </div>

                                    <div className='pt-3'>
                                        <p className='text-center already'><span>Already a Lander?</span> <span className='loginnow'><Link to="/login"> Login Now </Link></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Setupaccount;