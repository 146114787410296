import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Images from '../Images';

const Linksec= () => {
    return(
        <>
        <div className='toplinks bordbtm'>
                <ul>
                  <li>  <div><button className='themebtn'>Invite</button></div> </li>
                  <li> <NavLink to="/home5"><img src={Images.users} /></NavLink> </li>
                  <li> <NavLink to="/home6"><img src={Images.comment} /></NavLink> </li>
                  <li> <NavLink to="/home7"><img src={Images.clock} /></NavLink> </li>
                  <li> <NavLink to="/home8"><img src={Images.flag} /></NavLink> </li>
                  <li> <NavLink to="/home9"><img src={Images.settings} /></NavLink> </li>
                </ul>
                
             </div>
             </>
    )
}
export default Linksec