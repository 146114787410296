import axios from "../utils/setAuthtoken";
import * as config from "../config/config";

export const requestFollow = async (formData) => {

    try {
        console.log("formData_follow", formData)
        let result = await axios.post(config.backurl + "/api/createrequest", formData);
        if (result.status == 200) {
            console.log("request_res", result);
            return result;
        }
    } catch (err) {
        console.log("request_follow_err", err)
    }
}