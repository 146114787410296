import React, { useEffect, useState } from 'react';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Header from '../Header';
import Images from '../Images';
import Linksec from '../Rightsection.js/Linksec';

import AllFeeds from './AllFeeds';
import Favorite from './Favorite';
import { useLocation } from 'react-router-dom';
import Friends from './Friends';
import HidePost from './HidePost';
import FeedGroup from './FeedGroup';

const Feed = () =>{
 const location = useLocation()
 const page=  location?.state?.name;
 const activeclass=  location?.state?.data;
 console.log(page)
  const[showbar, setShowBar] = useState(true)
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])
return(
 <>
 <Header />
<section id='home'>
    <button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
  <div className='themecontainer'>
    <div className='themewidth'>
        <LeftSection link="feeds"/>
        <div id="content" className=' w-full d-flex'>
        
        <section id='centersection' className='posts'>
            <div className='showin1199 showrightsec'><Linksec/></div> 
            {(page ==  undefined ||page == null || page == "feeds" )  && <AllFeeds /> }
          
            {page == "favorite"  &&<Favorite /> }
            {page == "friends"  &&<Friends /> }
            {page == "group"  &&<FeedGroup /> }
            {page == "hidepost"  &&<HidePost /> }

           {/* {page == "feeds" ? <AllFeeds /> : page == "favorite" ? <Favorite />
            : <Group />} */}
            </section>
        {showbar && <RightSection />}
        
        </div>
    </div>
  </div>
 
</section>
</> 
)
}


export default Feed
