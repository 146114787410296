// import constant
import {
    SET_USER_DETAILS
} from '../constants/constants'

const initialState = {
    "userDetails":{
        "EmailId": "",
        "firstName": "",
        "lastName": "",
        "phoneNumber": "",
        "profilePicture": "",
        "walletAddress": ""
    }
};

const userDetails = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }

}

export default userDetails;