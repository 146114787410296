// import React, { useState, useEffect } from 'react';
// import { Button, Container, Nav, Form, NavDropdown, Navbar, Dropdown, InputGroup } from 'react-bootstrap';
// import Images from './Images';
// import { Link, NavLink } from 'react-router-dom';
// import { getData, getProfileData } from '../Actions/setProfilepic';
// import * as config from "../config/config";
// import isEmpty from "is-empty";
// import { getNotificationData } from '../Actions/Notification';

// const Header = () => {
//   const [showdrop, setShowDrop] = useState(false)
//   const [fname, set_fname] = useState("")
//   const [lname, set_lname] = useState("")
//   const [email, set_email] = useState("")
//   const [propic, set_pro] = useState("")

//   const [userdata, setUserData] = useState({})
//   const [userprofiledata, setUserProfileData] = useState({});
//   const [notificationdata, setNotificationData] = useState([])
//   console.log("header_userprofiledata", userprofiledata)

//   useEffect(() => {
//     userData();
//     // userProfileData();
//     // userNotificationData()
//   }, [])


//   var userData = async () => {
//     try {
//       var res = await getData();
//       console.log("respomd_data", res)
//       console.log("user", res, res.data.data.userData.firstName)
//       set_fname(res.data.data.userData.firstName)
//       set_lname(res.data.data.userData.lastName)
//       set_email(res.data.data.userData.EmailId)
//       set_pro(res.data.data.user.profilePicture)
//       setUserData(res.data.data.userData);
//       await userProfileData(res.data.data.userData);
      
//     }
//     catch (err) {
//       console.log(err, "err")
//     }
//   }


// var logout = () =>{
//   try{
//      sessionStorage.clear()
//   }
//   catch(err){
//     console.log(err,"err")
//   }

// }
// var userProfileData = async (data) => {

//   let username = window.location.pathname.split('/')[2]?.toString();
//   console.log("pathname", username);
//   let res = await getProfileData(username);
//   if (res?.status == 200) {
//     setUserProfileData(res?.data?.data?.user)
//   }
//   await userNotificationData(data, res?.data?.data?.user)
// }

// var userNotificationData = async (userdata1, userprofiledata1) => {
//   try {
//     console.log("userprodata", userdata1, userprofiledata1);
//     let datas = await getNotificationData(userdata1?._id);
//     console.log("datadatadata jeje", datas?.data?.notificationdata?.data);
//     setNotificationData(datas?.data?.notificationdata?.data)

//   } catch (err) {
//     console.log("errrrrrr", err)
//   }
// }
//     return(
//         <>
       
//           <Navbar bg="light" expand="lg">
//       <div className='px-3 d-flex justify-content-between w-full'>
//         <Navbar.Brand href="/">
//             <img src={Images.logo} className='img-fluid'/> 
//         </Navbar.Brand>
//         <div>
          
//         </div>
//             <InputGroup className=" themesearch">
//                <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span></InputGroup.Text>
//                   <Form.Control onClick={() => setShowDrop(!showdrop)}  placeholder="Search" className='nofocus'
//                     aria-label="What's happening ?"
//                     aria-describedby="basic-addon2" />
//                     {showdrop && <div className='searchdrop'>
//                         <ul>
//                           <li> <Link className='text-secondary' to="/viewfriend"><img src={Images.photo3} />{isEmpty(fname) ? <>newuser</> : fname }  {lname}</Link> </li>
//                           <li> <Link className='text-secondary' to="/places"><img src={Images.prof} /> London </Link> </li>
//                           <li> <Link className='text-secondary' to="/events"><img src={Images.prof} /> London </Link> </li>
//                           <li> <Link to="/" className='themeclr f-12'><span className='fa fa-search text-light bgtheme p-1 bord-50 f-12'></span> Search for London</Link></li>
//                         </ul>
//                     </div>}                    
//             </InputGroup>
           
//         <Navbar.Toggle aria-controls="navbarScroll" />
//         <Navbar.Collapse id="navbarScroll">
//           <Nav
//             className="my-2 my-lg-0"
//             style={{ maxHeight: '100px' }}
//             navbarScroll
//           >
//             <div><NavLink className="nav-link" href="/">Top</NavLink></div>
//             <div> <NavLink className="nav-link" href="#action2">All</NavLink></div>
//             <div><NavLink className="nav-link" href="#action2">Family</NavLink></div>
//             <NavDropdown title="Friends" id="navbarScrollingDropdown">
//               <NavDropdown.Item href="/friends">




//   return (
//     <>

//       <Navbar bg="light" expand="lg">
//         <div className='px-3 d-flex justify-content-between w-full'>
//           <Navbar.Brand href="/">
//             <img src={Images.logo} className='img-fluid' />
//           </Navbar.Brand>
//           <div>

//           </div>
//           <InputGroup className=" themesearch">
//             <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span></InputGroup.Text>
//             <Form.Control onClick={() => setShowDrop(!showdrop)} placeholder="Search" className='nofocus'
//               aria-label="What's happening ?"
//               aria-describedby="basic-addon2" />
//             {showdrop && <div className='searchdrop'>
//               <ul>
//                 <li> <Link className='text-secondary' to="/viewfriend"><img src={Images.photo3} />{fname}  {lname}</Link> </li>
//                 <li> <Link className='text-secondary' to="/places"><img src={Images.prof} /> London </Link> </li>
//                 <li> <Link className='text-secondary' to="/events"><img src={Images.prof} /> London </Link> </li>
//                 <li> <Link to="/" className='themeclr f-12'><span className='fa fa-search text-light bgtheme p-1 bord-50 f-12'></span> Search for London</Link></li>
//               </ul>
//             </div>}
//           </InputGroup>

//           <Navbar.Toggle aria-controls="navbarScroll" />
//           <Navbar.Collapse id="navbarScroll">
//             <Nav
//               className="my-2 my-lg-0"
//               style={{ maxHeight: '100px' }}
//               navbarScroll
//             >
//               <div><NavLink className="nav-link" href="/">Top</NavLink></div>
//               <div> <NavLink className="nav-link" href="#action2">All</NavLink></div>
//               <div><NavLink className="nav-link" href="#action2">Family</NavLink></div>
//               <NavDropdown title="Friends" id="navbarScrollingDropdown">
//                 <NavDropdown.Item href="/friends">
//                   <div><img src={Images.users} /> Friends <span className='countbadge'>1K</span> </div>
//                   <span className='fa fa-check'></span>
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="#action4">
//                   <div><img src={Images.comment} /> Group <span className='countbadge'>1K</span> </div>
//                   <span className='fa fa-check active'></span>
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="#action5">
//                   <div><img src={Images.clock} /> Events <span className='countbadge'>200</span> </div>
//                   <span className='fa fa-check'></span>
//                 </NavDropdown.Item>
//               </NavDropdown>
//               <div>   <NavLink className="nav-link" href="#" >
//                 My posts
//               </NavLink></div>
//             </Nav>

//             <div className='righthead kl gap-xxl-3 gap-2 d-flex justify-content-lg-start jc-between align-items-center'>
//               <div><button className='themebtn'>Invite</button></div>
//               {/* <div className='ms-xl-0 ms-auto'><button className='btn btn-link'><img src={Images.bell} /></button></div> */}
//               {<Dropdown>
//                 <Dropdown.Toggle variant="success" id="dropdown-basic">
//                   <div className='ms-xl-0 ms-auto position-relative'><p className='nofificationcolor'></p><img src={Images.bell} /></div>
//                 </Dropdown.Toggle>
// {console.log("[not_data",notificationdata )}
//                {notificationdata && notificationdata.length > 0 && notificationdata.map((item) =>  
//                <><Dropdown.Menu>
//                   <div className='d-flex justify-content-between align-items-center'>
//                     <img src={Images.bell} />
//                     <p className='mb-0'>Venkat</p>
//                     <p className='mb-0'>lorem ipsum</p>
//                     <button className='themebtn'>Accept</button>
//                     <button className='themebtn'>Reject</button>
//                   </div>
//                 </Dropdown.Menu>
//                 <br/></>
//                 )}
//               </Dropdown>}


//               <Dropdown className='linkdrop'>
//                 <Dropdown.Toggle variant="success" id="dropdown-basic">
//                 <img src={isEmpty(propic)? Images.prof1 :`${config.backurl}/images/${propic}`} className='img-fluid ' /> {isEmpty(fname) ? <>newuser</> : fname }   {lname}
//                 </Dropdown.Toggle>

//                 <Dropdown.Menu>
//                   <Dropdown.Item href="/profile">Profile</Dropdown.Item>
//                   <Dropdown.Item href="/signup" onClick={()=>{logout()}}>Logout</Dropdown.Item>
//                 </Dropdown.Menu>
//               </Dropdown>
//             </div>

//           </Navbar.Collapse>
//         </div>
//       </Navbar>
//     </>
//   )
// }

// export default Header



import React, { useState ,useEffect} from 'react';
import { Button, Container, Nav,Form, NavDropdown, Navbar, Dropdown, InputGroup } from 'react-bootstrap';
import Images from './Images';
import { Link, NavLink } from 'react-router-dom';
import { getData,getProfileData } from '../Actions/setProfilepic';
import * as config from "../config/config";
import isEmpty from "is-empty";
import { getNotificationData } from '../Actions/Notification';
import { useSelector, useDispatch } from 'react-redux'

const Header = () =>{
   const[showdrop, setShowDrop] =useState(false)
   const [fname, set_fname] = useState("")
   const [lname, set_lname] = useState("")
   const [email, set_email] = useState("")
   const [propic,set_pro] = useState("")
   const [userName, set_userName] = useState("")
   const [displayName, set_displayName] = useState("")
   const [userdata, setUserData] = useState({})
   const [userprofiledata, setUserProfileData] = useState({});
   const [notificationdata, setNotificationData] = useState([])
   console.log("header_userprofiledata", userprofiledata)
   const userDetails= useSelector(state => state.accountDetails.userDetails)
   console.log("userDetails",userDetails) 

   useEffect(() => {
    userData()
}, [userDetails])

  // var userData = async () => {
  //   try {
  //     var res = await getData();
  //     console.log("respomd_data", res)
  //     console.log("user", res, res.data.data.userData.firstName)
  //     set_fname(res.data.data.userData.firstName)
  //     set_lname(res.data.data.userData.lastName)
  //     set_email(res.data.data.userData.EmailId)
  //     set_pro(res.data.data.user.profilePicture)
  //     setUserData(res.data.data.userData);
  //     await userProfileData(res.data.data.userData);
      
  //   }
  //   catch (err) {
  //     console.log(err, "err")
  //   }
  // }

  var userData = async () => {
    try {
    //   var res = await getData(dispatch)
    //   if (!isEmpty(userDetails)) {
    //   console.log("user", res,res.data.data.result)
      set_fname(userDetails.firstName)
      set_lname(userDetails.lastName)
      set_email(userDetails.EmailId)
      set_pro(userDetails.profilePicture)
      set_userName(userDetails.userName)
      set_displayName(userDetails.displayName)
    //   }
    //   else{
    //     console.log("check")
    //   }
    }
    catch (err) {
       console.log(err,"err")
    }
  } 

var userProfileData = async (data) => {

  let username = window.location.pathname.split('/')[2]?.toString();
  console.log("pathname", username);
  let res = await getProfileData(username);
  if (res?.status == 200) {
    setUserProfileData(res?.data?.data?.user)
  }
  await userNotificationData(data, res?.data?.data?.user)
}

var userNotificationData = async (userdata1, userprofiledata1) => {
  try {
    console.log("userprodata", userdata1, userprofiledata1);
    let datas = await getNotificationData(userdata1?._id);
    console.log("datadatadata jeje", datas?.data?.notificationdata?.data);
    setNotificationData(datas?.data?.notificationdata?.data)

  } catch (err) {
    console.log("errrrrrr", err)
  }
}



  var logout = () => {
    try {
      sessionStorage.clear()
      if (localStorage.getItem("rememberme") === false) {
        localStorage.clear()
      }
      else {
        localStorage.removeItem("token")
      }
    }
    catch (err) {
      console.log(err, "err")
    }

  }

    return(
        <>
       
          <Navbar bg="light" expand="lg">
      <div className='px-3 d-flex justify-content-between w-full'>
        <Navbar.Brand href="/">
            <img src={Images.logo} className='img-fluid'/> 
        </Navbar.Brand>
        <div>
          
        </div>
            <InputGroup className=" themesearch">
               <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span></InputGroup.Text>
                  <Form.Control onClick={() => setShowDrop(!showdrop)}  placeholder="Search" className='nofocus'
                    aria-label="What's happening ?"
                    aria-describedby="basic-addon2" />
                    {showdrop && <div className='searchdrop'>
                        <ul>
                          <li> <Link className='text-secondary' to="/viewfriend"><img src={Images.photo3} />{isEmpty(displayName) ? <>newuser</> : displayName } </Link> </li>
                          <li> <Link className='text-secondary' to="/places"><img src={Images.prof} /> London </Link> </li>
                          <li> <Link className='text-secondary' to="/events"><img src={Images.prof} /> London </Link> </li>
                          <li> <Link to="/" className='themeclr f-12'><span className='fa fa-search text-light bgtheme p-1 bord-50 f-12'></span> Search for London</Link></li>
                        </ul>
                    </div>}                    
            </InputGroup>
           
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <div><NavLink className="nav-link" href="/">Top</NavLink></div>
            <div> <NavLink className="nav-link" href="#action2">All</NavLink></div>
            <div><NavLink className="nav-link" href="#action2">Family</NavLink></div>
            <NavDropdown title="Friends" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/friends">
                  <div><img src={Images.users} /> Friends <span className='countbadge'>1K</span> </div>
                  <span className='fa fa-check'></span>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action4">
              <div><img src={Images.comment} /> Group <span className='countbadge'>1K</span> </div>
                  <span className='fa fa-check active'></span>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
              <div><img src={Images.clock} /> Events <span className='countbadge'>200</span> </div>
                  <span className='fa fa-check'></span>
              </NavDropdown.Item>
            </NavDropdown>
         <div>   <NavLink className="nav-link" href="#" >
              My posts
            </NavLink></div>
          </Nav>
      
          <div className='righthead gap-xxl-3 gap-2 d-flex justify-content-lg-start jc-between align-items-center'>
              <div><button className='themebtn'>Invite</button></div>
              <div className='ms-xl-0 ms-auto'><button className='btn btn-link'><img src={Images.bell} /></button></div>
              <Dropdown className='linkdrop'>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={isEmpty(propic)? Images.prof1 :`${config.backurl}/images/${propic}`} className='img-fluid ' /> {isEmpty(displayName) ? <>newuser</> : displayName } 
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                  <Dropdown.Item href="/signup" onClick={()=>{logout()}}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
         
        </Navbar.Collapse>
      </div>
    </Navbar>
        </>
    )
}

export default Header