import React, { useState } from 'react';

const Profileinfo = () =>{
 

    return(
        <>
        <div className='whitebox mt-3'>
                    <div className='px-sm-3 px-2 topsearch'>
                            <div className='pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start about'>
                      <h6>Intro</h6>
                      <p class="f-12 text-black mb-sm-3 mb-2 mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                            </div>
                            <div className='settingmodal px-sm-3 px-2'>
                            <ul className='grid_ul'>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  

                    
                        <span className='fa fa-info-circle'></span>
                        <div className='ms-2'>
                            <h6 className='mb-0'>Page</h6>
                            <p className='mb-0 f-12'>bitcoinsworld.com</p>
                        </div>
                    </div> 
                  
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fas fa-globe'></span>
                        <div className='ms-2'>
                            <h6 className='mb-0'>Website</h6>
                            <p className='mb-0 f-12'>bitcoinsworld.com</p>
                        </div>
                    </div> 
                  
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className="fas fa-envelope-open"></span>
                        <div className='ms-2'>
                            <h6 className='mb-0'>Mail</h6>
                            <p className='mb-0 f-12'>info@bitcoinsworld.com</p>
                        </div>
                    </div> 
                                   </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fa fa-star'></span>
                        <div className='ms-2'>
                            <h6 className='mb-0'>Ratings</h6>
                            <p className='mb-0 f-12'>$0 (11 Reviews)</p>
                        </div>
                    </div> 
                   
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fas fa-map-marker-alt'></span>
                        <div className='ms-2'>
                            <h6 className='mb-0'>Address</h6>
                            <p className='mb-0 f-12'>lorem ipsum dolar sit amet, , consectetur adipiscing elit</p>
                        </div>
                    </div> 
                  
                </li>
                </ul>
                </div>
                           
                        </div>
        </>
    )
}

export default Profileinfo