import React, { useEffect, useState } from 'react';
import LandHead from './LandHead';
import Images from '../Images';
import { NavLink, useNavigate,useLocation } from 'react-router-dom';
import jwt from 'jsonwebtoken';

const Land = () =>{
    const location = useLocation();


    useEffect(()=>{
  
    console.log("apple_location",location)
    let userData = location.search
    userData = userData.split('=')
    console.log("userData",userData)
    const decodedToken =  decodeURIComponent(userData[1]);
    console.log(decodedToken,"decodedToken");
    },[location])


    return(
    <>
       <LandHead />
           <div className='land'>
        <section className='landbanner text-center'>

            <div className='container'>
            <div className='row jc-center'>
             
                <div className='col-lg-8'>
                    <div className='content'>
                        <p className='btncont'>Join DVG Today</p>
                        <h2>A Blockchain network for you</h2>
                            <p className='text-secondary'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                                soluta perspiciatis assumenda nisi iste! Nulla, architecto, magnam doloribus vel modi natus ad, 
                                possimus voluptatibus dignissimos quam consectetur fugit!</p>
                               <div className='floatimg'> 
                               <img src={Images.float1} className='img-fluid floatimg float1' />
                                <img src={Images.float2} className='img-fluid floatimg float2' />
                                <img src={Images.float3} className='img-fluid floatimg float3' />
                                <img src={Images.float4} className='img-fluid floatimg float4' />
                                <img src={Images.float5} className='img-fluid floatimg float5' />
                                <img src={Images.float6} className='img-fluid floatimg float6' />
                                    <div className='row jc-center'>
                                            <div className='col-md-4'>
                                                <div className='d-flex flex-1 gap-3'>
                                                    <div className='googles blue'><span className='fa fa-google'></span></div>
                                                    <div className='googles blue'><span className='fa fa-apple'></span></div>
                                                    <div className='googles blue'><span className='fa fa-twitter'></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    <img src={Images.landbanner} className='img-fluid' />
                                </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
       
          <section className='rightcont why'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 d-lg-block d-none '> <img src={Images.side1} className='img-fluid' /></div>
              <div className='col-lg-6'>
                <div className='content'>
                    <p className='btncont'>Why</p>
                    <h2>Lorem ipsum is a dummy content</h2>
                    <h4 className='blue'>Lorem ipsum is a dummy content</h4>
                    <p className='text-secondary'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                        soluta perspiciatis assumenda nisi iste! Nulla, architecto, magnam doloribus vel modi natus ad, 
                        possimus voluptatibus dignissimos quam consectetur fugit!</p>
                    <p className='text-secondary'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                        soluta perspiciatis assumenda nisi iste! Nulla, architecto, magnam doloribus vel modi natus ad, 
                        possimus voluptatibus dignissimos quam consectetur fugit!</p>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='d-flex flex-1 gap-3'>
                                    <div className='googles blue'> <span>10k</span> <br /> Lorem</div>
                                    <div className='googles blue'> <span>10k</span> <br /> Lorem</div>
                                    <div className='googles blue'> <span>10k</span> <br /> Lorem</div>
                                </div>
                            </div>
                        </div>
                </div>
              </div>
              </div>
              </div>
          </section>
          <section className='leftcont'>
          <div className='container'>
          <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='content'>
                    <p className='btncont'>Features</p>
                    <h2>Lorem ipsum is a dummy content</h2>
                    <h4 className='blue'>Lorem ipsum is a dummy content</h4>
                    <p className='text-secondary'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                        soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p>
                        <ul>
                            <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborio</p></li>
                            <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                                 soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p></li>
                            <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                                 soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p></li>
                        </ul>
                    <div>
                        <button className='themebtn me-2 py-3 mb-2'>Signup with Password</button>
                        <button className='themebtn py-3'>Signup with wallet</button>
                    </div>
                </div>
              </div>
              <div className='col-lg-6 d-lg-block d-none'>
                <img src={Images.side2} className='img-fluid' />
              </div>
              </div>
              </div>
          </section>

          <section className='leftcont'>
          <div className='container'>
          <div className='row align-items-center'>
               <div className='col-lg-6 d-lg-block d-none'>
               <img src={Images.side3} className='img-fluid' />
               </div>
            <div className='col-lg-6'>
              <div className='content'>
                  <p className='btncont'>Features</p>
                  <h2>Lorem ipsum is a dummy content</h2>
                  <h4 className='blue'>Lorem ipsum is a dummy content</h4>
                  <p className='text-secondary'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                      soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p>
                      <ul>
                          <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborio</p></li>
                          <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                               soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p></li>
                          <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                               soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p></li>
                      </ul>
                  <div>
                      <button className='themebtn me-2 py-3 mb-2'>Signup with Password</button>
                      <button className='themebtn py-3'>Signup with wallet</button>
                  </div>
              </div>
            </div>
            </div>
         </div>
        </section>
        <section className='leftcont'>
        <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='content'>
                  <p className='btncont'>Features</p>
                  <h2>Lorem ipsum is a dummy content</h2>
                  <h4 className='blue'>Lorem ipsum is a dummy content</h4>
                  <p className='text-secondary'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                      soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p>
                      <ul>
                          <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborio</p></li>
                          <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                               soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p></li>
                          <li className='mb-4'><p className='text-secondary'><span className='fa fa-check'></span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                               soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p></li>
                      </ul>
                  <div>
                      <button className='themebtn me-2 py-3 mb-2'>Signup with Password</button>
                      <button className='themebtn py-3'>Signup with wallet</button>
                  </div>
              </div>
            </div>
            <div className='col-lg-6 d-lg-block d-none'>
            <img src={Images.side4} className='img-fluid' />
            </div>
            </div>
            </div>
        </section>
        <section className='ourteam'>
        <div className='container'>
        <div className='content text-center'>
                  <p className='btncont'>Our Team</p>
                  <h2>A Blockchain social network for you</h2>
                  <p className='text-secondary'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                      soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p>
                  <div className='d-flex jc-between gap-2'>
                    <div><img src={Images.team1} className='img-fluid' /> <p style={{color:"#3C8CA5"}} className='mt-3'>Lorem ipsum</p> </div>
                    <div>  <img src={Images.team2} className='img-fluid' /> <p style={{color:"#3C8CA5"}} className='mt-3'>Lorem ipsum</p> </div>
                    <div><img src={Images.team3} className='img-fluid' /> <p style={{color:"#3C8CA5"}} className='mt-3'>Lorem ipsum</p> </div>
                  </div>  
                  <button className='themebtn darkbluebtn py-3 my-4'>Join DVG</button> 
              </div>
              </div>
        </section>
        <section className='subscribe'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='content text-center'>
                            <h2>A Blockchain social network for you</h2>
                            <p className='text-secondary'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in distinctio 
                                soluta perspiciatis assumenda nisi iste! Nulla, fugit!</p>
                            <button className='themebtn me-2 btncont'>Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     
       <div className='landfooter text-center p-4'>
            <p className='mb-0 text-light'>Lorem ipsum dolor sit amet conse</p>
        </div>
        </div>
     
        </>
    
    )
}

export default Land