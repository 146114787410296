import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const About = () =>{
    return(
        <div className='about'>
           <h6>About</h6>
           <p className='text-muted f-12'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
           <ul>
            <li className='d-flex jc-between align-items-center mb-3'>
                    <div className='d-flex gap-2 align-items-baseline'>
                        <span className='fa fa-globe-africa'></span>
                        <div><p className='mb-0 head'>Public</p>
                        <p className='mb-0 f-12 text-muted'>Anyone can see who's in the group and what they post.</p>
                        </div>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center mb-3'>
                    <div className='d-flex gap-2 align-items-baseline'>
                        <span className='fa fa-eye'></span>
                        <div><p className='mb-0 head'>Event By</p>
                        <p className='mb-0 f-12 text-muted'>Loreum ipsum (official)</p>
                        </div>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center mb-3'>
                    <div className='d-flex gap-2 align-items-baseline'>
                        <span className='fa fa-map-marker'></span>
                        <div>
                        <p className='mb-0 f-12 text-muted'>London, United Kingdom</p>
                        </div>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center mb-3'>
                    <div className='d-flex gap-2 align-items-baseline'>
                        <span className='fa fa-envelope'></span>
                        <div>
                        <p className='mb-0 f-12 text-muted'>info@mail.com</p>
                        </div>
                    </div>
                </li>
              
                <li className='d-flex jc-between align-items-center mb-3'>
                    <div className='d-flex gap-2 align-items-baseline'>
                        <span className='fa fa-user-o'></span>
                        <div>
                        <p className='mb-0 f-12 text-muted'>Rating · 4.3 (140 reviews)﻿</p>
                        </div>
                    </div>
                </li>
                <li>
                   <Link to="/"> 
                      <button className='themebtn w-full'>View more</button></Link>
                </li>
           </ul>
        </div>
    )
}

export default About