import logo from './logo.svg';
import './App.css';
import './index.scss';
import './hstyle.scss';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Land from "./Components/Landing/Landing"
import Signup from './Components/Signup';
import Profile from './Components/Profile';
import Setupaccount from './Components/Setupaccount';
import Setuppassword from './Components/Setuppassword';
import Setprofilepicture from './Components/Setprofilepicture';
import Connectwallet from './Components/Connectwallet';
import Welcome from './Components/welcomepage';
import Latestfollow from './Components/Latestfollow';
import Login from './Components/Login';
import Uploadimage from "./Components/Uploadimage";
import Captchalogin from "./Components/Captchalogin";
import Recapchaaa from "./Components/Recaptch";
import Friends from './Components/Friend/Friends';
import AllFriends from './Components/Friend/AllFriends';
import FriendProf from './Components/Friend/FriendProf';
import AllPlaces from './Components/Places/AllPlaces';
import PlaceProf from './Components/Places/PlaceProf';
import Places from './Components/Places/Places';
import ViewFriend from './Components/Friend/ViewFriend';
import Community from './Components/Community';
import Feed from './Components/Feeds/Feed';
import AllFeeds from './Components/Feeds/AllFeeds';
import Favorite from './Components/Feeds/Favorite';
import Groups from './Components/Group/Groups';
import toast, { Toaster } from 'react-hot-toast';

import Events from './Components/Eventspage/Events';
import GroupProf from './Components/Group/GroupProf';

import Profileother from './Components/Profileother';
import EventProf from './Components/Eventspage/EventProf';
import MyEventProf from './Components/Eventspage/MyEventProf';
import EditEvent from './Components/Eventspage/EditEvent';
import EditEvent1 from './Components/Eventspage/EditEvent1';
import EditEvent2 from './Components/Eventspage/EditEvent2';
import EditEvent3 from './Components/Eventspage/EditEvent3';
import EditEvent4 from './Components/Eventspage/EditEvent4';
import { ToastContainer } from 'react-toastify';
import HelperRoute from './routes/helper';


function App() {
  return (
    //     <div className="App">
    // import Events from './Components/Events';
    // import { ToastContainer } from 'react-bootstrap';
    // function App() {
    //   return (
    <div className="App">
      <ToastContainer />
      <HelperRoute />
      <Toaster />
      <BrowserRouter basename="/">
        <Routes>
          <Route path='/' element={<Land />} />
          <Route path='/home' element={<Home />} />
          <Route path='/events' element={<Events />} />
          <Route path='/friends' element={<Friends />} />
          <Route path='/viewfriend' element={<ViewFriend />} />
          <Route path='/allfriends' element={<AllFriends />} />
          <Route path='/friendprof' element={<FriendProf />} />


          <Route path='/places' element={<Places />} />
          <Route path='/allplaces' element={<AllPlaces />} />
          <Route path='/placeprof' element={<PlaceProf />} />


          <Route path='/community' element={<Community />} />

          {/* feeds */}
          <Route path='/feeds' element={<Feed />} />

          {/* events */}
          <Route path='/eventprof' element={<EventProf />} />
          <Route path='/myeventprof' element={<MyEventProf />} />
          <Route path='/editevent' element={<EditEvent />} />
          <Route path='/editevent1' element={<EditEvent1 />} />
          <Route path='/editevent2' element={<EditEvent2 />} />
          <Route path='/editevent3' element={<EditEvent3 />} />
          <Route path='/editevent4' element={<EditEvent4 />} />

          {/* group */}
          <Route path='/groups' element={<Groups />} />
          <Route path='/groupprof' element={<GroupProf />} />

          <Route path='/profile/Mani' element={<Profileother />} />







          <Route path='/home1' element={<Home />} />
          <Route path='/home2' element={<Home />} />
          <Route path='/home3' element={<Home />} />
          <Route path='/home4' element={<Home />} />
          <Route path='/home5' element={<Home />} />
          <Route path='/home6' element={<Home />} />
          <Route path='/home7' element={<Home />} />
          <Route path='/home8' element={<Home />} />
          <Route path='/home9' element={<Home />} />
          <Route path='/home10' element={<Home />} />
          <Route path='/profile' element={<Profile />} />



          <Route path='/signup' element={<Signup />} />
          <Route path='/connectwallet' element={<Connectwallet />} />
          <Route path='/setupaccount' element={<Setupaccount />} />
          <Route path='/setuppassword' element={<Setuppassword />} />
          <Route path='/setprofilepicture' element={<Setprofilepicture />} />
          <Route path='/welcome' element={<Welcome />} />
          <Route path='/latestfollow' element={<Latestfollow />} />
          <Route path='/login' element={<Login />} />
          <Route path="/uploadimage" element={<Uploadimage />} />
          <Route path="/captchalogin" element={<Captchalogin />} />
          <Route path="/recapthaaa" element={<Recapchaaa />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
