import React, { useState } from 'react';
import { Dropdown, InputGroup, Modal, Form } from 'react-bootstrap';
import Images from '../Images';

const SettingModal = (props) =>{
    const[setting, setSetting] = useState(true)
    return(
        <>
         <Modal show={setting} className='thememodal settingmodal' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title className='mb-3'>Settings</Modal.Title>
          {/* <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p> */}
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body className='p-4'>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            {/* <img src={Images.youtube} className='youtube img'/> */}
            <img src={Images.all} className='all img'/>
         
            <p className='f-14'>Lorem ipsum is a dummy content</p>
            <ul>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fa fa-users'></span>
                        <div>
                            <h6 className='mb-0 '>Friends</h6>
                            <p className='mb-0 f-12'>Only for friends</p>
                        </div>
                    </div> 
                    <div>
                         <label class="custcheck">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fa fa-globe-africa'></span>
                        <div>
                            <h6 className='mb-0 '>Public</h6>
                            <p className='mb-0 f-12'>Only for friends</p>
                        </div>
                    </div> 
                    <div>
                         <label class="custcheck">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fa fa-unlock-alt'></span>
                        <div>
                            <h6 className='mb-0 '>Private</h6>
                            <p className='mb-0 f-12'>Only for friends</p>
                        </div>
                    </div> 
                    <div>
                         <label class="custcheck">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </li>
            </ul>
        
   
        </Modal.Body>
        <Modal.Footer>
        <div className=' squarebtn my-3'>
                <button> Save <img src={Images.btnimg} /></button>
               
            </div>
        </Modal.Footer>
       
      
      </Modal>
        </>
    )
}

export default SettingModal