import React, { useEffect, useRef, useState } from 'react';
import Images from '../Images';
import { Link } from 'react-router-dom';
import Card from '../Common/Card';
import SearchGroup from '../Modals/SearchGroup';


export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth"
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

const CreateGroup = () =>{
    const[drop, setDrop] = useState(false)
    const[drop1, setDrop1] = useState(false)
    const[drop2, setDrop2] = useState(false)
    const[search, setsearch] = useState(true)

    return(
        <div className='postlists'>
         {search && <SearchGroup onDismiss= {() => setsearch(false)} />}

            <div className='whitebox mb-4 '>
                <div className='p-3'>
                    <h6 className='mt-3 mb-4'>Create a group</h6>
                    <div className='row'>
                        <div className='col-md-6 mb-md-3 my-2'>
                            <div class="form-floating grayinput">
                                <input type="text" class="form-control" id="email" placeholder="Enter email" name="email" />
                                <label for="email">Email</label>
                            </div>
                        </div>
                        <div className='col-md-6 mb-md-3 my-2'>
                            <div className='themefloat'>
                                <label>Email</label>
                                <p className='inputtxt' onClick={() => setDrop(!drop)}>Bitcoin</p>
                            {drop && <div className='drop'>
                                    <ul>
                                        <li className='d-flex jc-between align-items-center'>
                                            <div className='d-flex  gap-2'>
                                                <span className='fa fa-eye text-secondary f-14'></span>
                                                <div>
                                                    <h6>Visibble</h6>
                                                    <p className='text-secondary f-12'>Last active 2h ago</p>
                                                </div>
                                            </div>
                                            <label class="custcheck radio">
                                                <input type="radio" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>
                                        <li className='d-flex jc-between align-items-center'>
                                            <div className='d-flex gap-2'>
                                            <span className='fa fa-eye-slash text-secondary f-14'></span>
                                                <div>
                                                    <h6>Hiddec</h6>
                                                    <p className='text-secondary f-12'>Last active 2h ago</p>
                                                </div>
                                            </div>
                                            <label class="custcheck radio">
                                                <input type="radio" name="radio"/>
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                    <div className='col-md-6 mb-md-3 my-2'>
                            <div className='themefloat'>
                                <label>Email</label>
                                <p className='inputtxt' onClick={() => setDrop1(!drop1)}>Bitcoin</p>
                            {drop1 && <div className='drop'>
                                    <ul>
                                        <li className='d-flex jc-between align-items-center'>
                                            <div className='d-flex gap-2'>
                                                <img src={Images.prof} className='img-fuild' />
                                                <div>
                                                    <h6>Bitcoin word</h6>
                                                    <p className='text-secondary f-12'>Last active 2h ago</p>
                                                </div>
                                            </div>
                                            <label class="custcheck radio">
                                                <input type="radio" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>
                                        <li className='d-flex jc-between align-items-center'>
                                            <div className='d-flex gap-2'>
                                                <img src={Images.prof} className='img-fuild' />
                                                <div>
                                                    <h6>Bitcoin word</h6>
                                                    <p className='text-secondary f-12'>Last active 2h ago</p>
                                                </div>
                                            </div>
                                            <label class="custcheck radio">
                                                <input type="radio" name="radio"/>
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                        <div className='col-md-6 mb-md-3 my-2'>
                            <div className='themefloat'>
                                <label>Email</label>
                                <p className='inputtxt' onClick={() => setDrop2(!drop2)}>Bitcoin</p>
                                {drop2 && <div className='drop'>
                                    <ul>
                                        <li className='d-flex jc-between align-items-center'>
                                            <div className='d-flex  gap-2'>
                                                <span className='fa fa-eye text-secondary f-14'></span>
                                                <div>
                                                    <h6>Visibble</h6>
                                                    <p className='text-secondary f-12'>Last active 2h ago</p>
                                                </div>
                                            </div>
                                            <label class="custcheck radio">
                                                <input type="radio" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>
                                        <li className='d-flex jc-between align-items-center'>
                                            <div className='d-flex gap-2'>
                                            <span className='fa fa-eye-slash text-secondary f-14'></span>
                                                <div>
                                                    <h6>Hiddec</h6>
                                                    <p className='text-secondary f-12'>Last active 2h ago</p>
                                                </div>
                                            </div>
                                            <label class="custcheck radio">
                                                <input type="radio" name="radio"/>
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <button className="themebtn w-full py-3">Save</button>
                    </div>

                    
                </div>
               
            </div>
       </div>
    )
}

export default CreateGroup