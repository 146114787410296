import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Images from '../Images';
import isEmpty from "is-empty";
import { useSelector, useDispatch } from 'react-redux'
import * as config from "../../config/config";
import toast, { Toaster } from 'react-hot-toast';
import fileObjectUrl from "../../lib/imageLib";
import { ValidateInputDatas } from "../Validations/Validations"
import { NavLink, useNavigate,useLocation } from 'react-router-dom';
import {updateProfile} from "../../Actions/setProfilepic";

const EditProfileModal = (props) => {
    const [editprof, setEditProf] = useState(true)
    const [fname, set_fname] = useState("")
    const [lname, set_lname] = useState("")
    const [email, set_email] = useState("")
    const [Bio, set_bio] = useState("")
    const [propic, set_pro] = useState("")
    const [backgroundImage, set_backgroundImage] = useState("")
    const [userName, set_userName] = useState("")
    const [displayName, set_displayName] = useState("")
    const [error, set_error] = useState("");
    // const [profilePicture, set_profilePicture] =useState("")


    const userDetails = useSelector(state => state.accountDetails.userDetails)
    console.log("userDetails", userDetails)

    const history = useNavigate()
    const dispatch = useDispatch();

    const onChange = (e) => {
        const {id,value}=e.target
        console.log("event", id,value,typeof(userName))
        if (e.target.id == 'userName'){
            if(value.length>=1){
                set_userName(e.target.value)
            }
        }  
        if (e.target.id == 'displayName')
            set_displayName(e.target.value)
        if (e.target.id == 'Bio')
            set_bio(e.target.value)
        // if (e.target.id == 'backgroundImage')
        //     set_backgroundImage(e.target.value)
        // if (e.target.id == 'propic')
        //     set_pro(e.target.value)

    };


    const onSubmit =async () => {
        var data = {
            displayName:displayName,
            userName:userName
        }
        console.log("_errors_errors", data)
        const { errors, isValid } = await ValidateInputDatas(data, "ProfileUpdate")
        set_error(errors)
        console.log("_errors_errors", errors, isValid)
        var formData = new FormData();
        formData.append("displayName", displayName)
        formData.append("userName", userName)
        formData.append("profilePicture", propic)
        formData.append("backgroundImage", backgroundImage)
        formData.append("Bio", Bio)

        if (Object.keys(errors).length == 0 && isValid === true) {
            var resp = await updateProfile(formData,dispatch);
            console.log("updateProfile_resp",resp)
        }
        if (resp?.status == 500) {
            toast.error("Error !Try Again...", {
              style: {
                minWidth: '300px',
                minHeight: '55px'
              }
            })
          }else {
            toast.success(`${resp?.data?.data?.message}`, {
              style: {
                minWidth: '300px',
                minHeight: '55px'
              }
            })
           props.onDismiss()
          }


    }

    useEffect(() => {
        userData()
    }, [userDetails])

    var userData = async () => {
        try {
            //   var res = await getData(dispatch)
            //   if (!isEmpty(userDetails)) {
            //   console.log("user", res,res.data.data.result)
            // set_userName(userDetails.firstName)
            set_lname(userDetails.lastName)
            set_email(userDetails.EmailId)
            set_pro(userDetails.profilePicture)
            set_userName(userDetails.userName)
            set_displayName(userDetails.displayName)
            set_bio(userDetails.Bio)
            set_backgroundImage(userDetails.backgroundImage)
            //   }
            //   else{
            //     console.log("check")
            //   }
        }
        catch (err) {
            console.log(err, "err")
        }
    }


    const ImageUpload = async (event) => {
        console.log("event?.target?.files[0].type : ", event?.target?.files[0].type)
        if (event?.target?.files[0].type !== "image/png" && event?.target?.files[0].type !== "image/jpg" && event?.target?.files[0].type !== "image/jpeg") {
            return toast.error("Only jpg, png, jpeg image formats are supported", {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            })
        } else {
            console.log(event.target, "seet")
            // const reader = new FileReader();
            // reader.addEventListener('load', () => set_backgroundImage(reader.result));
            // reader.readAsDataURL(event.target.files[0]);
            
             set_backgroundImage(event.target?.files[0])

        }
    }


    const ImageUpload1 = async (event) => {
        console.log("event?.target?.files[0].type : ", event?.target?.files[0].type)
        if (event?.target?.files[0].type !== "image/png" && event?.target?.files[0].type !== "image/jpg" && event?.target?.files[0].type !== "image/jpeg") {
            return toast.error("Only jpg, png, jpeg image formats are supported", {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            })
        } else {
            // console.log(event.target, "seet")
            // const reader = new FileReader();
            // reader.addEventListener('load', () => set_profilePicture(reader.result));
            // reader.readAsDataURL(event.target.files[0]);
            set_pro(event.target?.files[0]);

        }
    }


    return (
        <>
            <Modal show={editprof} className='thememodal ' centered>
                <Modal.Header className='jc-center pb-0'>
                    <div className='text-center'>
                        <Modal.Title>Edit Profile</Modal.Title>
                    </div>
                    <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
                </Modal.Header>
                <Modal.Body>
                    <img src={Images.graycloud} className='cloud img' />
                    <img src={Images.gallery} className='gallery img' />
                    <img src={Images.youtube} className='youtube img' />
                    <img src={Images.all} className='all img' />
                    <div>
                        <div className='editprofimg'>
                            <img src={typeof(backgroundImage)==="string"?  isEmpty(backgroundImage) ? Images.post1:`${config.backurl}/images/${backgroundImage}`: fileObjectUrl(backgroundImage)} className='img-fluid' />
                            <button className='btn uploadimg'>Upload Photo
                                <input type="file" onChange={ImageUpload}/>
                            </button>
                            <div className='profedit'>
                                <div className='position-relative'>
                                    {console.log("typeof(propic)",typeof(propic)==="string")}
                                    <img src={typeof(propic)==="string"?  isEmpty(propic) ? Images.prof1: `${config.backurl}/images/${propic}` : fileObjectUrl(propic) }  />
                                    <button className='btn facamera'>
                                        <span className='fa fa-camera'></span>
                                        <input type="file" onChange={ImageUpload1}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className='f-14 mb-2'>Display Name</label>
                        {console.log("displayName",displayName)}
                        <input type="text" className='form-control themeinput mb-2' id='displayName'  onChange={(e) => { onChange(e) }} value={displayName} />
                    </div>
                    <div>
                        <label className='f-14 mb-2'>User Name</label>
                        <input type="text" className='form-control themeinput mb-2' id='userName' onChange={(e) => { onChange(e) }} value={userName} />
                    </div>

                    <div>
                        <label className='f-14 mb-2'>Bio</label>
                        <div className='position-relative'>
                            <textarea className='form-control' rows="10" placeholder='Add your Bio Here' id='Bio' onChange={(e) => { onChange(e) }} value={Bio} ></textarea>
                            <span className='fa fa-check'></span>
                        </div>
                    </div>

                    <div className=' squarebtn my-4'>
                        <button onClick={onSubmit}> Update <img src={Images.btnimg} /></button>

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default EditProfileModal