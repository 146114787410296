import React, { useState } from 'react';
import Images from '../../Images';
import { Form } from 'react-bootstrap';

const Comments = () =>{
    return(
        <div className="commentbox">
                      <div className="comment">
                        <div className="flexb mb-3">
                          <div>
                            <img src={Images.story2} className="postuserimg" />
                          </div>
                          <div>
                            <h6 className="postuser">John</h6>
                            <p className="userdet">
                              Lorem ipsum is simply dummy
                            </p>
                          </div>
                        </div>
                        <a href="#" className="emergency text-blue">
                          # Emergency Meeting
                        </a>
                        <p className="usercontent">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </p>
                        <div className="d-flex totaldet">
                          <div>
                            <span>01:09 PM. </span>
                            <span>Jun 15,2023</span>
                          </div>
                          <div>
                            <span>
                              <img src={Images.fire} />
                              12.8k
                            </span>
                            <span>
                              <img src={Images.comment1} />
                              1k
                            </span>
                            <span>
                              <img src={Images.share} />
                              1k
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="usericons flexb">
                        <div>
                          <a href="#">
                            <img src={Images.fire} />
                          </a>
                        </div>
                        <div>
                          <a href="#">
                            <img src={Images.comment1} />
                          </a>
                        </div>
                        <div>
                          <a href="#">
                            <img src={Images.share} />
                          </a>
                        </div>
                        <div>
                          <a href="#">
                            <img src={Images.eye} />
                          </a>
                        </div>
                        <div>
                          <a href="#">
                            <img src={Images.save} />
                          </a>
                        </div>
                      </div>
                      <div className="commentlist">
                        <ul>
                          <li>
                            <div className="flexb">
                              <div>
                                <img
                                  src={Images.story2}
                                  className="postuserimg"
                                />
                              </div>
                              <div>
                                <h6 className="postuser">John</h6>
                                <p className="userdet">
                                  Lorem ipsum is simply dummy
                                </p>
                                <div className="othercomment">
                                  <span>
                                    <a href="#">
                                      <img src={Images.fire} />
                                      10
                                    </a>
                                  </span>
                                  <span>
                                    <a href="#">
                                      <img src={Images.comment1} />
                                      10
                                    </a>
                                  </span>
                                  <span>
                                    <a href="#">
                                      <img src={Images.share} />
                                      1k
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="flexb">
                              <div>
                                <img
                                  src={Images.story2}
                                  className="postuserimg"
                                />
                              </div>
                              <div>
                                <h6 className="postuser">John</h6>
                                <p className="userdet">
                                  Lorem ipsum is simply dummy
                                </p>
                                <div className="othercomment">
                                  <span>
                                    <a href="#">
                                      <img src={Images.fire} />
                                      10
                                    </a>
                                  </span>
                                  <span>
                                    <a href="#">
                                      <img src={Images.comment1} />
                                      10
                                    </a>
                                  </span>
                                  <span>
                                    <a href="#">
                                      <img src={Images.share} />
                                      1k
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="flexb">
                              <div>
                                <img
                                  src={Images.story2}
                                  className="postuserimg"
                                />
                              </div>
                              <div>
                                <h6 className="postuser">John</h6>
                                <p className="userdet">
                                  Lorem ipsum is simply dummy
                                </p>
                                <img src={Images.post1} className="commentimg" />
                                <div className="othercomment">
                                  <span>
                                    <a href="#">
                                      <img src={Images.fire} />
                                      10
                                    </a>
                                  </span>
                                  <span>
                                    <a href="#">
                                      <img src={Images.comment1} />
                                      10
                                    </a>
                                  </span>
                                  <span>
                                    <a href="#">
                                      <img src={Images.share} />
                                      1k
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="flexb">
                              <div>
                                <img
                                  src={Images.story2}
                                  className="postuserimg"
                                />
                              </div>
                              <div>
                                <h6 className="postuser">John</h6>
                                <p className="userdet">
                                  Lorem ipsum is simply dummy Lorem ipsum is simply dummy Lorem ipsum is simply dummy
                                </p>
                                <div className="othercomment">
                                  <span>
                                    <a href="#">
                                      <img src={Images.fire} />
                                      10
                                    </a>
                                  </span>
                                  <span>
                                    <a href="#">
                                      <img src={Images.comment1} />
                                      10
                                    </a>
                                  </span>
                                  <span>
                                    <a href="#">
                                      <img src={Images.share} />
                                      1k
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="commentfoot">
                        <div className="flexb topflex">
                          <div>
                            <img src={Images.story2} className="footuser" />
                          </div>
                          <div>
                            <div className="footmsg">
                              <Form.Control placeholder="What's happening ?"></Form.Control>
                              <div className="flexb">
                                <div>
                                  <a href="#">
                                    <div className="footup">
                                      <img src={Images.paperclip} />
                                      <input
                                        type="file"
                                        className="form-control"
                                      />
                                    </div>
                                  </a>
                                  <a href="#">
                                    <img src={Images.image} />
                                  </a>
                                  <a href="#">
                                    <img src={Images.smiley} />
                                  </a>
                                </div>
                                <div>
                                  <button className="themebtn small blue">
                                    <img src={Images.shareblue} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    )
}

export default Comments