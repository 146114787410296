import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Images from '../Images';
import isEmpty from "is-empty";

const Bio = (props) => {
    const [bio, setBio] = useState(true)
    const [fname, set_fname] = useState("")
    const [displayName, set_displayName] = useState("")
    const [lname, set_lname] = useState("")
    const [userbio, set_bio] = useState("")
    const [propic, set_pro] = useState("")
    const [background, set_background] = useState("")
    const [userName, set_userName] = useState("")


    const onChange = (e) => {
        console.log("event", e)
        if (e.target.id == 'displayName')
            set_displayName(e.target.value)
        if (e.target.id == 'userName')
            set_userName(e.target.value)
        // if (e.target.id == 'userbio')
        // set_bio(e.target.value)
        if (e.target.id == 'backgroundImage')
            set_background(e.target.value)
        if (e.target.id == 'profilePicture')
            set_pro(e.target.value)


    };

    return (
        <>
            <Modal show={bio} className='thememodal ' centered>
                <Modal.Header className='jc-center pb-0'>
                    <div className='text-center'>
                        <Modal.Title>Bio</Modal.Title>
                        <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p>
                    </div>
                    <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
                </Modal.Header>
                <Modal.Body>
                    <img src={Images.graycloud} className='cloud img' />
                    <img src={Images.gallery} className='gallery img' />
                    <img src={Images.youtube} className='youtube img' />
                    <img src={Images.all} className='all img' />
                    <p className='d-flex mb-0'> <input type='text' className='mb-1' id="firstName" value={isEmpty(fname) ? <>newuser</> : fname} onChange={(e) => { onChange(e) }} />
                    </p>

                    <p className='d-flex mb-0'>   <input type='text' className='mb-0 text-muted' id="userName" value={isEmpty(fname) ? <>newuser</> : fname} onChange={(e) => { onChange(e) }} />

                    </p>
                    <div className='position-relative'>
                        <textarea className='form-control'>gfsdhjf</textarea>
                        <span className='fa fa-check'></span>
                    </div>
                    <div className=' squarebtn my-4'>
                        <button> Update <img src={Images.btnimg} /></button>

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Bio