import React, { useState } from 'react';
import Images from '../Images';

const Community = () =>{
    return(
        <div id="community">
            <div className='d-flex jc-between align-items-end px-xl-0 px-3 py-xl-0 py-3'>
                <p className='subhead'><span className='dnonein991'>My</span> Community</p>
                <div className=''> 
                    <button className='morebtn small me-1'><span className='fa fa-search'></span></button>
                    <button className='morebtn small'><span className='fa fa-plus'></span></button>
                </div>
           </div>
           <div className='themebord p-0 showin1199'></div>
           <ul className=' px-xl-0 px-3 py-3 text-start mb-0'>
              <li className='d-flex align-items-center jc-between squareimg mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='me-2'><img src={Images.story3} className='img-fluid user' /></div>
                    <div className=''>
                        <h6 className='smallhead'>Blockchain market</h6>
                        <p className='f9txt mb-0'><span className='live avail me-1'></span>24 Members are currently active</p>
                        {/* live, offline */}
                    </div>
                </div>
              </li>
              <li className='d-flex align-items-center jc-between squareimg mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='me-2'><img src={Images.story2} className='img-fluid user' /></div>
                    <div className=''>
                        <h6 className='smallhead'>Blockchain market</h6>
                        <p className='f9txt mb-0'><span className='live avail me-1'></span>24 Members are currently active</p>
                    </div>
                </div>
              </li>
              <li className='d-flex align-items-center jc-between squareimg mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='me-2'><img src={Images.story5} className='img-fluid user' /></div>
                    <div className=''>
                        <h6 className='smallhead'>Blockchain market</h6>
                        <p className='f9txt mb-0'><span className='live avail me-1'></span>24 Members are currently active</p>
                    </div>
                </div>
              </li>
           </ul>
        </div>
    )
}

export default Community