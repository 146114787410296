import React, { useEffect, useState } from 'react';

import Header from '../Header';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Images from '../Images';
import Imagemodal from '../Modals/ImageModal';
import Videomodal from '../Modals/VideoModal';
import CreatePost from '../Modals/CreatePost';
import EditPost from '../Modals/EditPost';
import DeletePost from '../Modals/DeletePost';
import SettingModal from '../Modals/SettingModal';
import Bio from '../Modals/Biomodal';
import { Dropdown, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import Linksec from '../Rightsection.js/Linksec';
import About from '../leftsection/About';
import AllFriendsCont from '../Friend/AllFriendsCont';
import Community from '../Community';
import Posts from '../Posts';
import PostLists from '../Common/PostLists';
import Description from '../Modals/Description';
import InviteGuest from '../Modals/InviteGuest';
import { Link } from 'react-router-dom';

const MyEventProf = () =>{
 
  
  const[showbar, setShowBar] = useState(true)
  const[bio, setBio] = useState(false)

  
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])

  const[imagemodal, setImagemodal] = useState(false)
  const[videomodal, setVideomodal] = useState(false)
  const[createpost, setCreatePost] = useState(false)
  const[editpost, setEditPost] = useState(false)
  const[deletepost, setDeletePost] = useState(false)
  const[setting, setSetting] = useState(false)

  const[description, setDescription] = useState(false)
  const[inviteguest, setInviteGuest] = useState(false)
return(
 <>
 {description && <Description onDismiss={() => setDescription(false)}/>}
 {imagemodal && <Imagemodal onDismiss={() => setImagemodal(false)}/>}
 {videomodal && <Videomodal onDismiss={() => setVideomodal(false)}/>}
 {createpost && <CreatePost onDismiss={() => setCreatePost(false)}/>}
 {editpost && <EditPost onDismiss={() => setEditPost(false)}/>}
 {deletepost && <DeletePost onDismiss={() => setDeletePost(false)}/>}
 {setting && <SettingModal onDismiss={() => setSetting(false)}/>}
 {bio && <Bio onDismiss= {() => setBio(false)}/>}
 {inviteguest && <InviteGuest onDismiss={() => setInviteGuest(false)}/>}


 <Header />
<section id='home' className=''>
<button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
  <div className='themecontainer'>
  <div className='themewidth'>
     <LeftSection  link="events"/>
     <div id="content" className=' w-full d-flex'>
     
     <section id='centersection' className='posts profile'>
         
         <div className='showin1199 showrightsec'><Linksec/></div>  
         
            
            <div className='postlists'>
            <div className='whitebox '>
            <div className='row m-1 evenflow'>
                <div className='col-sm-6 watchleft'>
                    <div className='eventbanner'>
                    <p className='f-12 mb-2'>Lorem ipsum is a dummy content</p>
                    <h6>Highlight | Pakistan vs srilanka | T20I PCB | MA2A</h6>
                    </div>
                </div>
                <div className='col-sm-6 watchright'>
                    <img src={Images.post1} className='img-fluid watchimg' />
                    <div>
                    <button className='btn'>Watch</button>
                    </div>
                </div>
            </div> 
                        
                <div className='d-sm-flex jc-between gap-2 p-sm-3 p-2  pb-2 profilestat'>
                    <div className='d-flex'>
                        <div className='d-sm-flex d-none'>
                        <div className=' br-5 me-2 profimg text'>
                            25
                        </div>
                        </div> 
                        <div>
                            <p className='d-flex mb-0'> <h5  className='mb-1'>David Marvel </h5></p>
                                    
                                    <p className='d-flex mb-0'>   <h6 className='mb-0 text-muted'>@david 
                                
                                    </h6></p>
                                <p className='f-12 text-muted mb-1' onClick={() => setBio(true)}>Lorem ipsum is a dummy content</p>
                                <div className='d-flex align-items-center'>
                                <div className='lineimg'>
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                </div>
                                <span className='themeclr f-12 ms-5'>3.5k Friends</span></div>
                                </div>
                            
                            </div>
                            <div className='mt-sm-0 mt-2'>
                               
                            <Dropdown className="postdrop me-2 noafter d-inline-block">
                                        <Dropdown.Toggle variant="" className="morebtn inverse px-2 noarrow" id="dropdown-basic">
                                            Invite <span className="fas fa-chevron-down"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                     <Link to="/editevent"><button className='morebtn inverse me-2'>Edit</button></Link>
                                <Dropdown className="postdrop noafter d-inline-block">
                                        <Dropdown.Toggle variant="" className="morebtn px-2 noarrow" id="dropdown-basic">
                                             <span className="fas fa-ellipsis-h"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> 
                        </div>  
                            
                        </div>  
    <div className='btnflex'>
        <div className='col-xxxl-8'>
        <div className="whitebox mt-3">
            <div className="bordbtm px-sm-3 px-2 topsearch">
                <div className="pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start d-md-flex jc-between gap-5 align-items-center">
                    <h6 className="fw-500 nowrap my-3">Promote your event</h6>
                    <div className='d-flex w-full align-items-center gap-2 my-3'>
                    <div className='themeprogress active1'></div>
                    <span className='nowrap f-12'> 0 of 3 steps completed</span></div> 
                </div>
            </div>
            <div className='d-sm-flex jc-between flex-1 p-3'>
                <button className='btn modalbtn my-2 d-flex jc-between align-items-center me-2' onClick={() => setDescription(true)}> <i className='fa fa-pencil'></i> Add description <span className='fa fa-chevron-right'></span></button>
                <button className='btn modalbtn my-2 d-flex jc-between align-items-center me-2' onClick={() =>   setInviteGuest(true)}> <i className='fa fa-user-plus'></i> Invite guest <span className='fa fa-chevron-right'></span></button>
                <button className='btn modalbtn my-2 d-flex jc-between align-items-center me-2' onClick={() => setCreatePost(true)}> <i className='fa fa-edit'></i> Start discussion <span className='fa fa-chevron-right'></span></button>
            </div>
        </div> 
        </div>
        <div className='text-center'>
        <div className="whitebox mt-3">
            <div className="bordbtm px-sm-3 px-2 topsearch">
                <div className="pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start d-flex jc-between align-items-center">
                    <h6 className="fw-500 mb-0">Guest list</h6>
                <button className='morebtn'>See all</button>
                </div>
                
            </div>
            <div className='d-sm-flex jc-between flex-1 p-3'>
                <button className='btn modalbtn my-2 me-2'> 1 <br /> Going</button>
                <button className='btn modalbtn my-2 me-2'> 1 <br /> Liked</button>
                <button className='btn modalbtn my-2'> 1 <br /> Interested</button>
            </div>
        </div> 
        </div>
    </div>
    <div className="whitebox mt-3">
        <div className="bordbtm px-sm-3 px-2 topsearch">
            <div className="pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start">
                <h6>Create Post</h6>
                </div>
        </div>
        <div className="d-flex gap-2 p-sm-3 p-2  pb-2">
                <InputGroup className="graysearch">
                <Form.Control  onClick={() => setCreatePost(true)}
                    placeholder="What's happening ?"
                    aria-label="What's happening ?"
                    aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2">
                    <button className="linkbtn px-0">
                    
                    <img src={Images.paperclipblue} />
                    </button>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2">
                    <button className="linkbtn px-0">
                    <img src={Images.imageblue} />
                    </button>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2">
                    <button className="linkbtn px-0">
                    <img src={Images.smileyblue} />
                    </button>
                </InputGroup.Text>
                </InputGroup>
                <div>
                
                <button className="themebtn small blue">
                    <img src={Images.shareblue} />
                </button>
                </div>
        </div>
    </div>    
                               
                    <PostLists />    
               
          {/* <Wrapper className='wrap'> */}
               {/* <Tabs defaultActiveKey="about" id="uncontrolled-tab-example" className="mb-2 themetab " >
             
                   <Tab eventKey="about" title="About">
                     
                          <PostLists />
                     
                   </Tab>
                   <Tab eventKey="discussion" title="Discussion" className='friends '>
                   <PostLists />
                   </Tab>
               </Tabs> */}
             {/* </Wrapper> */}
            </div>
            
       </section>
      {showbar && <RightSection  rightsection="event" />}
      
     </div>
  </div>
  </div>
 
</section>
</> 
)
}


export default MyEventProf
