const Images = {
  logo : require("../Assets/Images/logo.png"),
  logos : require("../../src/Assets/Images/logo1.png"),


  meta : require("../../src/Assets/Images/metamask.png"),
  walletss : require("../../src/Assets/Images/wallet-connect-logo.png"),
  trust : require("../../src/Assets/Images/trust_wallet.png"),
  coin : require("../../src/Assets/Images/coin_base.png"),
  passwords : require("../../src/Assets/Images/password1.png"),

  
  // land
  landbanner : require("../../src/Assets/Images/land/landbanner.png"), 
  side1 : require("../../src/Assets/Images/land/side1.png"), 
  side2 : require("../../src/Assets/Images/land/side2.png"), 
  side3 : require("../../src/Assets/Images/land/side3.png"), 
  side4 : require("../../src/Assets/Images/land/side4.png"), 
  dvg : require("../../src/Assets/Images/land/dvg.png"), 
  team1 : require("../../src/Assets/Images/land/team1.png"), 
  team2 : require("../../src/Assets/Images/land/team2.png"), 
  team3 : require("../../src/Assets/Images/land/team3.png"), 

  float1 : require("../../src/Assets/Images/land/float1.png"), 
  float2 : require("../../src/Assets/Images/land/float2.png"), 
  float3 : require("../../src/Assets/Images/land/float3.png"), 
  float4 : require("../../src/Assets/Images/land/float4.png"), 
  float5 : require("../../src/Assets/Images/land/float5.png"), 
  float6 : require("../../src/Assets/Images/land/float6.png"), 
  btnimg : require("../../src/Assets/Images/land/btnimg.png"), 


  before : require("../../src/Assets/Images/land/before.png"), 


  
  //........... CENTER SECTION
  // top links
  home : require("../../src/Assets/Images/centericons/topicons/home.png"), 
  users : require("../../src/Assets/Images/centericons/topicons/users.png"), 
  comment : require("../../src/Assets/Images/centericons/topicons/comment.png"), 
  commentblue : require("../../src/Assets/Images/centericons/topicons/commentblue.png"), 
  clock : require("../../src/Assets/Images/centericons/topicons/clock.png"), 
  flag : require("../../src/Assets/Images/centericons/topicons/flag.png"), 
  settings : require("../../src/Assets/Images/centericons/topicons/settings.png"), 
  bars : require("../../src/Assets/Images/centericons/topicons/bars.png"), 
  // post
  smiley : require("../../src/Assets/Images/centericons/smiley.png"), 
  smileyblue : require("../../src/Assets/Images/centericons/smileyblue.png"), 
  image : require("../../src/Assets/Images/centericons/image.png"), 
  imageblue : require("../../src/Assets/Images/centericons/imageblue.png"), 
  paperclip : require("../../src/Assets/Images/centericons/paperclip.png"), 
  paperclipblue : require("../../src/Assets/Images/centericons/paperclipblue.png"), 
  share : require("../../src/Assets/Images/centericons/share.png"), 
  shareblue : require("../../src/Assets/Images/centericons/shareblue.png"), 
  post1 : require("../../src/Assets/Images/centericons/post1.jpg"), 
  post2 : require("../../src/Assets/Images/centericons/post2.png"), 
  post3 : require("../../src/Assets/Images/centericons/post3.png"), 
  post4 : require("../../src/Assets/Images/centericons/post4.png"), 
  post5 : require("../../src/Assets/Images/centericons/post5.png"), 
  post6 : require("../../src/Assets/Images/centericons/post6.png"), 
  post7 : require("../../src/Assets/Images/centericons/post7.png"), 
  post8 : require("../../src/Assets/Images/centericons/post8.png"), 
  fire : require("../../src/Assets/Images/centericons/fire.png"), 
  fireblue : require("../../src/Assets/Images/centericons/fireblue.png"), 
  eyeblue : require("../../src/Assets/Images/centericons/eyeblue.png"), 
  eye : require("../../src/Assets/Images/centericons/eye.png"), 
  eyeblue : require("../../src/Assets/Images/centericons/eyeblue.png"), 


  demo : require("../../src/Assets/Images/water.mp4"), 

  //friends
  friends1 : require("../../src/Assets/Images/centericons/friends1.png"), 
  friends2 : require("../../src/Assets/Images/centericons/friends2.png"), 
  friends3 : require("../../src/Assets/Images/centericons/friends3.png"), 
  friends4 : require("../../src/Assets/Images/centericons/friends4.png"), 
  friends5 : require("../../src/Assets/Images/centericons/friends5.png"), 
  friends6 : require("../../src/Assets/Images/centericons/friends6.png"), 
  friends7 : require("../../src/Assets/Images/centericons/friends7.png"), 
  friends8 : require("../../src/Assets/Images/centericons/friends8.png"), 
  friends9 : require("../../src/Assets/Images/centericons/friends9.png"), 
  friends10 : require("../../src/Assets/Images/centericons/friends10.png"), 
  friends11 : require("../../src/Assets/Images/centericons/friends11.png"), 
 
  //   left side icons
  feed : require("../../src/Assets/Images/leftlogo/feed.png"),
  feedblue : require("../../src/Assets/Images/leftlogo/feedblue.png"),
  explore : require("../../src/Assets/Images/leftlogo/explore.png"),
  wallet : require("../../src/Assets/Images/leftlogo/wallet.png"),
  group : require("../../src/Assets/Images/leftlogo/group.png"),
  event : require("../../src/Assets/Images/leftlogo/cal.png"),
  request : require("../../src/Assets/Images/leftlogo/request.png"),
  favuserblue : require("../../src/Assets/Images/leftlogo/favuserblue.png"),

  //rigth side icons
  bell : require("../../src/Assets/Images/rightlogo/bell.png"),

  //story
  story1 : require("../../src/Assets/Images/story/story1.png"),
  story2 : require("../../src/Assets/Images/story/story2.png"),
  story3 : require("../../src/Assets/Images/story/story3.png"),
  story4 : require("../../src/Assets/Images/story/story4.png"),
  story5 : require("../../src/Assets/Images/story/story5.png"),
  story6 : require("../../src/Assets/Images/story/story6.png"),
  story7 : require("../../src/Assets/Images/story/story7.png"), 
  story8 : require("../../src/Assets/Images/story/story8.png"),


  //signup
  cloud : require("../../src/Assets/Images/cloud_colour.png"),
  wallet1 : require("../../src/Assets/Images/wallet.png"),
  all : require("../../src/Assets/Images/chat.png"),
  youtube : require("../../src/Assets/Images/video.png"),

  // friend
  event1 : require("../../src/Assets/Images/friend/event1.png"),
  event2 : require("../../src/Assets/Images/friend/event2.png"),
  event3 : require("../../src/Assets/Images/friend/event3.png"),
  event4 : require("../../src/Assets/Images/friend/event4.png"),
  event5 : require("../../src/Assets/Images/friend/event5.png"),
  event6 : require("../../src/Assets/Images/friend/event6.png"),

  photo1 : require("../../src/Assets/Images/friend/photo1.png"),
  photo2 : require("../../src/Assets/Images/friend/photo2.png"),
  photo3 : require("../../src/Assets/Images/friend/photo3.png"),
  photo4 : require("../../src/Assets/Images/friend/photo4.png"),



  // profile
  profilebg : require("../../src/Assets/Images/profilebg.png"),
  prof : require("../../src/Assets/Images/prof.png"),
  profilebg1 : require("../../src/Assets/Images/profilebg1.png"),
  profilebg2 : require("../../src/Assets/Images/profilebg2.png"),
  prof1 : require("../../src/Assets/Images/prof1.png"),

  // cloud : require("../../src/Assets/Images/cloud_colour.png"),
  whitecloud : require("../../src/Assets/Images/whitecloud.png"),
  graycloud : require("../../src/Assets/Images/graycloud.png"),
  darkgraycloud : require("../../src/Assets/Images/small_cloud.png"),
  // wallet1 : require("../../src/Assets/Images/wallet.png"),
  password : require("../../src/Assets/Images/password.png"),
  // all : require("../../src/Assets/Images/chat.png"),
  // youtube : require("../../src/Assets/Images/video.png"),
  gallery : require("../../src/Assets/Images/gallery1.png"),
  browseprofile : require("../../src/Assets/Images/browse_profile.png"),
  browsw_icon: require("../../src/Assets/Images/browsw_icon.png"),
  nect: require("../../src/Assets/Images/nect.png"),
  editimg: require("../../src/Assets/Images/banner.png"),
  img1: require("../../src/Assets/Images/img_1.png"),
  mail: require("../../src/Assets/Images/mail.png"),
  follower : require("../../src/Assets/Images/followimg.png"),

  galleryimg : require("../../src/Assets/Images/gallery_small.png"),
  userimage: require("../../src/Assets/Images/userimage.png"),
  delete: require("../../src/Assets/Images/delete.png"),


  comment1: require("../../src/Assets/Images/centericons/comt.png"),
  save: require("../../src/Assets/Images/centericons/save.png"),


  // photos
  photo1: require("../../src/Assets/Images/photos/photo_1.png"),
  photo2: require("../../src/Assets/Images/photos/photo_2.png"),
  photo3: require("../../src/Assets/Images/photos/photo_3.png"),
  photo4: require("../../src/Assets/Images/photos/photo_4.png"),
  photo5: require("../../src/Assets/Images/photos/photo_5.png"),
  photo6: require("../../src/Assets/Images/photos/photo_6.png"),

  // features
  feature1: require("../../src/Assets/Images/features/feature_1.png"),

  watervideo : require("../../src/Assets/Images/water.mp4"), 
  followprof:require("../../src/Assets/Images/follow_profile.png"),


  // messages
  chaticon : require("../../src/Assets/Images/messages/chat_icon.png"), 
  message1 : require("../../src/Assets/Images/messages/message_1.png"), 
  message2 : require("../../src/Assets/Images/messages/message_2.png"), 

  puppy : require("../../src/Assets/Images/puppy.gif"), 

}

export default Images