import isEmpty from "is-empty";
const Validator = require("validator");

export const ValidateInputDatas = async (data, type) => {
    console.log(data,"ValidateInputDatas")
    let errors = {}
    let name_regex = /^[A-Za-z\s]*$/;
    let email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (type == "Register") {
        if (isEmpty(data.firstName)) {
            console.log(isEmpty(data.firstName))
            errors.firstName = "This field is required."
        }
        else if (!name_regex.test(data.firstName)) {
            console.log("regex", !data.firstName !== /^[A-Za-z\s]*$/)
            errors.firstName = "First Name should contain only alphabets"
        }

        if (isEmpty(data.lastName)) {
            console.log(isEmpty(data.lastName))
            errors.lastName = "This field is required."
        }
        else if (!name_regex.test(data.lastName)) {
            console.log("regex", !data.lastName !== /^[A-Za-z\s]*$/)
            errors.lastName = "Last Name should contain only alphabets"
        }

        if (isEmpty(data.EmailId)) {
            console.log(isEmpty(data.EmailId))
            errors.EmailId = "This field is required."
        }
        else if (!email_regex.test(data.EmailId)) {
            errors.EmailId = "EmailId should be in correct format"
        }

        if (isEmpty(data.phoneNumber)) {
            console.log(isEmpty(data.phoneNumber))
            errors.phoneNumber = "This field is required."
        }
        else if (isEmpty(data.phoneNumber.split('.')[1])) {
            errors.phoneNumber = "PhoneNumber should not be empty"
        }

    }
    else if(type == "password"){
        if (isEmpty(data.password)) {
            errors.password = "Password field is required";
        }
        // else if (data.password.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/) < 0) {
        //     errors.password = "Your password must contain small and caps letters, special character and number.";
        // }
        if (isEmpty(data.confirmPass)) {
            errors.confirmPass = "Confirm password field is required";
        }
        else if (!Validator.equals(data.password, data.confirmPass)) {
            errors.confirmPass = "Passwords must match";
        }
    }
    else if(type == "login"){
        if (isEmpty(data.EmailId)) {
            console.log(isEmpty(data.EmailId))
            errors.EmailId = "This field is required."
        }
        if (isEmpty(data.password)) {
            errors.password = "Password field is required";
        }
        // else if (data.password.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/) < 0) {
        //     errors.password = "Your password must contain small and caps letters, special character and number.";
        //}
        if (isEmpty(data.captcha)) {
            errors.captcha = "Recaptcha field is required";
        }
    }

    else if (type == "forgetPass") {

        if (isEmpty(data.EmailId)) {
            console.log(isEmpty(data.EmailId))
            errors.EmailId = "This field is required."
        }
        else if (!email_regex.test(data.EmailId)) {
            errors.EmailId = "EmailId should be in correct format"
        }
    }
    else if (type == "otp") {

        if (isEmpty(data.otp)) {      
            errors.otp = "This field is required."
        }
      
    }
    else if (type == "resetData") {

        // if (isEmpty(data.otp)) {
        //     errors.otp = "The field is required."
        // }
        if (isEmpty(data.password)) {
            errors.password = "Password field is required";
        }
        // else if (data.password.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/) < 0) {
        //     errors.password = "Your password must contain small and caps letters, special character and number.";
        // }
        if (isEmpty(data.confirmPass)) {
            errors.confirmPass = "Confirm password field is required";
        }
        else if (!Validator.equals(data.password, data.confirmPass)) {
            errors.confirmPass = "Passwords must match";
        }
    }
    else if (type == "ProfileUpdate") {

        if (isEmpty(data.userName)) {
            errors.userName = "This field is required";
        }
        if (isEmpty(data.displayName)) {
            errors.displayName = "This field is required";
        }
       
    }
    
    
    return {
        errors,
        isValid: isEmpty(errors)
    }
}