import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Images from '../Images';
import { Dropdown, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';

import Linksec from '../Rightsection.js/Linksec';
import Bio from '../Modals/Biomodal';
import About from '../leftsection/About';
import AllFriendsCont from './AllFriendsCont';
import Community from '../Community';
import Imagemodal from '../Modals/ImageModal';
import Videomodal from '../Modals/VideoModal';
import CreatePost from '../Modals/CreatePost';
import EditPost from '../Modals/EditPost';
import DeletePost from '../Modals/DeletePost';
import SettingModal from '../Modals/SettingModal';

const FriendProfCont = () =>{
const[width, setWidth] = useState()
const[bio, setBio] = useState(false)
useEffect(() => {
  var bgwidth = document.getElementById("centersection").scrollWidth;
  setWidth(bgwidth)
},[])
const Wrapper = styled.section 
  
  `
    &:before{
      content: "";
      position: fixed;
      background: #fff;
      height: 109px;
      top: 0;
      // left: 15px;
      // right: 0;
      margin: auto;
      z-index: 9;
      width:${width + "px"};
      border-radius: 0 0 11px 11px;
    }
 
  `
  const[imagemodal, setImagemodal] = useState(false)
  const[videomodal, setVideomodal] = useState(false)
  const[createpost, setCreatePost] = useState(false)
  const[editpost, setEditPost] = useState(false)
  const[deletepost, setDeletePost] = useState(false)
  const[setting, setSetting] = useState(false)


    return(
      <>



                  {imagemodal && <Imagemodal onDismiss={() => setImagemodal(false)}/>}
                    {videomodal && <Videomodal onDismiss={() => setVideomodal(false)}/>}
                    {createpost && <CreatePost onDismiss={() => setCreatePost(false)}/>}
                    {editpost && <EditPost onDismiss={() => setEditPost(false)}/>}
                    {deletepost && <DeletePost onDismiss={() => setDeletePost(false)}/>}
                    {setting && <SettingModal onDismiss={() => setSetting(false)}/>}

                     
       {bio && <Bio onDismiss= {() => setBio(false)}/>}
       
        <section id='centersection' className='posts profile'>
         
          <div className='showin1199 showrightsec'><Linksec/></div>  
          
             
             <div className='postlists'>
            <div className='whitebox '>
                        <div className='profilewall'>
                          <img src={Images.profilebg1} className='img-fluid br-5 me-1'/> 
                      
                         
                         
                        </div>
                          <div className='d-sm-flex jc-between gap-2 p-sm-3 p-2  pb-2 profilestat'>
                          <div className='d-flex'>
                              <div className='d-sm-flex d-none'>
                                  <img src={Images.prof1} className='img-fluid br-5 me-1 profimg'/> 
                              </div> 
                              <div>
                                <p className='d-flex mb-0'> <h5  className='mb-1'>David Marvel </h5></p>
                                  
                                 <p className='d-flex mb-0'>   <h6 className='mb-0 text-muted'>@david 
                                
                                 </h6></p>
                                <p className='f-12 text-muted mb-1' onClick={() => setBio(true)}>Lorem ipsum is a dummy content</p>
                               <div className='d-flex align-items-center'>
                               <div className='lineimg'>
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                  
                                </div>  
                                <span className='themeclr f-12 ms-5'>3.5k Friends</span></div>
                                
                              </div>
                              </div>
                              <div className='mt-sm-0 mt-2'>
                                <button className='morebtn inverse me-2'>Confirm</button>
                                <button className='morebtn me-2'>Cancel</button>
                                <button className='morebtn px-2'><span className='fa fa-ellipsis-h'></span></button>
                              </div>
                            </div>
                           
                        </div>
                
           {/* <Wrapper className='wrap'> */}
                <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="my-2 themetab" >
                <Tab eventKey="all" title="All"> 
                    <div className='whitebox '>
                    <div className='bordbtm px-sm-3 px-2 topsearch'>
                            <div className='pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start'>
                      <span className='f-14 fw-500'>Create Post</span>
                            </div>
                            </div>
                            <div className='d-flex gap-2 p-sm-3 p-2  pb-2'>
                              <InputGroup className="graysearch">
                                  <Form.Control onClick={() => setCreatePost(true)}
                                    placeholder="What's happening ?"
                                    aria-label="What's happening ?"
                                    aria-describedby="basic-addon2" />
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                              </InputGroup>
                            <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                            </div>
                           
                        </div>
                      
                      
                        <div className='allposts'>
                            <div className='position-relative'>
                              <img src={Images.post4} className='img-fluid' />
                              <div className='d-flex postdet'>
                                  <div className='d-flex jc-between w-full align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Images.story1} className='img-fluid b-50 me-1' />
                                        <div>
                                            <p className='mb-0 text-light f-14 fw-500'>David</p>
                                            <p className='mb-0 text-light'>Lorem ipsum</p>
                                        </div>
                                     </div>
                                     <div>
                                      <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setEditPost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                                     </div>
                                  </div>
                              </div>
                            </div>
                            <div className='p-3'> 
                              <div className='dopost mb-sm-3 mb-0'>
                              <div className='d-flex jc-between'>
                                    <div className=''>
                                      
                                    <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                                    <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                                    <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                                      <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                                  
                                      <button className='linkbtn ms-sm-2 ms-0 donate '>
                                      <i class="fas fa-hand-holding-medical showin575"></i>
                                    <span className='hidein575'>Donate</span> </button>  </div>
                                    <div>
                                      <button className='morebtn me-1 donate'> 17 minutes</button>
                                      <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                                      <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                                    </div>
                                </div>
                              </div>
                              <div className='mobilecomment'>
                               <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                               <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                              </div>
                              <div className='commenttext'>
                                <p>
                              <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                                consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                                Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                                </p>
                              </div>
                              <div>
                              <div className='d-flex gap-1'>
                                <InputGroup className="graysearch ">
                                    <Form.Control  onClick={() => setCreatePost(true)}
                                      placeholder="What's happening ?"
                                      aria-label="What's happening ?"
                                      aria-describedby="basic-addon2" />
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                </InputGroup>
                              <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                              </div>
                            </div>
                            </div>
                        </div>
                        <div className='allposts'>
                            <div className='position-relative'>
                              <img src={Images.post2} className='img-fluid' />
                              <div className='d-flex postdet'>
                                  <div className='d-flex jc-between w-full align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Images.story1} className='img-fluid b-50 me-1' />
                                        <div>
                                            <p className='mb-0 text-light'>David</p>
                                            <p className='mb-0 text-light'>Lorem ipsum</p>
                                        </div>
                                     </div>
                                     <div>
                                      <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setEditPost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                                     </div>
                                  </div>
                              </div>
                            </div>
                            <div className='p-3'> 
                              <div className='dopost mb-sm-3 mb-0'>
                              <div className='d-flex jc-between'>
                                    <div className=''>
                                      
                                    <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                                    <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                                    <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                                      <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                                      <button className='linkbtn ms-sm-2 ms-0 donate '>
                                      <i class="fas fa-hand-holding-medical showin575"></i>
                                    <span className='hidein575'>Donate</span> </button>
                                    </div>
                                    <div className='mt-3'>
                                      <button className='morebtn me-1 donate'> 17 minutes</button>
                                      <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                                      <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                                    </div>
                                </div>
                              </div>
                              <div className='mobilecomment'>
                               <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                               <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                              </div>
                              <div className='commenttext'>
                                <p>
                              <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                                consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                                Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                                </p>
                              </div>
                              <div>
                              <div className='d-flex gap-1'>
                                <InputGroup className="graysearch ">
                                    <Form.Control  onClick={() => setCreatePost(true)}
                                      placeholder="What's happening ?"
                                      aria-label="What's happening ?"
                                      aria-describedby="basic-addon2" />
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                </InputGroup>
                              <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                              </div>
                            </div>
                            </div>
                        </div>
                        </Tab>
                    <Tab eventKey="photos" title="Photos">
                        <div className='whitebox '>
                          <div className='gridcont'>
                          <div className='grid-4 p-3'>
                            <img src={Images.photo1} className='img-fluid' />
                            <img src={Images.photo2} className='img-fluid' />
                            <img src={Images.photo3} className='img-fluid' />
                            <img src={Images.photo4} className='img-fluid' />
                          </div>
                          </div>
                        </div>
                    </Tab>
                    <Tab eventKey="videos" title="Videos">
                      <div className='whitebox '>
                            <div className='gridcont'>
                              <div className='grid-6 p-3'>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                              </div>
                            </div>
                        </div>
                       </Tab>
                    <Tab eventKey="music" title="Music">
                        <div className='whitebox '>
                            <div className='gridcont'>
                              <div className='grid-6 p-3'>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                                <video src={Images.demo} ></video>
                              </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="about" title="About">
                        <div className='whitebox p-3'>
                           <About />
                        </div>
                    </Tab>
                    <Tab eventKey="friends" title="Friends" className='friends '>
                      <div className='postlists px-0'> 
                        <AllFriendsCont />
                      </div>
                    </Tab>
                    <Tab eventKey="community" title="Community"  className='friends '>
                    <div className='postlists px-0'> 
                        <Community />
                      </div>
                       </Tab>
                </Tabs>
              {/* </Wrapper> */}
             </div>
             
        </section>
        </>
    )
}

export default FriendProfCont