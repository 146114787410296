import React, { useState } from 'react';
import Images from '../Images';

const ActiveUser = () =>{
    return(
        <div id="activeuser">
        <div className='d-flex jc-between align-items-end p-3'>
            <p className='subhead'>Most Active User</p>
            <div> 
                <button className='morebtn'>More</button>
            </div>
       </div>
       <div className='themebord p-0'></div>
       <ul className='p-3 text-start mb-0'>
       <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story5} className='img-fluid user me-lg-2' />
                <div className=''>
                    <p className='f9txt mb-1'>Lorem ipsum</p>
                    <h6 className='smallhead mb-1'>Crypto Coin Market</h6>
                    <p className='f9txt mb-0'>Trending with james</p>
                </div>
            </div>
            <button className='morebtn small'><img src={Images.favuserblue} className='img-fluid' /></button>
          </li>
          <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story2} className='img-fluid user me-lg-2' />
                <div className=''>
                    <p className='f9txt mb-1'>Lorem ipsum</p>
                    <h6 className='smallhead mb-1'>Crypto Coin Market</h6>
                    <p className='f9txt mb-0'>Trending with james</p>
                </div>
            </div>
             <button className='morebtn small'><img src={Images.favuserblue} className='img-fluid' /></button>
          </li>
          <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story1} className='img-fluid user me-lg-2' />
                <div className=''>
                    <p className='f9txt mb-1'>Lorem ipsum</p>
                    <h6 className='smallhead mb-1'>Crypto Coin Market</h6>
                    <p className='f9txt mb-0'>Trending with james</p>
                </div>
            </div>
             <button className='morebtn small'><img src={Images.favuserblue} className='img-fluid' /></button>
          </li>
       </ul>
    </div>
    )
}

export default ActiveUser