import React, { useState } from 'react';
import { Dropdown, InputGroup, Modal, Form } from 'react-bootstrap';
import Images from '../Images';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const CreateEvent = (props) =>{
    const[createevent, setCreateEvent] = useState(true)
    const [startDate, setStartDate] = useState(new Date());
    const[drop, setDrop] = useState(false)
    const[drop1, setDrop1] = useState(false)
    const[drop2, setDrop2] = useState(false)
    return(
        <>
         <Modal show={createevent} className='thememodal searchmodal' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title className='mb-3'>Create Event</Modal.Title>
          {/* <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p> */}
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body className='p-4'>
             <div className='mb-sm-0 mb-2 '>
                <div className='profilewall'>
                    <img src={Images.profilebg} className='img-fluid br-5 me-1'/> 
                    <button className='btn btn-link p-0 editbtn'> <input type="file" /><span className='fa fa-camera pt-1 f-12 text-dark'></span></button>
                </div>
                <div className='d-sm-flex jc-between gap-2 profilestat ps-4'>
                    <div className='d-flex'>
                        <div className='d-sm-flex d-none'>
                            <img src={Images.prof} className='img-fluid br-5 me-1 profimg'/> 
                        </div>
                        <div>
                            <p className='d-flex mb-0'> <h6 className='mb-1'>David Marvel</h6></p>
                                  
                                 <p className='d-flex mb-0'><h6 className='mb-0 text-muted f-12'> @david </h6></p>
                               
                              </div>
                              </div>
                             
                            </div>
                           
            </div>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            {/* <img src={Images.youtube} className='youtube img'/> */}
            <img src={Images.all} className='all img'/>
            <input type="text" className='form-control themeinput h-42 bordered' placeholder='Event Name'/>
           <div className='d-flex flex-1 my-2 gap-2 h-42'>
                <div><DatePicker showIcon placeholderText='Any date' selected={startDate} onChange={(date) => setStartDate(date)} className='form-control dateinput bordered h-42 w-full'/></div>
                <div><DatePicker showIcon placeholderText='Any date' selected={startDate} onChange={(date) => setStartDate(date)} className='form-control dateinput bordered h-42 w-full'/></div>
           </div> 
         <button className='btn btn-link nodecore themeclr f-12 mb-2 ps-0'><span className='fa fa-plus-circle themeclr'></span> End date and time</button>
           <div className='themefloat modalbtn mb-2 bordered br-5 h-42 py-1 f-12'>
            <p className='inputtxt' onClick={() => setDrop(!drop)}>Is it person or virtual?</p>
                {drop && <div className='drop'>
                    <ul className='mb-0'>
                        <li className='d-flex jc-between align-items-center'>
                            <div className='d-flex  gap-2'>
                                <i className='fa fa-paper-plane text-secondary f-14'></i>
                               <h6>In person</h6>
                                    
                            </div>
                        </li>
                        <li className='d-flex jc-between align-items-center'>
                            <div className='d-flex  gap-2'>
                                <i className='fa fa-video text-secondary f-14'></i>
                               <h6>Is it in person or virtual?</h6>
                                    
                            </div>
                        </li>
                        </ul>
                    </div>}
            </div>
            <div className='themefloat modalbtn mb-2 bordered br-5 h-42 py-1 f-12'>
            <p className='inputtxt' onClick={() => setDrop1(!drop1)}>Who can see it?</p>
                {drop1 && <div className='drop'>
                    <ul className='mb-0'>
                        <li className='d-flex jc-between align-items-center'>
                            <div className='d-flex  gap-2'>
                                <i className='fa fa-paper-plane text-secondary f-14'></i>
                               <h6>In person</h6>
                                    
                            </div>
                        </li>
                        <li className='d-flex jc-between align-items-center'>
                            <div className='d-flex  gap-2'>
                                <i className='fa fa-video text-secondary f-14'></i>
                               <h6>Is it person or virtual?</h6>
                                    
                            </div>
                        </li>
                        </ul>
                    </div>}
            </div>
         
         
          
        
        
        <div className='text-end'>
           <button className='themebtn w-full py-2'>Create Event</button>
          </div>
        </Modal.Body>
       
      
      </Modal>
        </>
    )
}

export default CreateEvent