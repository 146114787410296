import React, { useEffect, useRef } from 'react';
import Images from '../Images';
import { Link } from 'react-router-dom';
import Card from '../Common/Card';


export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth"
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}
const Discover = () =>{
 
  const scrollRef = useHorizontalScroll();
    return(
        <div className='postlists'>
           <div className='whitebox mb-4'>
                <div className='d-flex jc-between p-3'>
                    <h6>Suggeted group</h6>
                    <Link to="/" className=' text-dark nodecore'>View all</Link>
                </div>
                <div className='scrollcards p-3'>
                  <Card />
                </div>
            </div>

            <div className='whitebox mb-4 '>
                <div className='d-flex jc-between p-3'>
                    <h6>Popular near you</h6>
                    <Link to="/" className=' text-dark nodecore'>View all</Link>
                </div>
                <div className='scrollcards p-3'>
                  <Card />
                </div>
            </div>

            <div className='whitebox mb-4'>
                <div className='d-flex jc-between p-3'>
                    <h6>More suggestion</h6>
                    <Link to="/" className=' text-dark nodecore'>View all</Link>
                </div>
                <div className='scrollcards p-3'>
                  <Card />
                </div>
            </div>
       </div>
    )
}

export default Discover