import React, { useState,useEffect } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import Images from '../Images';
import { resetPassword } from '../../Actions/resetpass'
import { ValidateInputDatas } from "../Validations/Validations"
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate, useLocation } from "react-router-dom";
import isEmpty from "is-empty";

const Setup = (props) => {
    const [setup, setSetup] = useState(true)
    const [EmailId, set_EmailId] = useState("");
    const [otp, set_otp] = useState("");
    const [error, set_error] = useState("");
    const [password, set_password] = useState("")
    const [confirmPass, set_confirmPass] = useState("")
    const [showpass, set_showpass] = useState(false)
     const [confirmpass, set_pass] = useState(false)


     const location = useLocation();
     console.log("location", location)


     useEffect(() => {
       
        if (!isEmpty(location?.state?.EmailId)) {
            set_EmailId(location?.state?.EmailId)
          console.log("EmailId Refelecyecd ", location?.state?.EmailId)
         
        }
        
      }, [location])

    const onChange = (e) => {
        console.log("event", e)
        if (e.target.id == 'otp')
            set_otp(e.target.value)
        if (e.target.id == 'password')
            set_password(e.target.value)
        if (e.target.id == 'confirmPass')
            set_confirmPass(e.target.value)
    };


    const onSubmit = async (e) => {

        try {
            var data = {
                otp: otp
            }
            console.log("Data", data)
            const { errors, isValid } = await ValidateInputDatas(data, "otp")
            set_error(errors)
            console.log("_errors_errors", errors, isValid)
            if (Object.keys(errors).length == 0 && isValid === true) {
                var resetData = {
                    EmailId: EmailId,
                    otp: otp
                }
                var resp = await resetPassword(resetData)
                console.log("resetPassword", resp)
                if (resp?.status == 500) {
                    toast.error("Error !Try Again...", {
                        style: {
                            minWidth: '300px',
                            minHeight: '55px'
                        }
                    })
                } else if (resp?.data?.resetPass?.message === "Invalid OTP Please Enter Correctly") {
                    toast.success(`${resp?.data?.resetPass?.message}`, {
                        style: {
                            minWidth: '300px',
                            minHeight: '55px'
                        }
                    })
                } else if(resp?.data?.resetPass?.message === "OTP verified"){
                    toast.success(`${resp?.data?.resetPass?.message}`, {
                        style: {
                            minWidth: '300px',
                            minHeight: '55px'
                        }
                    })
                    props.onShowpass()
                    props.onDismiss()
                }

            }
        }

        catch (err) {
            console.log("resetPassword", err)
        }

    };


    return (
        <>
            <Modal show={setup} className='thememodal' centered>
                <Modal.Header className='jc-center pb-0 pt-5'>
                    <div className='text-center'>
                        <Modal.Title>Setup your password</Modal.Title>
                    </div>
                    <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
                </Modal.Header>
                <Modal.Body>

                    <img src={Images.graycloud} className='cloud img' />
                    <img src={Images.gallery} className='gallery img' />
                    <img src={Images.youtube} className='youtube img' />
                    <img src={Images.all} className='all img' />
                    <div className='position-relative mt-4 input mx-auto'>

                        <input className='form-control' placeholder='Enter OTP' id="otp" onChange={(e) => { onChange(e) }} />
                        <span className="text-danger  mb-3 d-block text-left">{error.otp}</span>


                        {/* <div>
                            <InputGroup className="mb-2">
                                <Form.Control
                                    className='form-control' type={showpass ? "text" : "password"} placeholder='Reset password' id="password" onChange={(e) => { onChange(e) }}
                                />
                                <InputGroup.Text id="basic-addon2">   {showpass ?
                                    <span className="fa fa-eye text-secondary f-14" onClick={() => { set_showpass(!showpass) }}></span> :
                                    <span className="fa fa-eye-slash text-secondary f-14" onClick={() => { set_showpass(!showpass) }}></span>
                                }</InputGroup.Text>

                            </InputGroup>

                        </div>

                        <div>
                            <InputGroup className="mb-2">
                                <Form.Control
                                    className='form-control' type={confirmpass ? "text" : "password"} placeholder='Confirm password' id="confirmPass" onChange={(e) => { onChange(e) }}
                                />
                                <InputGroup.Text id="basic-addon2">
                                    {confirmpass ?
                                        <span className="fa fa-eye text-secondary f-14" onClick={() => { set_pass(!confirmpass) }}></span> :
                                        <span className="fa fa-eye-slash text-secondary f-14" onClick={() => { set_pass(!confirmpass) }}></span>
                                    }
                                </InputGroup.Text>

                            </InputGroup>
                            <span className="text-danger  mb-3 d-block text-left">{error.confirmPass}</span>
                        </div> */}

                        {/* <span className="text-danger  mb-3 d-block text-left">{error.otp}</span>
                        <input className='form-control' placeholder='Reset password' id="password" onChange={(e) => { onChange(e) }} />

                        <span className="text-danger  mb-3 d-block text-left">{error.password}</span>
                        <input className='form-control' placeholder='Confirm password' id="confirmPass" onChange={(e) => { onChange(e) }} />

                        <span className="text-danger  mb-3 d-block text-left">{error.confirmPass}</span> */}

                    </div>
                    <div className=' squarebtn my-5'>
                        <button onClick={onSubmit} state={{ EmailId: EmailId }}>Proceed<img src={Images.btnimg}/></button>

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Setup