import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Images from "./Images";
import { Form, InputGroup, Tab, Tabs, Button } from "react-bootstrap";
import { BsGoogle, BsApple } from "react-icons/bs";

import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Captchaaa from "../Components/Recaptch"
import Setup from "./Modals/SetupModal";
import OtpModal from './Modals/OtpModal';
import Password from "./Modals/Password";
import LeftCarousel from "./LeftCarousel";
import { login } from '../Actions/login';
import { ValidateInputDatas } from "./Validations/Validations"
import toast, { Toaster } from 'react-hot-toast';
import axios, {setAuthToken} from '../utils/setAuthtoken';
import isEmpty from 'is-empty';
import { useSelector, useDispatch } from 'react-redux'

const Captchalogin = () => {
  const [setup, setSetup] = useState(false)
  const [setotp, Setotop] = useState(false)
  const [setPass, setSetPass] = useState(false)
  const [EmailId, set_EmailId] = useState("");
  const [password, set_password] = useState("")
  const [captcha, set_captcha] = useState("")
  const [error, set_error] = useState("");
  const [check, set_check] = useState(false);
  const [rememberme, set_rememberme] = useState(false)
  const [showpass, set_showpass] = useState(false)
  const dispatch = useDispatch();


  const history = useNavigate()

  const onChange = (e) => {
    console.log("event", e)
    if (e.target.id == 'password')
      set_password(e.target.value)

    if (e.target.id == 'EmailId')
      set_EmailId(e.target.value)

  };



  const onSubmit = async (e) => {
    console.log("Data",EmailId)
    try {
      var data = {
        EmailId: EmailId,
        password: password,
        captcha: captcha
        // password: password,
        // walletAddress :req.body.walletAddress
      }
      const { errors, isValid } = await ValidateInputDatas(data, "login")
      set_error(errors)
      console.log("_errors_errors", errors, isValid)
      if (Object.keys(errors).length == 0 && isValid === true) {
        var logData = {
          EmailId: EmailId,
          password: password,
          // password: password,
          // walletAddress :req.body.walletAddress
        }
        var resp = await login(logData,dispatch)

        setAuthToken(resp?.data?.data?.token)
        console.log("login", resp)
        if (resp?.status == 500) {
          toast.error("Error !Try Again...", {
            style: {
              minWidth: '300px',
              minHeight: '55px'
            }
          })
        } else if (resp?.data?.data?.message === "Password Incorrect" || resp?.data?.data?.message === "User Not Found") {
          toast.success(`${resp?.data?.data?.message}`, {
            style: {
              minWidth: '300px',
              minHeight: '55px'
            }
          })
        } else {
          toast.success(`${resp?.data?.data?.message}`, {
            style: {
              minWidth: '300px',
              minHeight: '55px'
            }
          })
          // var resp = getData()
          if( isEmpty(resp?.data?.data?.result.profilePicture)) {
             history('/setprofilepicture')
          }else{
            history('/profile')
          }

        }

      }


    }
    catch (err) {
      console.log("login", err)
    }

  };
  //  var remember = localStorage.setItem("rememberme",false);


  useEffect(() => {
    var rem = localStorage.getItem("rememberme")
    console.log("rem",rem,rem == true)
    if(rem == 'true'){
      console.log("rem_local",rem,localStorage.getItem("Email"))
      set_EmailId(localStorage.getItem("Email"))
      set_error("")
    }
    else{
      set_EmailId("")
    }
}, [])



  function remember(e) {
    
    const {value,checked}=e.target
    console.log("Remember", checked) 
    if (checked === true) {
      localStorage.setItem("rememberme", true)
      set_check(true)
    }
    else {
      localStorage.setItem("rememberme", false)
      set_check(false)
    }
  }

  return (
    <>
      {setup && <Setup onDismiss={() => { setSetup(false); Setotop(true) }} />}
      {setotp && <OtpModal onDismiss={() =>{Setotop(false)}} onShowpass={()=>{setSetPass(true)}}/>}
      {setPass && <Password onDismiss={() => setSetPass(false)} />}
      <div>
        <div className="signup">
          <div className="container">
            <div className="row mh-100">
              <div className="col-md-5 col-lg-6">
                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                  <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                </div>
                <div className='carouselnone'>
                <div className="position-relative pb-5">
                  <img className="imagecloud" src={Images.whitecloud} />
                </div>
                <div className="mt-4">
                  <OwlCarousel className="owl-theme" {...options1}>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                  </OwlCarousel>
                </div>
                <div className="position-relative pt-5">
                  <img className="imagecloud1" src={Images.whitecloud} />
                </div>
                </div> */}
                <LeftCarousel />
              </div>
              <div className="col-md-7 col-lg-6">
                <div className="signupcard captchalogin">
                  <h4 className="connectheading text-center">
                    Setup your Account
                  </h4>
                  <p className="condition mt-3 mb-2">
                    By Continuing, you agree to our
                    <span>
                      <Link to="/"> Terms and Service,</Link>
                    </span>{" "}
                    including the use of Cookies.
                  </p>
                  <img className="leftgraycloud" src={Images.gallery} />
                  <img className="graycloud" src={Images.graycloud} />
                  <img className="imagecloudsetup" src={Images.cloud} />
                  <div className="row justify-content-center mt-2">
                    <div className="col-lg-7 col-md-10 col-sm-8 col-12">
                      <div className="task mt-2">
                        <div className="pt-4 pb-4 text-center">
                          <Link to="/connectwallet" className=" position-relative">
                            <Button variant="primary" className="signupwallet">
                              Connect Wallet
                            </Button>
                            
                            <img className="signupicon" src={Images.wallet1} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="bordbt">
                    <p className="or">OR</p>
                  </div>

                  <div className="setupfield mt-4">
                    <img
                      className="darkgraycloudsetup"
                      src={Images.darkgraycloud}
                    />
    
                    <Form>
                      <Form.Group
                        className="mb-3"

                      >
                        <Form.Control
                          type="text"
                          placeholder="Username / Email / Password"
                          id="EmailId"
                          onChange={(e) => { onChange(e) }}
                          value={EmailId && EmailId}
                        />
                        <span className="text-danger  mb-3 d-block text-left">{error.EmailId}</span>
                      </Form.Group>
                      {/* <Form.Group
                        className="mb-2"

                      >
                        <Form.Control type="password" placeholder="Password" id="password" onChange={(e) => { onChange(e) }} />
                        <span className="text-danger  mb-3 d-block text-left">{error.password}</span>
                      </Form.Group> */}
                      <div>
                        <InputGroup className="mb-2">
                          <Form.Control
                            type={showpass ? "text":"password"} placeholder="Password" id="password" onChange={(e) => { onChange(e) }}
                          />
                          <InputGroup.Text id="basic-addon2"> 
                          {showpass ? 
                          <span className="fa fa-eye text-secondary f-14" onClick={()=>{set_showpass(!showpass)}}></span>:
                          <span className="fa fa-eye-slash text-secondary f-14" onClick={()=>{set_showpass(!showpass)}}></span>
                          }
                          
                          
                          </InputGroup.Text>

                        </InputGroup>
                        <span className="text-danger  mb-3 d-block text-left">{error.password}</span>
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <Form.Group>

                            <Form.Check className="d-flex gap-1" type="checkbox" label="Remember me" onChange={remember} checked={check} />

                          </Form.Group>
                        </div>
                        <div>
                          <span className="loginnow">
                            <Link onClick={() => setSetup(true)} state={{ EmailId: EmailId }}> Forgot Password? </Link>
                          </span>
                        </div>
                      </div>
                      <div className="captcha_sec">

                      <Captchaaa onchange={(e) => { console.log(e, "Captchaaa"); set_captcha(e) }} />
                      </div>
                      <span className="text-danger  mb-3 d-block text-left">{error.captcha}</span>
                    </Form>
                  </div>

                  <div className="allimages">
                    {/* <img className='imagecloud' src={Images.cloud} /> */}
                    {/* <img className='darkgraycloud' src={Images.darkgraycloud} /> */}
                    <img className="commenticon" src={Images.all} />
                    <img className="youtubeicon" src={Images.youtube} />
                    <div className="pt-4 pb-4 text-center ">
                      <Link className="position-relative" onClick={onSubmit}>

                      <Button variant="primary" className="signupwallet">
                        Login
                      </Button>
                      <img className="signupicon" src={Images.wallet1} />
                      </Link>
                    </div>
                  </div>

                  <div className="pt-3">
                    <p className="text-center already">
                      <span>Haven't registered an account ?</span>{" "}
                      <span className="loginnow">
                        <Link to="/signup"> Sign Up here </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Captchalogin;
