import React, { useState } from 'react';
import { Dropdown, InputGroup, Modal, Form } from 'react-bootstrap';
import Images from '../Images';

const ReportProfile = (props) =>{
    const[reportprofilemodal, setReportprofilemodal] = useState(true)
    return(
        <>
         <Modal show={reportprofilemodal} className='thememodal modal_upper_z' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title className='mb-3'>Report User</Modal.Title>
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body className='p-4'>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            {/* <img src={Images.youtube} className='youtube img'/> */}
            <img src={Images.all} className='all img'/>
         
            <p className='f-14 report_text'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet</p>
            <ul className='report_ul'>
                        <li>
                            
                          <div className="flexb"> 
                          <div>
                              <Form>
                                <Form.Check type="checkbox"></Form.Check>
                              </Form>
                            </div>
                            <div className="newdet">
                             
                              <div>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, lorem ipsum</p>
                              </div>
                            </div>
                           
                          </div>
                        </li>

                        <li>
                            
                            <div className="flexb"> 
                            <div>
                                <Form>
                                  <Form.Check type="checkbox"></Form.Check>
                                </Form>
                              </div>
                              <div className="newdet">
                               
                                <div>
                                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, lorem ipsum</p>
                                </div>
                              </div>
                             
                            </div>
                          </li>

                          <li>
                            
                            <div className="flexb"> 
                            <div>
                                <Form>
                                  <Form.Check type="checkbox"></Form.Check>
                                </Form>
                              </div>
                              <div className="newdet">
                               
                                <div>
                                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, lorem ipsum</p>
                                </div>
                              </div>
                             
                            </div>
                          </li>

                          <li>
                            
                            <div className="flexb"> 
                            <div>
                                <Form>
                                  <Form.Check type="checkbox"></Form.Check>
                                </Form>
                              </div>
                              <div className="newdet">
                               
                                <div>
                                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, lorem ipsum</p>
                                </div>
                              </div>
                             
                            </div>
                          </li>

                          <li>
                            
                            <div className="flexb"> 
                            <div>
                                <Form>
                                  <Form.Check type="checkbox"></Form.Check>
                                </Form>
                              </div>
                              <div className="newdet">
                               
                                <div>
                                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, lorem ipsum</p>
                                </div>
                              </div>
                             
                            </div>
                          </li>

                          <li>
                            
                            <div className="flexb"> 
                            <div>
                                <Form>
                                  <Form.Check type="checkbox"></Form.Check>
                                </Form>
                              </div>
                              <div className="newdet">
                               
                                <div>
                                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, lorem ipsum</p>
                                </div>
                              </div>
                             
                            </div>
                          </li>
                    </ul>
        <div className='text-end'>
           <button className='morebtn dark me-2' onClick={props.onDismiss}>Confirm</button>
           <button className='morebtn bordered' onClick={props.onDismiss}>Cancel</button>
          </div>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default ReportProfile