import axios from "../utils/setAuthtoken";
import * as config from "../config/config";
import { setAuthToken } from '../utils/setAuthtoken'
import {
    SET_USER_DETAILS,
} from '../constants/constants'
import isEmpty from "is-empty";

export const setAccountData = (data) => {
    // console.log('dats----------------------->',data)
    return {

        type: SET_USER_DETAILS,
        data: {
            userDetails: {
                EmailId: data.EmailId,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                profilePicture: data.profilePicture,
                walletAddress: data.walletAddress,
                userName: data.userName,
                displayName: data.displayName,
                Bio:data.Bio,
                backgroundImage:data.backgroundImage
            }
        },
    };
};


export const profilePicture = async (data,dispatch) => {
    try {
        console.log("profilePicture")
        let respData = await axios.post(config.backurl + "/api/profilePicture", data)
        if (respData.status == 200) {
            console.log("profilePicture_res", respData);
        }
        if (!isEmpty(respData?.data?.data?.result)) {
            dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("profilePicture_err", err);
    }
}


export const getData = async (dispatch) => {
    try {
        console.log("getData")
        let respData = await axios.post(config.backurl + "/api/getUser")
        if (respData.status == 200) {
            console.log("getData_res", respData);
        }
        if (!isEmpty(respData?.data?.data?.result)) {
            dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("getData_err", err);
    }
}


export const getProfileData = async (param) => {
    console.log("parsm_data", param)
    try {
        let result = await axios.get(config.backurl + `/api/getProfileData/${param}`);
        if (result.status == 200) {
            return result;
        }
    } catch (err) {
        console.log("getprofileData_err", err)
    }
}

export const updateProfile = async (formData,dispatch) => {
    try {
        console.log("updateProfile")
        let respData = await axios.post(config.backurl + "/api/updateProfile", formData)
        if (respData.status == 200) {
            console.log("updateProfile_res", respData);
        }
        if (!isEmpty(respData?.data?.data?.result)) {
            dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("updateProfile_err", err);
    }
}
