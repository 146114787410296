import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Images from '../Images';

const Friends = (props) =>{
    return(
    <div className='postlists mt-1'>
        <div className='whitebox p-3'>
            <h6 className='mb-3'>Friends</h6>
       <div className='row'>
            <div className='col-xxl-3 col-md-4 col-sm-6 text-center mb-3'>
                <div className='frndcard d-flex flex-column'>
                    <img src={Images.friends1} className='img-fluid' />
                    <div className='cont py-2'>
                        <p className='mb-0 f-14'>Bitcoin market</p>
                    <div className='w-same w100'>
                        <button className='morebtn m-auto dark d-block mb-2'>Add friend</button>
                        <button className='morebtn m-auto bordered d-block'>Remove</button>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-sm-6 text-center mb-3'>
                <div className='frndcard d-flex flex-column'>
                    <img src={Images.friends2} className='img-fluid' />
                    <div className='cont py-2'>
                        <p className='mb-0 f-14'>Bitcoin market</p>
                    <div className='w-same w100'>
                        <button className='morebtn m-auto dark d-block mb-2'>Add friend</button>
                        <button className='morebtn m-auto bordered d-block'>Remove</button>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-sm-6 text-center mb-3'>
                <div className='frndcard d-flex flex-column'>
                    <img src={Images.friends3} className='img-fluid' />
                    <div className='cont py-2'>
                        <p className='mb-0 f-14'>Bitcoin market</p>
                    <div className='w-same w100'>
                        <button className='morebtn m-auto dark d-block mb-2'>Add friend</button>
                        <button className='morebtn m-auto bordered d-block'>Remove</button>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-sm-6 text-center mb-3'>
                <div className='frndcard d-flex flex-column'>
                    <img src={Images.friends4} className='img-fluid' />
                    <div className='cont py-2'>
                        <p className='mb-0 f-14'>Bitcoin market</p>
                    <div className='w-same w100'>
                        <button className='morebtn m-auto dark d-block mb-2'>Add friend</button>
                        <button className='morebtn m-auto bordered d-block'>Remove</button>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-sm-6 text-center mb-3'>
                <div className='frndcard d-flex flex-column'>
                    <img src={Images.friends5} className='img-fluid' />
                    <div className='cont py-2'>
                        <p className='mb-0 f-14'>Bitcoin market</p>
                    <div className='w-same w100'>
                        <button className='morebtn m-auto dark d-block mb-2'>Add friend</button>
                        <button className='morebtn m-auto bordered d-block'>Remove</button>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-sm-6 text-center mb-3'>
                <div className='frndcard d-flex flex-column'>
                    <img src={Images.friends6} className='img-fluid' />
                    <div className='cont py-2'>
                        <p className='mb-0 f-14'>Bitcoin market</p>
                    <div className='w-same w100'>
                        <button className='morebtn m-auto dark d-block mb-2'>Add friend</button>
                        <button className='morebtn m-auto bordered d-block'>Remove</button>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-sm-6 text-center mb-3'>
                <div className='frndcard d-flex flex-column'>
                    <img src={Images.friends7} className='img-fluid' />
                    <div className='cont py-2'>
                        <p className='mb-0 f-14'>Bitcoin market</p>
                    <div className='w-same w100'>
                        <button className='morebtn m-auto dark d-block mb-2'>Add friend</button>
                        <button className='morebtn m-auto bordered d-block'>Remove</button>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-sm-6 text-center mb-3'>
                <div className='frndcard d-flex flex-column'>
                    <img src={Images.friends8} className='img-fluid' />
                    <div className='cont py-2'>
                        <p className='mb-0 f-14'>Bitcoin market</p>
                    <div className='w-same w100'>
                        <button className='morebtn m-auto dark d-block mb-2'>Add friend</button>
                        <button className='morebtn m-auto bordered d-block'>Remove</button>
                        </div> 
                    </div> 
                </div>
            </div>
           
        </div>  
        </div>
      
    </div>
    )
}

export default Friends