import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Images from './Images';
import { Form, InputGroup, Tab, Tabs, Button } from 'react-bootstrap';
import { BsGoogle, BsApple } from 'react-icons/bs';
// import OwlCarousel from "react-owl-carousel-rtl";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import LeftCarousel from './LeftCarousel';
import { useGoogleLogin } from '@react-oauth/google';
// import {AppleLogin} from "react-apple-login";
import { googleLogin } from '../Actions/login'
import axios from "axios";
import isEmpty from 'is-empty';
import AppleLogin from "react-apple-login";
import jwt from 'jsonwebtoken';
import * as config from "../config/config";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux'

const Signup = () => {

    const [user, setUser] = useState([]);
    const [auth, setAuth] = useState([]);
    const [profile, setProfile] = useState([]);
    const dispatch = useDispatch();

    const history = useNavigate()
    // const options1 = {
    //     loop: true,
    //     margin: 20,
    //     nav: true,
    //     dots: false,
    //     smartSpeed: 1200,
    //     navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
    //     autoplay: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // }

    var login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });


    const handleAppleSignInSuccess = (response) => {
        console.log("apple_response", response);
    };

    const handleAppleSignInFailure = (error) => {
        console.error('Error signing in with Apple:', error);
    };


    useEffect(() => {
        document.getElementById('appleid-signin').classList.add('d-none')

        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then(async (res) => {
                    // setProfile(res.data);
                    console.log("login", res)
                    // history('/setupaccount',{state:res.data})
                    let data = {
                        EmailId: res.data.email
                    }
                    var resp = await googleLogin(data,dispatch)
                    console.log("resp", resp)
                    if (resp.data.data.message === "User Already Exists" && !isEmpty(resp?.data?.data?.result.profilePicture)) {
                        history('/profile')
                    }
                    else if (resp.data.data.message === "User Already Exists" && isEmpty(resp?.data?.data?.result.profilePicture)) {
                        history('/setprofilepicture')
                    }
                    else {
                        let data = res.data
                        data.key = 'googelLogin'
                        history('/setupaccount', { state: data })
                    }
                })
                .catch((err) => console.log(err));
        } else {

        }
    }, [user]);


    const responseFacebook = (response) => {
        console.log(response);
      }


    return (
        <>

            <div>
                <div className='signup'>
                    <div className='container'>
                        <div className='row mh-100'>
                            <div className='col-md-5 col-lg-6'>
                                <LeftCarousel />
                                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                                    <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                                </div>

                                <div className='carouselnone'>
                                <div className='position-relative pb-5'>
                                    <img className='imagecloud' src={Images.whitecloud} />
                                </div>
                                <div className='mt-md-5 mt-4'>
                                    <OwlCarousel className='owl-theme' {...options1}>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                    </OwlCarousel>
                                </div>
                                <div className='position-relative pt-5'>
                                    <img className='imagecloud1' src={Images.whitecloud} />
                                </div>
                                </div> */}

                            </div>
                            <div className='col-md-7 col-lg-6'>
                                <div className='signupcard'>
                                    <h4 className='heading text-center'>Join DVG today</h4>
                                    <p className='subheading text-center mb-4'></p>
                                    <img className='leftgraycloud' src={Images.gallery} />
                                    <img className='graycloud' src={Images.graycloud} />
                                    <div className='row justify-content-center mt-5'>
                                        <div className='col-md-4 col-5'>

                                            <div className='googles'><BsGoogle className='gicon' onClick={() => { login() }} /></div>

                                        </div>
                                        <div className='col-md-4 col-5'>
                                            <div className='googles'>
                                                <BsApple className='gicon' onClick={() => {
                                                    document.getElementById('appleid-signin').click()
                                                    
                                                }} />
                                                <AppleLogin
                                                    clientId="in.maticz.dvg.sid"
                                                    redirectURI="https://dvg.maticz.in"
                                                    className='d-none'
                                                    usePopup={true}
                                                    scope="email name"
                                                    responseMode="fragment"
                                                    responseType="code"
                                                    state="origin:web"
                                                    onSuccess={()=>{handleAppleSignInSuccess()}}
                                                    onFailure={()=>{handleAppleSignInFailure()}}
                                                    callback={(data)=>{
                                                        console.log(data,'Apple response')
                                                        let idToken = jwt.decode(data.authorization.id_token);
                                                        console.log("idToken",idToken)
                                                        if(data.user.email.includes('@privaterelay.appleid.com')){
                                                            toast.error("Error !You have to enable your Email ", {
                                                                style: {
                                                                    minWidth: '300px',
                                                                    minHeight: '55px'
                                                                }
                                                            })
                                                            history('/signup')
                                                        }
                                                        else if (!data.error) {
                                                            axios
                                                              .post(config.backurl +"/api/auth", data)
                                                              .then(resp => {
                                                                console.log("resp_data",resp)
                                                                if (resp.data.data.message === "User Already Exists" && !isEmpty(resp?.data?.data?.result.profilePicture)) {
                                                                    history('/profile')
                                                                }
                                                                else if (resp.data.data.message === "User Already Exists" && isEmpty(resp?.data?.data?.result.profilePicture)) {
                                                                    history('/setprofilepicture')
                                                                }
                                                                else {
                                                                    let data = {
                                                                        EmailId:data.user.email,
                                                                        firstName:data.user.name.firstName,
                                                                        lastName:data.user.name.lastName
                                                                    }
                                                                    
                                                                    data.key = 'appleLogin'
                                                                    history('/setupaccount', { state: data })
                                                                }
                                                              })
                                                        }
                                                    }}
                                                   
                                                />
                                                {/* <div
                                                    id="appleid-signin"
                                                    className="signin-button"
                                                    data-color="black"
                                                    data-border="true"
                                                    data-type="sign-in"
                                                ></div> */}

                                                {/* <FacebookLogin
                                                    appId="610646917846579" //APP ID NOT CREATED YET
                                                    fields="name,email"
                                                    className='d-none'
                                                    callback={responseFacebook}
                                                /> */}
                                            </div>
                                        </div>

                                    </div>

                                    <div className='bordbt'>
                                        <p className='or'>OR</p>
                                    </div>
                                    <div className='allimages'>
                                        <img className='imagecloud' src={Images.cloud} />
                                        <img className='darkgraycloud' src={Images.darkgraycloud} />
                                        <img className='commenticon' src={Images.all} />
                                        <img className='youtubeicon' src={Images.youtube} />
                                        <div className='pt-5 pb-4 text-center'>
                                            <Link to="/connectwallet" className='position-relative'>
                                                <Button variant="primary" className='signupwallet'>
                                                    Sign up with Wallet </Button>
                                                <img className='signupicon' src={Images.wallet1} />
                                            </Link>
                                        </div>
                                        <div className='pt-3 pb-3 text-center'>
                                            <Link to="/setupaccount" className='position-relative'>
                                                <Button variant="primary" className='passwordss'>
                                                    Sign up with password</Button>
                                                <img className='signupicon1 signupicon1_sign' src={Images.password} />
                                            </Link>

                                        </div>
                                    </div>

                                    <div className='pt-3'>
                                        <p className='text-center already'><span>Already a Lander?</span> <span className='loginnow'><Link to="/login"> Login Now </Link></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Signup;