import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Images from './Images';
import { Form, InputGroup, Tab, Tabs, Button } from 'react-bootstrap';
import { BsGoogle, BsApple } from 'react-icons/bs';
// import OwlCarousel from "react-owl-carousel-rtl";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link,useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaRegCopy } from 'react-icons/fa';
import Slider from "react-rangeslider";
import "../../node_modules/react-rangeslider/lib/index.css";
import LeftCarousel from './LeftCarousel';
import toast, { Toaster } from 'react-hot-toast';
import {profilePicture} from '../Actions/setProfilepic'
import setAuthToken from '../utils/setAuthtoken';
import isEmpty from 'is-empty';
import { useSelector, useDispatch } from 'react-redux'


const Setprofilepicture = () => {

    const [checked, setChecked] = useState(false);
    const [file, setFile] = useState('');
    const [slide, setSlide] = useState({
        value: 0,
    });
    const [image , set_image] = useState('')


    const slidechange = (value) => {
        setSlide({
            value: value,
        });
    };

 const dispatch = useDispatch();
  const history = useNavigate()
    // const imgsrc = (e) => {
    //     console.log('e.target.file', e.target.files[0])
    //     setFile(e.target.files[0])
    // }

    // const options1 = {
    //     loop: true,
    //     margin: 20,
    //     nav: true,
    //     dots: false,
    //     smartSpeed: 1200,
    //     navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
    //     autoplay: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // }


    const onSubmit =async () => {
        var data = {
            profilePicture : image
        }
        console.log("_errors_errors", image)
        var formData = new FormData();
        formData.append("profilePicture", image)
        var resp = await profilePicture(formData);
        if (resp.status == 500) {
            toast.error("Error !Try Again...", {
              style: {
                minWidth: '300px',
                minHeight: '55px'
              }
            })
          }else {
            toast.success(`${resp.data.data.message}`, {
              style: {
                minWidth: '300px',
                minHeight: '55px'
              }
            })
           
            history('/uploadimage')
  
          }


    }
    
    const deleteByValue = value => {
        // alert(2)
        set_image("") 
    }

    const ImageUpload = async (event) => {
        // console.log("event.target.files[0].type", event.target.files[0],event.target.files[0].type !== "image/png" ,event.target.files[0].type !== "image/jpg" , event.target.files[0].type !== "image/jpeg")
        // var img = new Image()
        // img.src = window?.URL?.createObjectURL(event?.target?.files[0])
        console.log("event?.target?.files[0].type : ", event?.target?.files[0].type)
        if (event?.target?.files[0].type !== "image/png" && event?.target?.files[0].type !== "image/jpg" && event?.target?.files[0].type !== "image/jpeg") {
            return toast.error("Only jpg, png, jpeg image formats are supported", {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            })
        } else {
            console.log(event.target, "seet")
            const reader = new FileReader();
            reader.addEventListener('load', () => set_image(reader.result));
            reader.readAsDataURL(event.target.files[0]);
            slidechange(100)


        }
    };

    return (
        <>
            <div>
                <div className='signup'>
                    <div className='container'>
                        <div className='row mh-100'>
                            <div className='col-md-5 col-lg-6'>
                            <LeftCarousel />
                                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                                    <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                                </div>
                                <div className='carouselnone'>
                                    <div className='position-relative pb-5'>
                                        <img className='imagecloud' src={Images.whitecloud} />
                                    </div>
                                    <div className='mt-4'>
                                        <OwlCarousel className='owl-theme' {...options1}>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>decode
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                    <div className='position-relative pt-5'>
                                        <img className='imagecloud1' src={Images.whitecloud} />
                                    </div>
                                </div> */}
                            </div>
                            <div className='col-md-7 col-lg-6'>
                                <div className='signupcard'>
                                    <h4 className='connectheading text-center'>Pick your Profile picture</h4>
                                    <p className='subheading text-center mb-4'></p>
                                    <img className='leftgraycloud' src={Images.gallery} />
                                    <img className='graycloud' src={Images.graycloud} />
                                    <img className='imagecloudsetup' src={Images.cloud} />

                                    <div className='uploadpad'>
                                        <img className='imagecloudsetup1' src={Images.darkgraycloud} />
                                        <div className='borderedinputbox text-xl-center text-sm-start text-center  mb-4 py-3'>
                                            <div className='d-flex jc-between align-items-center flex-xl-column flex-column'>
                                                <div className='d-flex align-items-center gap-2 flex-xl-column  flex-column'>
                                                    {/* <div className='browseimg'><img className='img' src={Images.browseprofile} />
                                                </div> */}
                                                    {/* <div className='my-4'>
                                                    <h5 className='uload_log_size'>Upload Logo</h5>
                                                    <p className='filename'>Logo.png</p>
                                                </div> */}

                                                    <div className='browseinput'>

                                                       <div>
                                                        {console.log('filename', file)}
                                                        <div className='img_upload_pos_div'>
                                                        {image ? <img src={image} className='img-fluid' /> : <> <img src={Images.browseprofile} className='img-fluid' /> </>}
                                                        <input type='file' onChange={ImageUpload}  />
                                                        </div>
                                                        {/* uploadimg */}
                                                        <div className='mb-4 mt-3'>
                                                            <h5 className='uload_log_size'>Upload Image</h5>
                                                            {/* <p className='filename'>Logo.png</p> */}
                                                        </div>
                                                        {/* <button className='btn themebtn'> Upload Image</button> */}
                                                        <div className=''>
            
                                                            <button className='btn btn-primary browesfile bw position-relatives-input'> 
                                                            <input type='file' onChange={ImageUpload}  />
                                                            Browse File</button>
                                                            <img className='browesfileicon' src={Images.browsw_icon} />
                                                        </div>
                                                        </div>
                                                        <div className="loaderbox profilee">
                                                            <div>
                                                                <div className="reimg">
                                                                    <img src={Images.galleryimg} alt="" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="slidecount">

                                                                    <Slider
                                                                    disabled={true}
                                                                        min={0}
                                                                        max={100}
                                                                        value={slide.value}
                                                                        onChange={slidechange}
                                                                        tooltip={false}
                                                                    />
                                                                    <span><span className="percent percent1">Image.jpg</span> <span className="percent">{slide.value}%</span></span>
                                                                </div>
                                                            </div>
                                                            <div >
                                                                <div className="reimg imgtwo cur_pointer_div" onClick={()=>{deleteByValue()}}>
                                                                    <img src={Images.delete} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='bordbt bordbt2'></div>

                                    <div className='allimages'>
                                        <img className='commenticon' src={Images.all} />
                                        <img className='youtubeicon' src={Images.youtube} />
                                        {/* <div className='pt-4 pb-4 text-center'>
                                        <Link to="/uploadimage" className=" position-relative">
                                            <Button variant="primary" className='signupwallet signupnext'>
                                                Next</Button>
                                            <img className='nexticon nexticon1' src={Images.nect} />
                                            </Link>
                                        </div> */}
                                        <div className='pt-4 pb-4 text-center mx-5'>
                                            {isEmpty(image) ?  <Link to= "/welcome" className=' position-relative' > 
                                            <Button variant="primary" className='signupwallet' >
                                                Next
                                            </Button>
                                            <img className='signupicon' src={Images.nect} />
                                            </Link>: <Link to= "/uploadimage" state={{profilePicture : image }} className=' position-relative' > 
                                            <Button variant="primary" className='signupwallet' >
                                                Next
                                            </Button>
                                            <img className='signupicon' src={Images.nect} />
                                            </Link>}
                                       
                                            {/* <Redirect to={{
                                                pathname: '/order',
                                                state: { id: '123' }
                                            }} /> */}
                                        {/* <Link  onClick={onSubmit} className=' position-relative'>
                                            <Button variant="primary" className='signupwallet'>
                                                Next</Button>
                                            <img className='signupicon' src={Images.nect} />
                                            </Link> */}
                                        </div>

                                    </div>

                                    <div className='pt-3'>
                                        <p className='text-center already'><span className='loginnow'>
                                        {isEmpty(image) ? 
                                         <Link to="/welcome"> Skip Now </Link> :
                                          <Link to="/uploadimage"> Skip Now </Link>}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Setprofilepicture;