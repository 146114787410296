import React, { useEffect, useState } from 'react';

import Header from '../Header';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Images from '../Images';
import Imagemodal from '../Modals/ImageModal';
import Videomodal from '../Modals/VideoModal';
import CreatePost from '../Modals/CreatePost';
import EditPost from '../Modals/EditPost';
import DeletePost from '../Modals/DeletePost';
import SettingModal from '../Modals/SettingModal';
import Bio from '../Modals/Biomodal';
import { Dropdown, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import Linksec from '../Rightsection.js/Linksec';
import PostLists from '../Common/PostLists';
import Description from '../Modals/Description';
import InviteGuest from '../Modals/InviteGuest';
import { toBeRequired } from '@testing-library/jest-dom/matchers';
// import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
const EditEvent1 = () =>{
    const[createevent, setCreateEvent] = useState(true)
    const [startDate, setStartDate] = useState(new Date());
  const[showbar, setShowBar] = useState(true)
  const[bio, setBio] = useState(false)
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    // if(window.innerWidth <= 1199){
    // if(showbar == false){
    //   document.getElementById("content").style.overflowY = "auto "
    // }else{
    //   document.getElementById("content").style.overflowY = " hidden"
    // }}
  },[showbar])

  const[imagemodal, setImagemodal] = useState(false)
  const[videomodal, setVideomodal] = useState(false)
  const[createpost, setCreatePost] = useState(false)
  const[editpost, setEditPost] = useState(false)
  const[deletepost, setDeletePost] = useState(false)
  const[setting, setSetting] = useState(false)

  const[rightside, setRightSide] = useState(true)

  const[description, setDescription] = useState(false)
  const[inviteguest, setInviteGuest] = useState(false)

  const[mobile, setMobile] = useState(false)
  const[desktop, setDesktop] = useState(true)

  const[drop, setDrop] = useState(false)


  const[settings , setSettings] = useState(false)

  useState(() =>{
    
    if(window.innerWidth <= 1200){
        setRightSide(false)
    }
  },[window.innerWidth])
return(
 <>
 {  description &&    <Description onDismiss={() => setDescription(false)}/>}
 {imagemodal && <Imagemodal onDismiss={() => setImagemodal(false)}/>}
 {videomodal && <Videomodal onDismiss={() => setVideomodal(false)}/>}
 {createpost && <CreatePost onDismiss={() => setCreatePost(false)}/>}
 {editpost && <EditPost onDismiss={() => setEditPost(false)}/>}
 {deletepost && <DeletePost onDismiss={() => setDeletePost(false)}/>}
 {setting && <SettingModal onDismiss={() => setSetting(false)}/>}
 {bio && <Bio onDismiss= {() => setBio(false)}/>}
 {inviteguest && <InviteGuest onDismiss={() => setInviteGuest(false)}/>}


 <Header />
<section id='editevent'>
<div className=' '>
  <div className='themewidth editeventsec'>
    <section className='posts profile mx-3'>
      
        <div className=''>
                    <div className='editevevnt'>
                    <div className='pb-3 pt-2 '>
                        <h6> Desktop Preview </h6>
                      
                    </div>
                    <div class="themebord p-0 mb-3"></div>
                    <div className='scrollsec p-sm-2'>
                
                  
            <div className='whitebox '>
            <div className='row m-1 evenflow'>
                <div className='col-sm-6 watchleft'>
                    <div className='eventbanner'>
                    <p className='f-12 mb-2'>Lorem ipsum is a dummy content</p>
                    <h6>Highlight | Pakistan vs srilanka | T20I PCB | MA2A</h6>
                    </div>
                </div>
                <div className='col-sm-6 watchright'>
                    <img src={Images.post1} className='img-fluid watchimg' />
                    <div>
                    <button className='btn'>Watch</button>
                    </div>
                </div>
            </div> 
                        
                <div className='d-sm-flex jc-between gap-2 p-sm-3 p-2  pb-2 profilestat'>
                    <div className='d-flex'>
                        <div className='d-sm-flex d-none'>
                        <div className=' br-5 me-2 profimg text'>
                            25
                        </div>
                        </div> 
                        <div>
                            <p className='d-flex mb-0'> <h5  className='mb-1'>David Marvel </h5></p>
                                    
                                    <p className='d-flex mb-0'>   <h6 className='mb-0 text-muted'>@david 
                                
                                    </h6></p>
                                <p className='f-12 text-muted mb-1' onClick={() => setBio(true)}>Lorem ipsum is a dummy content</p>
                                <div className='d-flex align-items-center'>
                                <div className='lineimg'>
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                    <img src={Images.prof} />
                                </div>
                                <span className='themeclr f-12 ms-5'>3.5k Friends</span></div>
                                </div>
                            
                            </div>
                            <div className='mt-sm-0 mt-2'>
                               
                               <Dropdown className="postdrop me-2 noafter d-inline-block">
                                           <Dropdown.Toggle variant="" className="morebtn inverse px-2 noarrow" id="dropdown-basic">
                                               Invite <span className="fas fa-chevron-down"></span>
                                           </Dropdown.Toggle>
                                           <Dropdown.Menu>
                                               <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                           </Dropdown.Menu>
                                       </Dropdown>
                                        <Link to="/editevent"><button className='morebtn inverse me-2'>Edit</button></Link>
                                   <Dropdown className="postdrop noafter d-inline-block">
                                           <Dropdown.Toggle variant="" className="morebtn px-2 noarrow" id="dropdown-basic">
                                                <span className="fas fa-ellipsis-h"></span>
                                           </Dropdown.Toggle>
                                           <Dropdown.Menu>
                                               <Dropdown.Item className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                           </Dropdown.Menu>
                                       </Dropdown>
                                   </div> 
                        </div>  
                            
                        </div>  
    <div className='btnflex'>
        <div className='col-xxxl-8'>
        <div className="whitebox mt-3">
            <div className="bordbtm px-sm-3 px-2 topsearch">
                <div className="pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start d-md-flex jc-between gap-5 align-items-center">
                    <h6 className="fw-500 nowrap my-3">Promote your event</h6>
                    <div className='d-flex w-full align-items-center gap-2 my-3'>
                    <div className='themeprogress active1'></div>
                    <span className='nowrap f-12'> 0 of 3 steps completed</span></div> 
                </div>
            </div>
            <div className='d-sm-flex jc-between flex-1 p-3'>
                <button className='btn modalbtn my-2 d-flex jc-between align-items-center me-2' onClick={() => setDescription(true)}> <i className='fa fa-pencil'></i> Add description <span className='fa fa-chevron-right'></span></button>
                <button className='btn modalbtn my-2 d-flex jc-between align-items-center me-2' onClick={() =>   setInviteGuest(true)}> <i className='fa fa-user-plus'></i> Invite guest <span className='fa fa-chevron-right'></span></button>
                <button className='btn modalbtn my-2 d-flex jc-between align-items-center me-2' onClick={() => setCreatePost(true)}> <i className='fa fa-edit'></i> Start discussion <span className='fa fa-chevron-right'></span></button>
            </div>
        </div> 
        </div>
        <div className='text-center'>
        <div className="whitebox mt-3">
            <div className="bordbtm px-sm-3 px-2 topsearch">
                <div className="pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start d-flex jc-between align-items-center">
                    <h6 className="fw-500 mb-0">Guest list</h6>
                <button className='morebtn'>See all</button>
                </div>
                
            </div>
            <div className='d-sm-flex jc-between flex-1 p-3'>
                <button className='btn modalbtn my-2 me-2'> 1 <br /> Going</button>
                <button className='btn modalbtn my-2 me-2'> 1 <br /> Liked</button>
                <button className='btn modalbtn my-2'> 1 <br /> Interested</button>
            </div>
        </div> 
        </div>
    </div>
    <div className="whitebox mt-3">
        <div className="bordbtm px-sm-3 px-2 topsearch pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start d-flex jc-between align-items-center">
           <p>Create post</p>
        </div>
        <div className="d-flex gap-2 p-sm-3 p-2  pb-2">
                <InputGroup className="graysearch">
                <Form.Control  onClick={() => setCreatePost(true)}
                    placeholder="What's happening ?"
                    aria-label="What's happening ?"
                    aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2">
                    <button className="linkbtn px-0">
                    
                    <img src={Images.paperclipblue} />
                    </button>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2">
                    <button className="linkbtn px-0">
                    <img src={Images.imageblue} />
                    </button>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2">
                    <button className="linkbtn px-0">
                    <img src={Images.smileyblue} />
                    </button>
                </InputGroup.Text>
                </InputGroup>
                <div>
                
                <button className="themebtn small blue">
                    <img src={Images.shareblue} />
                </button>
                </div>
        </div>
    </div>      
                 
                        <PostLists />   
                
        
                </div>
                </div>
        </div>   
    </section>
   {rightside ? "" : <button className='showright themebtn' onClick={() => setRightSide(true)}><span className='fa fa-bars'></span></button>} 
<div className= {rightside ? 'eventsidebar ty' : 'eventsidebar d-none ty'}>

     <div className='p-3'>
        <div className='d-flex jc-between my-3'>
            <p className='f-12 mb-0'>Event &gt; <Link to="/" className='blue'>Edit Event</Link> </p>
            <button className="fa fa-times eventclose" onClick={() => setRightSide(!rightside)}></button>
        </div>
        <div class="themebord p-0 mb-3"></div>
        {/* <div className='settingmodal step5'>
            <h6>Additional details</h6>
            <p className='f-12 text-secondary'>Choose a way for people to join your event online</p>
            <div class="form-floating grayinput mb-1">
                <input type="text" class="form-control bordered pe-4 f-12" id="email" value="Loram ipsum is a dummy content" name="email" />
                <label for="email">Description</label>
            </div> 
            <div className='eventphoto mt-3'>
            <h6>Cover Photo</h6>
                <img src={Images.post1} className='img-fluid' />
                <div className='d-grid'>
                    <img src={Images.post1} />
                    <img src={Images.post1} />
                    <img src={Images.post1} />
                    <img src={Images.post1} />
                    <img src={Images.post1} />

                </div>
            </div>
            <div className={settings ? 'whitebox p-3 mt-4' : ' mt-4'}>
                <button className='btn btn-link nodecore text-dark ps-0' onClick={() => setSettings(!settings)}><span className='fa fa-cog circle'></span> Settings</button>
               {settings && <>
                <div class="themebord p-0 my-3"></div>
                <div class="form-floating grayinput mb-1">
                <input type="text" class="form-control bordered pe-4 f-12" id="email" value="Add friends" name="email" />
                <label for="email">Co-host</label>  </div>
               
          
            <h6 className='mt-3'>Options</h6>
            <div className='d-flex jc-between my-2'> 
            <span>Show guest lists</span>
            <label className="switch">
                <input type="checkbox" role="switch" onChange={(e) => { console.log(e.target.checked); setChecked(e.target.checked) }} />
                <span className="slider round"></span>
            </label>
           </div>
 </>
               }

            </div>
        </div>
        <div className='settingmodal step4'>
            <h6>Description</h6>
            <p className='f-12 text-secondary'>Choose a way for people to join your event online</p>
           
           
            <div class="form-floating grayinput mb-1">
                <input type="text" class="form-control bordered pe-4 f-12" id="email" value="Loram ipsum is a dummy content" name="email" />
                <label for="email">Description</label>
            </div> </div>
        <div className='settingmodal step3'>
            <h6>Location</h6>
            <p className='f-12 text-secondary'>Choose a way for people to join your event online</p>
           
           
            <div class="form-floating grayinput mb-1">
                <input type="text" class="form-control bordered pe-4" id="email" value="London" name="email" />
                <label for="email">Email</label>
                <span className='fa fa-crosshairs'></span>
            </div>
            <p className='text-secondary f-12'><span className='fa fa-globe'></span> UTC + 01</p>
            <button className='btn themebtn'> <span className='fa fa-power-off'></span> switch to person</button>
            <div class="themebord p-0 my-3"></div>
            <h6>Add health and safty requirements</h6>
            <p className='f-12 text-secondary'>Lorem ipsum is a dummy content. Choose a way for people to join your event online</p>
           
           <div className='d-flex jc-between my-2'> 
            <span>Outdoor</span>
            <label className="switch">
                <input type="checkbox" role="switch" onChange={(e) => { console.log(e.target.checked); setChecked(e.target.checked) }} />
                <span className="slider round"></span>
            </label>
           </div>
           <div className='d-flex jc-between my-2'> 
            <span>Mask</span>
            <label className="switch">
                <input type="checkbox" role="switch" onChange={(e) => { console.log(e.target.checked); setChecked(e.target.checked) }} />
                <span className="slider round"></span>
            </label>
           </div>
          
           <input type="text" className='form-control themeinput h-42 bordered mt-4 f-12' placeholder='Other requirement'/>
        </div> */}
        <div className='settingmodal step2'>
            <h6>Location</h6>
            <p className='f-12 text-secondary'>Choose a way for people to join your event online</p>
            <ul>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fa fa-video'></span>
                        <div>
                            <h6 className='mb-0 '>Live</h6>
                            <p className='mb-0 f-12'>Only for friends</p>
                        </div>
                    </div> 
                    <div>
                         <label class="custcheck">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fa fa-link'></span>
                        <div>
                            <h6 className='mb-0 '>External link</h6>
                            <p className='mb-0 f-12'>Only for friends</p>
                        </div>
                    </div> 
                    <div>
                         <label class="custcheck">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </li>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                        <span className='fa fa-comment'></span>
                        <div>
                            <h6 className='mb-0 '>Others</h6>
                            <p className='mb-0 f-12'>Only for friends</p>
                        </div>
                    </div> 
                    <div>
                         <label class="custcheck">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </li>
            </ul>
            <button className='btn themebtn'> <span className='fa fa-power-off'></span> switch to person</button>
        </div>

   
    </div>
    <div className='bottomprogress py-3 px-3'>
        <div className='d-flex flex-1 gap-2 progressbars'>
            <div className='active'></div>
            <div className='active'></div>
            <div></div>
            <div></div>
        </div>
        <div className='d-flex flex-1 gap-2 my-3'>
        <Link to="/editevent"> <button className='morebtn w-full'>Back</button></Link>
            <Link to="/editevent2">   <button className='morebtn inverse w-full'>Next</button></Link>
        </div>
    </div>
  </div>
  </div>
  </div>
 
</section>
</> 
)
}


export default EditEvent1
