import React, { useState } from 'react';
import Images from '../Images';

const Hashtag = () =>{
    return(
        <div id="hashtag">
        <div className='d-flex jc-between align-items-end p-3'>
            <p className='subhead'>Trending hashtags</p>
            <div> 
                <button className='morebtn'>More</button>
            </div>
       </div>
       <div className='themebord p-0'></div>
       <ul className='p-3 text-start mb-0'>
       <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story6} className='img-fluid user me-lg-2' />
                <div className=''>
                    <h6 className='smallhead '>Jhon</h6>
                    <p className='f9txt mb-0'>Trending with james</p>
                </div>
            </div>
            <button className='morebtn small'><span className='fas fa-ellipsis-h'></span></button>
          </li>
          <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story7} className='img-fluid user me-lg-2' />
                <div className=''>
                    <h6 className='smallhead '>Jessi</h6>
                    <p className='f9txt mb-0'>Trending with james</p>
                </div>
            </div>
             <button className='morebtn small'><span className='fas fa-ellipsis-h'></span></button>
          </li>
          <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story8} className='img-fluid user me-lg-2' />
                <div className=''>
                    <h6 className='smallhead '>Greg</h6>
                    <p className='f9txt mb-0'>Trending with james</p>
                </div>
            </div>
             <button className='morebtn small'><span className='fas fa-ellipsis-h'></span></button>
          </li>
       </ul>
    </div>
    )
}

export default Hashtag