import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Header from '../Header';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Images from '../Images';
import Linksec from '../Rightsection.js/Linksec';

const ViewFriend = () =>{
    const[showbar, setShowBar] = useState(true)
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])
    return(
        <>
         <Header />
        <section id="home" className='friends'>
        <button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
        <div className='themecontainer'>
  <div className='themewidth'>
     <LeftSection />
     <div id="content" className=' w-full d-flex'>
     <section id='centersection' className='posts'>
     <div className='showin1199 showrightsec'><Linksec/></div>  
     <div className='postlists request'> 
        <div className='whitebox p-sm-4'>
        <div className='d-flex jc-between align-items-center bordbtm mb-3 pb-3 px-sm-0 px-3 pt-sm-0 pt-3'>
                <h5 className='mb-0'>View friends </h5>
              <div className='shrink-0'>
              <Link to="/allfriends"> <button className='morebtn inverse me-2'>View all</button></Link>
              </div>
            </div>
           
            <ul>
            <li className='d-flex jc-between align-items-center gap-2'>
            <div className='d-flex w-full'>
                        <img src={Images.story4} className='friendimg'/>
                        <div className='w-full'>
                            <h6 className='mb-sm-0 mb-2'><Link to="/friendprof" className='nodecore text-dark'> David</Link></h6>
                          <div className=' w-full showin575 shrink-0'> 
                          <button className='morebtn inverse me-2 '>Add Friend</button>
                            <button className='morebtn' >Message</button></div>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                    </div>
                    <div className='rightbtn shrink-0'>
                        <button className='morebtn inverse me-2 '>Add Friend</button>
                        <button className='morebtn ' >Message</button>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center gap-2'>
            <div className='d-flex w-full'>
                        <img src={Images.story5} className='friendimg'/>
                        <div className='w-full'>
                        <h6 className='mb-sm-0 mb-2'><Link to="/friendprof" className='nodecore text-dark'> David</Link></h6>
                          <div className=' w-full showin575 shrink-0'> 
                          <button className='morebtn inverse me-2 '>Add Friend</button>
                            <button className='morebtn' >Message</button></div>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                    </div>
                    <div className='rightbtn shrink-0'>
                        <button className='morebtn inverse me-2 '>Add Friend</button>
                        <button className='morebtn ' >Message</button>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center gap-2'>
            <div className='d-flex w-full'>
                        <img src={Images.story6} className='friendimg'/>
                        <div className='w-full'>
                        <h6 className='mb-sm-0 mb-2'><Link to="/friendprof" className='nodecore text-dark'> David</Link></h6>
                          <div className=' w-full showin575 shrink-0'> 
                          <button className='morebtn inverse me-2 '>Add Friend</button>
                            <button className='morebtn' >Message</button></div>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                    </div>
                    <div className='rightbtn shrink-0'>
                        <button className='morebtn inverse me-2 '>Add Friend</button>
                        <button className='morebtn ' >Message</button>
                    </div>
                </li>
            </ul>
        </div>

        <div className='whitebox p-sm-4 mt-3'>
        <div className='d-flex jc-between align-items-center bordbtm mb-3 pb-3 px-sm-0 px-3 pt-sm-0 pt-3'>
                <h5 className='mb-0'>Suggestion Request</h5>
              <div className='shrink-0'>
              <Link to="/allfriends"> <button className='morebtn inverse me-2'>View all</button></Link>
              </div>
            </div>
            <ul>

            <li className='d-flex jc-between align-items-center gap-2'>
            <div className='d-flex w-full'>
                        <img src={Images.story1} className='friendimg'/>
                        <div className='w-full'>
                        <h6 className='mb-sm-0 mb-2'><Link to="/friendprof" className='nodecore text-dark'> David</Link></h6>
                          <div className=' w-full showin575 shrink-0'> 
                          <button className='morebtn inverse me-2 '>Add Friend</button>
                            <button className='morebtn' >Message</button></div>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                    </div>
                    <div className='rightbtn shrink-0'>
                        <button className='morebtn inverse me-2 '>Add Friend</button>
                        <button className='morebtn ' >Message</button>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center gap-2'>
            <div className='d-flex w-full'>
                        <img src={Images.story2} className='friendimg'/>
                        <div className='w-full'>
                        <h6 className='mb-sm-0 mb-2'><Link to="/friendprof" className='nodecore text-dark'> David</Link></h6>
                          <div className=' w-full showin575 shrink-0'> 
                          <button className='morebtn inverse me-2 '>Add Friend</button>
                            <button className='morebtn' >Message</button></div>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                    </div>
                    <div className='rightbtn shrink-0'>
                        <button className='morebtn inverse me-2 '>Add Friend</button>
                        <button className='morebtn ' >Message</button>
                    </div>
                </li>
                <li className='d-flex jc-between align-items-center gap-2'>
            <div className='d-flex w-full'>
                        <img src={Images.story3} className='friendimg'/>
                        <div className='w-full'>
                        <h6 className='mb-sm-0 mb-2'><Link to="/friendprof" className='nodecore text-dark'> David</Link></h6>
                          <div className=' w-full showin575 shrink-0'> 
                          <button className='morebtn inverse me-2 '>Add Friend</button>
                            <button className='morebtn' >Message</button></div>
                        <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                        <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                    </div>
                    <div className='rightbtn shrink-0'>
                        <button className='morebtn inverse me-2 '>Add Friend</button>
                        <button className='morebtn ' >Message</button>
                    </div>
                </li>

              
            </ul>
        </div>
     </div>
     </section>
        
      {showbar && <RightSection />}
      
     </div>
  </div>
  </div>
        </section>
        </>
    )
}

export default ViewFriend