import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Images from './Images';

const Community = () =>{
    return(
        <>
            <p>No Records</p>
                     {/* <ul className='hoverlists'>
                                <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                <div className='d-flex w-full align-items-center'>
                                            <img src={Images.story3} className='friendimg'/>
                                            <div className='w-full'>
                                           <Link to="/friendprof" className='nostyle text-dark'>
                                           <h6 className='mb-sm-0 mb-2'>David</h6></Link>
                                           <div className='showin575 shrink-0'>
                                                <button className='morebtn inverse me-2'>Add Friend</button>
                                                <button className='morebtn'>Cancel</button>
                                            </div>
                                            <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                                        </div>
                                        <div  className='rightbtn shrink-0'>
                                            <button className='morebtn inverse me-2'>Add Friend</button>
                                            <button className='morebtn'>Cancel</button>
                                        </div>
                                    </li>
                                    <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                <div className='d-flex w-full align-items-center'>
                                            <img src={Images.story4} className='friendimg'/>
                                            <div className='w-full'>
                                           <Link to="/friendprof" className='nostyle text-dark'>
                                           <h6 className='mb-sm-0 mb-2'>David</h6></Link>
                                           <div className='showin575 shrink-0'>
                                                <button className='morebtn inverse me-2'>Add Friend</button>
                                                <button className='morebtn'>Cancel</button>
                                            </div>
                                            <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                                        </div>
                                        <div  className='rightbtn shrink-0'>
                                            <button className='morebtn inverse me-2'>Add Friend</button>
                                            <button className='morebtn'>Cancel</button>
                                        </div>
                                    </li>
                                    <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                <div className='d-flex w-full align-items-center'>
                                            <img src={Images.story6} className='friendimg'/>
                                            <div className='w-full'>
                                           <Link to="/friendprof" className='nostyle text-dark'>
                                           <h6 className='mb-sm-0 mb-2'>David</h6></Link>
                                           <div className='showin575 shrink-0'>
                                                <button className='morebtn inverse me-2'>Add Friend</button>
                                                <button className='morebtn'>Cancel</button>
                                            </div>
                                            <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            <h6 className='mb-0 f-12 resultcont'>25 friends</h6></div>
                                        </div>
                                        <div  className='rightbtn shrink-0'>
                                            <button className='morebtn inverse me-2'>Add Friend</button>
                                            <button className='morebtn'>Cancel</button>
                                        </div>
                                    </li>
                                </ul> */}
                           
        </>
    )
}

export default Community