import axios from "axios";
axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("token",token)
    } 
};
export default axios;
// else {
//     delete axios.defaults.headers.common["Authorization"];
// }