import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Images from './Images';
import { Form, InputGroup, Tab, Tabs, Button } from 'react-bootstrap';
import { BsGoogle, BsApple } from 'react-icons/bs';
// import OwlCarousel from "react-owl-carousel-rtl";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaRegCopy } from 'react-icons/fa';
import LeftCarousel from './LeftCarousel';
import { register } from '../Actions/register'
import { ValidateInputDatas } from "./Validations/Validations"
import toast, { Toaster } from 'react-hot-toast';
import generator from "generate-password";
import copy from "copy-to-clipboard";
import isEmpty from 'is-empty';
import {setAuthToken} from '../utils/setAuthtoken';
import { useSelector, useDispatch } from 'react-redux'

const Setuppassword = () => {

    const [checked, setChecked] = useState(false)
    const [password, set_password] = useState("")
    const [confirmPass, set_confirmPass] = useState("")
    const [error, set_error] = useState("");
    const [genPass, set_genPass] = useState("");
    const [copyText, setCopyText] = useState('');
    const [showpass, set_showpass] = useState(false)
    const [confirmpass, set_pass] = useState(false)
    const[address,set_address] = useState('')
    const dispatch = useDispatch();
  
    const history = useNavigate()
    // const options1 = {
    //     loop: true,
    //     margin: 20,
    //     nav: true,
    //     dots: false,
    //     smartSpeed: 1200,
    //     navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
    //     autoplay: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // }


    const onChange = (e) => {
        console.log("event", e)
        if (e.target.id == 'password')
            set_password(e.target.value)
        if (e.target.id == 'confirmPass')
            set_confirmPass(e.target.value)
        if (e.target.id == 'copy')
            setCopyText(e.target.value);

    };
    const generatePassword = () => {
        const pwd = generator.generate({
            length: 24,
            lowercase: true,
            uppercase: true,
            numbers: true,
            symbols: true
        });
        set_genPass(pwd);
    }



    const copyToClipboard = () => {
        copy(genPass);
        set_password(genPass)
        set_confirmPass(genPass)
        toast.success("Password Copied Successfully", {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })

    }

    useEffect(() => {
        generatePassword()
    }, [])

    const onSubmit = async (e) => {
        console.log("Data")
        try {
            var data = {
                password: password,
                confirmPass: confirmPass
            }
            const { errors, isValid } = await ValidateInputDatas(data, "password")
            set_error(errors)
            console.log("_errors_errors", errors, isValid)
            if (Object.keys(errors).length == 0 && isValid === true) {
                var passdata = {
                    EmailId: localStorage.getItem("Email"),
                    password: password,
                    walletAddress: address

                }
                var resp = await register(passdata,dispatch)
                console.log("register", resp)
            }
            toast.success(`${resp?.data?.data?.message}`, {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            })
            setAuthToken(resp?.data?.data?.token)
            if(sessionStorage.getItem("accountInfo")){
                // history('/profile')
                history('/setprofilepicture')
            }
            else{
                history('/captchalogin')
            }
            
            if (resp?.status == 500) {
                toast.error("Error !Try Again...", {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
            }
        }
        catch (err) {
            console.log("register_err", err)
        }

    };
    useEffect(() => {
        console.log("HJHJHJHJHJHJHJ",sessionStorage.getItem("accountInfo")); 
        if(sessionStorage.getItem("accountInfo") !== null){
         set_address(sessionStorage.getItem("accountInfo"))
        }
    
     }, [])

    const copyAddress = () => {
     copy(address);
     toast.success("Address Copied Successfully", {
         style: {
             minWidth: '300px',
             minHeight: '55px'
         }
     })
 }


    return (
        <>
            <div>
                <div className='signup'>
                    <div className='container'>
                        <div className='row mh-100'>
                            <div className='col-md-5 col-lg-6'>
                                <LeftCarousel />
                                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                                    <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                                </div>
                                <div className='carouselnone'>
                                    <div className='position-relative pb-5'>
                                        <img className='imagecloud' src={Images.whitecloud} />
                                    </div>
                                    <div className='mt-4'>
                                        <OwlCarousel className='owl-theme' {...options1}>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                            <div class='item'>
                                                <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                                <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                    <div className='position-relative pt-5'>
                                        <img className='imagecloud1' src={Images.whitecloud} />
                                    </div>
                                </div> */}
                            </div>
                            <div className='col-md-7 col-lg-6'>
                                <div className='signupcard'>
                                    <h4 className='connectheading text-center'>Setup your Account</h4>
                                    <p className='subheading text-center mb-4'></p>
                                    <img className='leftgraycloud' src={Images.gallery} />
                                    <img className='graycloud' src={Images.graycloud} />
                                    <img className='imagecloudsetup' src={Images.cloud} />
                                    <div className='row justify-content-center mt-2'>
                                    {(sessionStorage.getItem("accountInfo") !== null) ? <div className='row justify-content-center mt-2'>
                                        <div className='col-lg-8 col-md-10 col-sm-9 col-12'>
                                            <div className='task mt-2'>
                                                <div className='d-flex align-items-center justify-content-center '>
                                                    <img className='metaimg' src={Images.meta} />
                                                    <h6 className='name mb-0'>Metamask</h6>
                                                </div>
                                                {address !== '' &&
                                                    <div className='tokens mt-3'>

                                                        <p>{<>{(address).slice(0, 7)}...{(address).slice((address).length - 9, (address).length - 1)}<FaRegCopy className='ms-2' onClick={copyToClipboard} /></>}</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
 
                                    </div>:
                                    <div className='align-items-center justify-content-center text-center'>
                                    <Link to="/connectwallet" className='position-relative'>
                                            <Button variant="primary" className='signupwallet'>
                                                Connect Wallet</Button>
                                            <img className='signupicon' src={Images.wallet1} />
                                            </Link>
                                            </div>}

                                    </div>
                                    <div className='bordbt bordbt1'></div>

                                    <div className='setupfields position-relative'>
                                        <img className='darkgraycloudsetup' src={Images.darkgraycloud} />
                                        <p className='detected mt-3 mb-3 text-center'>No ENS Address Detected</p>
                                        <div className='row justify-content-center mt-2'>
                                            <div className='col-lg-8 col-md-10 col-sm-9 col-12'>
                                                <div className='task task1 mt-2'>
                                                    <div className="d-flex ty justify-content-end">
                                                        <div className={checked ? 'switch_div d-flex switch_div_length switch_div_1 active_check' : 'switch_div d-flex switch_div_length switch_div_1'}>
                                                            <label className="switch">
                                                                <input type="checkbox" role="switch" onChange={(e) => { console.log(e.target.checked); setChecked(e.target.checked) }} disabled={!isEmpty(password)?true:false}/>
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex align-items-center justify-content-center mt-2 mb-3'>
                                                        <img className='metaimg1' src={Images.passwords} />
                                                        <h6 className='name mb-0'>Password</h6>
                                                    </div>

                                                    {!checked ?
                                                        <div>
                                                            <InputGroup className="mt-2 mb-0">
                                                                <Form.Control
                                                                    placeholder="OX865839nbg2421EIUKkjedg65839"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                    value={genPass}

                                                                />
                                                                <InputGroup.Text id="basic-addon2"><FaRegCopy onClick={copyToClipboard} /></InputGroup.Text>
                                                            </InputGroup>
                                                        </div> :
                                                        <div>
                                                            <Form>
                                                                <div>
                                                                    <InputGroup className="mb-2">
                                                                        <Form.Control
                                                                            type={showpass ? "text" : "password"} placeholder="Password" id="password" onChange={(e) => { onChange(e) }}
                                                                        />
                                                                        <InputGroup.Text id="basic-addon2"> {showpass ?
                                                                            <span className="fa fa-eye text-secondary f-14" onClick={() => { set_showpass(!showpass) }}></span> :
                                                                            <span className="fa fa-eye-slash text-secondary f-14" onClick={() => { set_showpass(!showpass) }}></span>
                                                                        }</InputGroup.Text>

                                                                    </InputGroup>
                                                                    <span className="text-danger  mb-3 d-block text-left">{error.password}</span>
                                                                </div>
                                                                <div>
                                                                    <InputGroup className="mb-2">
                                                                        <Form.Control
                                                                            type={confirmpass ? "text" : "password"} placeholder="Confirm Password" id="confirmPass" onChange={(e) => { onChange(e) }}
                                                                        />
                                                                        <InputGroup.Text id="basic-addon2"> {confirmpass ?
                                                                            <span className="fa fa-eye text-secondary f-14" onClick={() => { set_pass(!confirmpass) }}></span> :
                                                                            <span className="fa fa-eye-slash text-secondary f-14" onClick={() => { set_pass(!confirmpass) }}></span>
                                                                        }</InputGroup.Text>

                                                                    </InputGroup>
                                                                    <span className="text-danger  mb-3 d-block text-left">{error.confirmPass}</span>
                                                                </div>
                                                                {/* <Form.Group className="mb-2 mt-2">
                                                                    <Form.Control type="password" placeholder="Password" id="password" onChange={(e) => { onChange(e) }} />
                                                                    <span className="text-danger  mb-3 d-block text-left">{error.password}</span>
                                                                </Form.Group> */}
                                                                {/* <Form.Group className="mb-2" >
                                                                    <Form.Control type="password" placeholder="Confirm Password" id="confirmPass" onChange={(e) => { onChange(e) }} />
                                                                    <span className="text-danger  mb-3 d-block text-left">{error.confirmPass}</span>
                                                                </Form.Group> */}

                                                            </Form>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                        <p className='condition mt-3 mb-2'>Lorem Ipsum is simply dummy text of the <span className='loginnow'><Link to="/">Terms and Conditions</Link></span> printing</p>
                                    </div>

                                    <div className='allimages'>
                                        <img className='commenticon' src={Images.all} />
                                        <img className='youtubeicon' src={Images.youtube} />
                                        <div className='pt-4 pb-4 text-center'>
                                            <Link className=' position-relative' onClick={onSubmit}>
                                            <Button variant="primary" className='signupwallet'>
                                                Proceed</Button>
                                            <img className='signupicon' src={Images.wallet1} />
                                            </Link>
                                        </div>

                                    </div>

                                    <div className='pt-3'>
                                        <p className='text-center already'><span>Already a Lander?</span> <span className='loginnow'><Link to="/login"> Login Now </Link></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Setuppassword;