import React, { useState } from 'react';
import { Dropdown, InputGroup, Modal, Form, Tab, Tabs } from 'react-bootstrap';
import Images from '../Images';

const FollowModal = (props) =>{
    const[followmodal, setFollowmodal] = useState(true)
    return(
        <>
         <Modal show={followmodal} className='thememodal settingmodal' centered>
        <Modal.Header className='jc-between pb-0'>
            <div className='pb-3'>
          <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center profile_follow_sec'>
            <img src={Images.prof} className='img' />
            <div className='ps-2'>
                <p className='profile_name_folow'>Merlin95</p>
                <p className='profile_desc_folow'>20 Followers 24 Following</p>
            </div>

                </div>
            </div>
          {/* <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p> */}
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body className='p-4'>
        <InputGroup className=" themesearch">
                <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span></InputGroup.Text>
                <Form.Control placeholder="Search" className='nofocus'
                    aria-label="What's happening ?"
                    aria-describedby="basic-addon2" />
                </InputGroup>
            {/* <img src={Images.graycloud} className='cloud img' /> */}
            <img src={Images.gallery} className='gallery img' />
            {/* <img src={Images.youtube} className='youtube img'/> */}
            {/* <img src={Images.all} className='all img'/> */}
            <div className='mt-3'>

            <Tabs defaultActiveKey="followers" id="uncontrolled-tab-example" className="my-2 themetab tab_modal" >
                  
                  <Tab eventKey="followers" title="Followers"> 
        
                <div className='mt-4'>  
            <ul className='follow_sec_height'>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.followprof} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Follow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo1} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Follow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo2} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Follow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo3} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Follow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.followprof} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Follow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo1} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Follow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo2} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Follow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo3} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Follow</button>
                    </div>
                </li>

                
              
            </ul>
            </div>
                      </Tab>
                  <Tab eventKey="followings" title="Following">
                  
                <div className='mt-4'>  
            <ul className='follow_sec_height'>
                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.followprof} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Unfollow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo1} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Unfollow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo2} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Unfollow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo3} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Unfollow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.followprof} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Unfollow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo1} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Unfollow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo2} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Unfollow</button>
                    </div>
                </li>

                <li className='d-flex align-items-center jc-between mb-4'>
                    <div className='d-flex align-items-center'>  
                    <div className='follow_img'>
                        <img src={Images.photo3} className='img-fluid folow_img_round' />
                    </div>
                        <div className='ms-2'>
                            <h6 className='mb-0 '>Bitcoinworld</h6>
                            <p className='mb-0 f-12'>
                                <span>3.1k Followers</span>
                                <span className='ms-2'>2k Following</span>
                            </p>
                        </div>
                    </div> 
                    <div>
                       <button className='themebtn ms-2'>Unfollow</button>
                    </div>
                </li>

                
              
            </ul>
            </div>
                  </Tab>
             
               
              </Tabs>
              </div>

         
        
   
        </Modal.Body>
       
       
      
      </Modal>
        </>
    )
}

export default FollowModal