import React, { useEffect, useState } from 'react';
import Posts from './Posts';
import LeftSection from './leftsection/LeftSection';
import RightSection from './Rightsection.js/RightSection';
import Images from './Images';
import Header from './Header';
import EditProfile from './EditProfile';


const Profile = () =>{
 
  
  const[showbar, setShowBar] = useState(true)
  const[showdrop, setShowDrop] =useState(false)


  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])
return(
 <>
 <Header />
<section id='home'>
<button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
  <div className='themecontainer'>
  <div className='themewidth'>
     <LeftSection />
     <div id="content" className=' w-full d-flex'>
     
      <EditProfile />
      {showbar && <RightSection />}
      
     </div>
  </div>
  </div>
 
</section>
</> 
)
}


export default Profile
