import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { EthereumProvider } from '@walletconnect/ethereum-provider'

import { CURRENT_CHAIN_ID, CURRENT_RPC_URL } from "./useWeb3";
import toast, { Toaster } from 'react-hot-toast';
import { PROJECT_ID } from "../config/config";


export const MetamaskWallet = async (onSet) => {
    console.log("Metamaks", onSet, window.ethereum)
    try {
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum);


            try {


                await window.ethereum.enable().then(async () => {

                    const accounts = await web3.eth.getAccounts();
                    const account = accounts[0].toString();

                   sessionStorage.setItem("accountInfo", account);
                   sessionStorage.setItem("wallet","metamask")
                   if(account){
                    console.log(account,'account')
                    onSet(account)
                   }
                    return web3;
                });
            } catch (e) {
                console.log("Metamaks : ",e)
            }
        }
        // Legacy DApp Browsers
        else if (window.web3) {
            const web3 = new Web3(window.web3.currentProvider);
            const chainId = await web3.eth.getChainId();
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString();
            sessionStorage.setItem("accountInfo", account)
            return web3;
        }
        // Non-DApp Browsers
        else {
            //alert('No Dapp Supported Wallet Found');

            toast.error(`No Dapp Supported Wallet Found !`,

                {
                    duration: 2000,
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        }
    } catch (e) {
        toast.error(`Error : ${e}`,
            {
                duration: 2000,
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            });
    }


}

// export const BinanceWallet = async (e) => {


//     try {
//         if (window.BinanceChain) {
//             const web3 = new Web3(window.BinanceChain);


//             try {


//                 await window.BinanceChain.enable().then(async () => {

//                     const accounts = await web3.eth.getAccounts();
//                     const account = accounts[0].toString();

//                     sessionStorage.setItem("accountInfo", account)

//                     return web3;
//                 });
//             } catch (e) {

//                 // User has denied account access to DApp...
//             }
//         }
//         // Legacy DApp Browsers
//         else if (window.web3) {

//             const web3 = new Web3(window.web3.currentProvider);
//             const chainId = await web3.eth.getChainId();

//             const accounts = await web3.eth.getAccounts();
//             const account = accounts[0].toString();
//             sessionStorage.setItem("accountInfo", account)
//             return web3;
//         }
//         // Non-DApp Browsers
//         else {
//             //alert('No Dapp Supported Wallet Found');

//             toast.error(`No Dapp Supported Wallet Found !`,
//                 {
//                     duration: 2000,
//                     style: {
//                         minWidth: '300px',
//                         minHeight: '55px'
//                     }
//                 });
//         }
//     } catch (e) {
//         toast.error(`Error : ${e}`,
//             {
//                 duration: 2000,
//                 style: {
//                     minWidth: '300px',
//                     minHeight: '55px'
//                 }
//             });
//     }


// }

export const WalletConnect = async (onSet) => {
    try {
    const RPC_URL = CURRENT_RPC_URL();
    const CHAIN_ID = CURRENT_CHAIN_ID();
    //Create WalletConnect Provider
    const provider = await EthereumProvider.init({
        projectId: PROJECT_ID, // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.connect()
      
      const web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
        const account = accounts[0].toString();
        sessionStorage.setItem("accountInfo", account);
        sessionStorage.setItem("wallet","connect")
        if(account){
            console.log(account,'account')
            onSet(account)
           }
        localStorage.setItem("wcv2",account)

    return provider;
}
catch (e) {
    toast.error(`Error : ${e}`,
        {
            duration: 2000,
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        });
}
}


// Wallet Connect v1

// export const WalletConnect = async (e) => {
//     const RPC_URL = CURRENT_RPC_URL();
//     const CHAIN_ID = (CURRENT_CHAIN_ID());
//     //Create WalletConnect Provider

//     const RPC_DATA = {};
//     RPC_DATA[CHAIN_ID] = RPC_URL
//     const provider = new WalletConnectProvider({
//         rpc: RPC_DATA,
//         network: 'binance',
//         chainId: CHAIN_ID,
//         // infuraId: "69de03b5c7194095980c9233f9cf71df",
//     }
   
//     );
//     console.log("provider_providerprovider",provider);
//     await provider.enable().then(function (error, result) {


//     })
//         .catch(e => {
//             //try again
//             toast.error(`Error : ${e}`,
//                 {
//                     duration: 2000,
//                     style: {
//                         minWidth: '300px',
//                         minHeight: '55px'
//                     }
//                 });
//         });
//     const web3 = new Web3(provider);
//     const accounts = await web3.eth.getAccounts();

//     const account = accounts[0].toString();
//     sessionStorage.setItem("accountInfo", account)
//     // localStorage.setItem("provider",JSON.stringify(provider))


//     return provider;
// }


// export const WalletConnect = async (e) => {


// const provider = await EthereumProvider.init({
//     projectId:'b8a1daa2dd22335a2fe1d2e139980ae0',
//     chains: [56],
//     showQrModal:true,
//     methods: [
//       "eth_sendTransaction",
//       "eth_signTransaction",
//       "eth_sign",
//       "personal_sign",
//       "eth_signTypedData",
//       "eth_requestAccounts"
//     ],
//     events: ["chainChanged", "accountsChanged"]
//   })

//   const session = await provider.connect({
//     namespaces: {
//       eip155: {
//         methods: [ 
//           "eth_sendTransaction",
//           "eth_signTransaction",
//           "eth_sign",
//           "personal_sign",
//           "eth_signTypedData",
//           "eth_requestAccounts"
//         ],
//         chains: [`eip155:56`],
//         events: ["chainChanged", "accountsChanged"],
//         rpcMap: {},
//       },
//     },
//   });
  
//   // await provider.enable();
//   console.log("provider",provider);
//   const web3 = new Web3(provider);
//   const accounts = await provider.request({
//     method: 'eth_requestAccounts',
//   });
//   console.log("provider_provider_provider",provider);
//     // const accounts = await web3.eth.getAccounts();

//     const account = accounts[0].toString();
//     sessionStorage.setItem("accountInfo", account)
//     localStorage.setItem("wcv2",account)
//     localStorage.setItem("provider", JSON.stringify(provider))
// }

