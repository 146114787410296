import React, { useState,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Images from '../Images';
import { forgetPass } from '../../Actions/forgetpass'
import { ValidateInputDatas } from "../Validations/Validations"
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate, useLocation } from "react-router-dom";
import isEmpty from "is-empty";

const Setup = (props) =>{
    const[setup, setSetup] = useState(true)
    const[EmailId, set_EmailId] = useState("");
    const [error, set_error] = useState("");
    const [type, set_type] = useState('email');
    
    const location = useLocation();
    console.log("location", location)

    const onChange = (e) => {
        console.log("event", e)
        if (e.target.id == 'EmailId')
            set_EmailId(e.target.value)
    };

    useEffect(() => {
       
        if (!isEmpty(location?.state?.EmailId)) {
            set_EmailId(location?.state?.EmailId)
          console.log("EmailId Refelecyecd ", location?.state?.EmailId)
         
        }
        
      }, [location])


    const onSubmit = async (e) => {
        console.log("Data")
        try {
            var data = {
                EmailId: EmailId   
            }
            // const { errors, isValid } = await ValidateInputDatas(data, "forgetPass")
            // set_error(errors)
            // console.log("_errors_errors", errors, isValid)
            // if (Object.keys(errors).length == 0 && isValid === true) {
                var forgetData = {
                    EmailId: EmailId,
                    type:type   
                }
                var resp = await forgetPass(forgetData)
                console.log("forgetData", resp)
            //}
            if (resp.status == 500) {
                toast.error("Error !Try Again...", {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
            }else{
                toast.success(`${resp?.data?.checkUser?.message}`, {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                    
                })
                props.onDismiss()
            }
        }
        catch (err) {
            console.log("forgetData", err)
        }

    };


    return(
        <>
         <Modal show={setup} className='thememodal' centered>
        <Modal.Header className='jc-center pb-0 pt-5'>
            <div className='text-center'>
          <Modal.Title>Setup your account</Modal.Title>
          <p className='f-12 text-muted'>By continuing, you agree to our <a href="#"><span className='blue'>Terms of Service</span></a> , including the use of Cookies.</p>
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            <img src={Images.youtube} className='youtube img'/>
            <img src={Images.all} className='all img'/>
            <div className='position-relative mt-4 input mx-auto'>
                <input className='form-control' placeholder='mail@gmail.com' id= "EmailId" value={EmailId}/>
                <span className="text-danger  mb-3 d-block text-left">{error.EmailId}</span>
                <span className='fa fa-check'></span>
         
       
            </div>
            <div className=' squarebtn my-5' onClick={onSubmit} state={{ EmailId: EmailId }}>
                <button> Send a code to this email  <img src={Images.btnimg}/></button>
              
              
            </div>
        </Modal.Body>
      
      </Modal>
        </>
    )
}

export default Setup