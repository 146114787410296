import React, { useState } from 'react';
import Images from '../Images';
import Story from './Story';
import Community from './Community';
import Suggestion from './Suggestion';
import Linksec from '../Rightsection.js/Linksec';
import About from './About';
import { Dropdown, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import Relatedevent from './Relatedevents';
import YourEvents from '../Eventspage/YourEvents';
import CreateEvent from '../Modals/CreateEvent';
const LeftSection = (props) =>{
    {var date = new Date();}

    const[createevent, setCreateEvent] = useState(false)
    return(
        <>
         {createevent && <CreateEvent onDismiss={ () => setCreateEvent(false)} />} 

        <section id='leftsection' className='sidesections text-start'>
          <div className='hidein1199'> <Linksec/> </div> 
            {/* <div id="logo" className='bordbtm innersec logosec pt-3'>
               <img src={Images.logo} className='img-fluid'/><span className='dnonein991 ms-2'>DVG</span> 
            </div> */}
            <div className='leftcont'>
                <div className='viji'>
                {props.link == "feeds" ? <div className='bordbtm innersec linksec'>
                    <h6 className='hidein991'>Feeds</h6>
                <ul> 
                    <li> <Link to="/feeds" state={{ name: "feeds",data:"active" }} ><div  className='hoverdiv'><img src={Images.feed} className='img-fluid'/></div><span>All</span></Link> </li>
                    <li> <Link to="/feeds" state={{ name: "favorite",data:"active" }}><div  className='hoverdiv'><img src={Images.explore} className='img-fluid'/></div><span>Favorite</span></Link> </li>
                    <li> <Link to="/feeds" state={{ name: "friends",data:"active" }}><div  className='hoverdiv'><img src={Images.wallet} className='img-fluid'/></div><span>Friends</span></Link> </li>
                    <li> <Link to="/feeds" state={{ name: "group",data:"active" }}><div  className='hoverdiv'><img src={Images.group} className='img-fluid'/></div><span>Groups</span></Link> </li>
                    <li> <Link to="/feeds" state={{ name: "hidepost",data:"active" }}><div  className='hoverdiv'><img src={Images.event} className='img-fluid'/></div><span>Hide post</span></Link> </li>
               </ul>
            </div> : props.link == "groups" ? <div className='bordbtm innersec linksec'>
                    <h6 className='hidein991'>Groups</h6>
                <ul> 
                    <li> <Link to="/groups" state={{ name: "yourfeeds",data:"active" }} ><div className='hoverdiv'><img src={Images.feed} className='img-fluid'/></div><span>Your feeds</span></Link> </li>
                    <li> <Link to="/groups" state={{ name: "discover",data:"active" }}><div className='hoverdiv'><img src={Images.explore} className='img-fluid'/></div><span>Discover</span></Link> </li>
                    <li> <Link to="/groups" state={{ name: "yourgroup",data:"active" }}><div className='hoverdiv'><img src={Images.wallet} className='img-fluid'/></div><span>Your group</span></Link> </li>
                    <li> <Link className='btn themebtn text-center' to="/groups" state={{ name: "creategroup",data:"active" }}><span className='m-auto text-light'> + Create a Group</span></Link> </li>
                    <ul className='sidegroup'>
                        <li className='d-flex jc-between my-2 align-items-center'> 
                        <div className='d-flex squareimg'>  
                            <img src={Images.story1} className='user'/>
                            <div><Link to="/groupprof" class="smallhead">Blockchain world</Link>
                                <p class="f9txt mb-0"><span class="live avail me-1"></span>
                                Last active 2hr ago</p></div> 
                        </div>
                        <div> 
                                <Dropdown className="postdrop">
                                    <Dropdown.Toggle variant="" className=" morebtn  noarrow" id="dropdown-basic">
                                        <span className="fas fa-ellipsis-v"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </li>
                        <li className='d-flex jc-between my-2 align-items-center'> 
                        <div className='d-flex squareimg'>  
                            <img src={Images.story1} className='user'/>
                            <div><Link to="/groups" class="smallhead">Blockchain world</Link>
                                <p class="f9txt mb-0"><span class="live avail me-1"></span>
                                Last active 2hr ago</p></div> 
                        </div>
                        <div> 
                                <Dropdown className="postdrop">
                                    <Dropdown.Toggle variant="" className=" morebtn  noarrow" id="dropdown-basic">
                                        <span className="fas fa-ellipsis-v"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </li>
                        <li className='d-flex jc-between my-2 align-items-center'> 
                        <div className='d-flex squareimg'>  
                            <img src={Images.story1} className='user'/>
                            <div><Link to="/groups" class="smallhead">Blockchain world</Link>
                                <p class="f9txt mb-0"><span class="live avail me-1"></span>
                                Last active 2hr ago</p></div> 
                        </div>
                        <div> 
                                <Dropdown className="postdrop">
                                    <Dropdown.Toggle variant="" className=" morebtn  noarrow" id="dropdown-basic">
                                        <span className="fas fa-ellipsis-v"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </li>
                        <li className='d-flex jc-between my-2 align-items-center'> 
                        <div className='d-flex squareimg'>  
                            <img src={Images.story1} className='user'/>
                            <div><Link to="/groups" class="smallhead">Blockchain world</Link>
                                <p class="f9txt mb-0"><span class="live avail me-1"></span>
                                Last active 2hr ago</p></div> 
                        </div>
                        <div> 
                                <Dropdown className="postdrop">
                                    <Dropdown.Toggle variant="" className=" morebtn  noarrow" id="dropdown-basic">
                                        <span className="fas fa-ellipsis-v"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                                        <Dropdown.Item  className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </li>
                    </ul>
               </ul>
            </div>  :  props.link == "events" ?    
           <> <div className='bordbtm innersec linksec'>
                <ul>
                    <li> <Link to="/feeds"><div className='hoverdiv'><img src={Images.feed} className='img-fluid' /></div><span>Home</span></Link> </li>
                    <li> <Link to="home"><div className='hoverdiv'><img src={Images.explore} className='img-fluid' /></div><span>Your Event</span></Link> </li>
                    <li> <Link to="home1"><div className='hoverdiv'><img src={Images.wallet} className='img-fluid' /></div><span>Birthday</span></Link> </li>
                    <li> <Link to="/groups"><div className='hoverdiv'><img src={Images.group} className='img-fluid' /></div><span>Notification</span></Link> </li>
                </ul>
            </div> 
            <button className='themebtn w-full my-2' onClick={ () => setCreateEvent(true)}> 
                
                <span className='fa fa-plus-circle'></span>
                <span className='hidein991 ms-2'>Create Events</span> 
            </button>
            <div className='bordbtm innersec communitysec hidein1199'>
                <YourEvents />
            </div>
            <div className='bordbtm innersec communitysec hidein1199'>
                <Relatedevent />
            </div>
             </>:<>
            <div className='bordbtm innersec linksec'>
              <ul>
                  <li> <Link to="/feeds"><div className='hoverdiv'><img src={Images.feed} className='img-fluid'/></div><span>Feed</span></Link> </li>
                  <li> <Link to="home"><div className='hoverdiv'><img src={Images.explore} className='img-fluid'/></div><span>Explore</span></Link> </li>
                  <li> <Link to="home1"><div className='hoverdiv'><img src={Images.wallet} className='img-fluid'/></div><span>Wallet</span></Link> </li>
                  <li> <Link to="/groups"><div className='hoverdiv'><img src={Images.group} className='img-fluid'/></div><span>Groups</span></Link> </li>
                  <li> <Link to="/events"><div className='hoverdiv'><img src={Images.event} className='img-fluid'/></div><span>Event</span></Link> </li>
                  <li> <Link to="/friends"><div className='hoverdiv'><img src={Images.request} className='img-fluid'/></div><span>Request</span></Link> </li>
              </ul>
              <button className='morebtn small'><span className='fa fa-chevron-down'></span></button>
           </div> 
           
            </>
            }
          
            
            {props.section == "prof" ?  
            <div className='mt-3 hidein1199'>
                <About />
            </div> : <div>
            <div className='bordbtm innersec storysec hidein1199'>
                <Story />
            </div>

            <div className='bordbtm innersec communitysec hidein1199'>
                <Community />
            </div>
            <div className='bordbtm innersec suggestsec hidein1199'>
                <Suggestion />
            </div>
            </div>
            }
           
            </div>
           <div className='datetime'><img src={Images.event} />{date.getDate()} {date.toLocaleString('en',{month:'long'})}, {date.getFullYear()}</div>
            </div>
          
        </section></>
    )
}

export default LeftSection