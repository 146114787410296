import React, { useEffect, useState } from 'react';

import Header from '../Header';
import FriendProfCont from './FriendProfCont';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Images from '../Images';

const FriendProf = () =>{
 
  
  const[showbar, setShowBar] = useState(true)
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])
return(
 <>
 <Header />
<section id='home'>
<button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
  <div className='themecontainer'>
  <div className='themewidth'>
     <LeftSection  section="prof"/>
     <div id="content" className=' w-full d-flex'>
     
      <FriendProfCont/>
      {showbar && <RightSection  rightsection="prof" />}
      
     </div>
  </div>
  </div>
 
</section>
</> 
)
}


export default FriendProf
