import React, { useState } from 'react';
import Images from '../Images';

const ActiveGroup = () =>{
    return(
        <div id="activegroup">
        <div className='d-flex jc-between align-items-end p-3'>
            <p className='subhead'>Most Active Group</p>
            <div> 
                <button className='morebtn'>More</button>
            </div>
       </div>
       <div className='themebord p-0'></div>
       <ul className='p-3 text-start mb-0'>
       <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story5} className='img-fluid user me-lg-2' />
            <div className=''>
                    <h6 className='smallhead'>Blockchain world</h6>
                    <p className='f9txt mb-0'><span className='live avail me-1'></span>24 Members are currently active</p>
                      {/* live, offline */}
                </div>
            </div>
         </li>
         <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story2} className='img-fluid user me-lg-2' />
            <div className=''>
                    <h6 className='smallhead'>Blockchain world</h6>
                    <p className='f9txt mb-0'><span className='offline avail me-1'></span>24 Members are currently active</p>
                </div>
            </div>
         </li>
          <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story1} className='img-fluid user me-lg-2' />
                <div className=''>
                    <h6 className='smallhead'>Blockchain world</h6>
                    <p className='f9txt mb-0'><span className='live avail me-1'></span>24 Members are currently active</p>
                </div>
                      
            </div>
          </li>
       </ul>
    </div>
    )
}

export default ActiveGroup