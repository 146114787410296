import React, { useEffect, useState } from 'react';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Header from '../Header';
import Images from '../Images';
import Linksec from '../Rightsection.js/Linksec';


import { useLocation } from 'react-router-dom';

import YourFeed from './YourFeed';
import Discover from './Discover';
import YourGroup from './YourGroup';
import CreateGroup from './CreateGroup';

const Groups = () =>{
 const location = useLocation()

 const page=  location?.state?.name;
 const activeclass=  location?.state?.data;
 console.log(location,"location")
  const[showbar, setShowBar] = useState(true)
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])
return(
 <>
 <Header />
<section id='home'>
    <button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
  <div className='themecontainer'>
    <div className='themewidth'>
        <LeftSection link="groups"/>
        <div id="content" className=' w-full d-flex'>
        
        <section id='centersection' className='posts'>
            <div className='showin1199 showrightsec'><Linksec/></div> 
            {(page == undefined||page == null || page == "yourfeeds") && <YourFeed /> }
       
            {page == "discover" && <Discover />}
            {page == "yourgroup" && <YourGroup />}
            {page == "creategroup" && <CreateGroup />}
           {/* {page == "feeds" ? <AllFeeds /> : page == "favorite" ? <Favorite />
            : <Group />} */}
            </section>
        {showbar && <RightSection />}
        
        </div>
    </div>
  </div>
 
</section>
</> 
)
}


export default Groups
