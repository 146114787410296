import axios from "../utils/setAuthtoken";
import * as config from "../config/config";
import isEmpty from "is-empty";
import {
    SET_USER_DETAILS,
} from '../constants/constants'

export const setAccountData = (data) => {
    // console.log('dats----------------------->',data)
    return {

        type: SET_USER_DETAILS,
        data: {
            userDetails: {
                EmailId: data.EmailId,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                profilePicture: data.profilePicture,
                walletAddress: data.walletAddress,
                userName: data.userName,
                displayName: data.displayName,
                Bio:data.Bio,
                backgroundImage:data.backgroundImage
            }
        },
    };
};



export const login = async (logData, dispatch) => {
    try {
        console.log("login_login")
        let respData = await axios.post(config.backurl + "/api/login", logData)
        if (respData.status == 200) {
            console.log("jjjj", respData);
        }
        if (!isEmpty(respData?.data?.data?.result)) {
            dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("login", err);
    }
}



export const walletLogin = async (logData, dispatch) => {
    try {
        console.log("walletLogin_login")
        let respData = await axios.post(config.backurl + "/api/walletLogin", logData)
        if (respData.status == 200) {
            console.log("walletLogin_result", respData);
        }
        if (!isEmpty(respData?.data?.data?.result)) {
            dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("walletLogin", err);
    }
}


export const googleLogin = async (data, dispatch) => {
    try {
        console.log("googleLogin_login")
        let respData = await axios.post(config.backurl + "/api/googleLogin", data)
        if (respData.status == 200) {
            console.log("googleLogin_result", respData);
        }
        if (!isEmpty(respData?.data?.data?.result)) {
            dispatch(setAccountData(respData?.data?.data?.result));
        }
        return respData
    }
    catch (err) {
        console.log("googleLogin", err);
    }
}