import React, { useReducer, useState } from "react";
import { Col, Modal, Row, Form, InputGroup, Button } from "react-bootstrap";
import MessageLeft from "../../Components/Message/MessageLeft";
import Images from "../Images";
import Story1 from "../../Assets/Images/story/story1.png";
import Story2 from "../../Assets/Images/story/story2.png";

const Message = (props) => {
  const [messagebox, setMessagebox] = useState(true);
  const [startchat, setStartchat] = useState(true);
  const [startchattwo, setStartchattwo] = useState(true);
  const [userchat, setUserchat] = useState(true);
  const [issettings, setissettings] = useState(false);
  const [isgrpsettings, setisgrpsettings] = useState(false);
  const [isGroupchat, setIsgroupchat] = useState(false);

  const [detailSettings, setDetailsettings] = useState(true);
  const [photoSettings, setPhotosettings] = useState(false);
  const [inviteSettings, setInvitesettings] = useState(false);

  const [nameedit, setNameedit] = useState(false);
  const [descedit, setDescedit] = useState(false);

  



  const schat = () => {
    setStartchattwo(!startchattwo);
    setStartchat(!startchat);
  };

  const usmsg = () => {
    setUserchat(!userchat);
    setStartchattwo(!startchattwo);
  };

  const [selectchat] = useState([
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story2,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story2,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story2,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story2,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story2,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
    },
  ]);


  const [memberGrp] = useState([
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
      membertype:"Admin"
    },
    {
      img: Story2,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
      membertype:"Member"
    },
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
      membertype:"Invited"
    },
    {
      img: Story2,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
      membertype:"Member"
    },
    {
      img: Story1,
      name: "Bitcoinworld",
      subname: "@bitcoinworld",
      membertype:"Member"
    },
    
    
    
  ]);


const chat = (data)=>{setIsgroupchat(data)}
const detailsshow =(data)=>{setDetailsettings(data)}
const photoshow =(data)=>{setPhotosettings(data)}
const inviteshow =(data)=>{setInvitesettings(data)}

  return (
    <Modal show={messagebox} className="msgmodal" centered>
      <Modal.Body>
        <div className="msgtop">
          <Row>
            <Col xl={5} lg={5}>
              <MessageLeft setChat={schat} settings={issettings} grpsettings={isgrpsettings} setIsgroupchat={isGroupchat} checkGroupchat={chat} checkDetails={detailsshow} checkPhoto={photoshow} checkInvite={inviteshow} detailSettings={detailSettings} onDismissmodal={setMessagebox} prevParent={props.onDismiss} />
            </Col>
            <Col xl={7} lg={7}>
              <div className="rightabbox">
              

{isgrpsettings?
<>
{detailSettings?
  <div className={"newchat"}>
                  <div className="msghead">
                    <h6 className="h6 ms-0">Details</h6>
                  </div>
                  <div className="msgbody">
                  <div className="flexb add">
                    <div>
                      <p className="text-blue mb-0">
                        Name
                      </p>
                    </div>
                    <div>
                      <span className="text-blue">0/99</span>
                    </div>
                  </div>
                  <div className="searchbox">
                    <p className="mb-0">
                      Demo Group
                    </p>
                  </div>

                  <div className="flexb add">
                    <div>
                      <p className="text-blue mb-0">
                      View Member
                      </p>
                    </div>
                    <div>
                      <span className="text-blue">0/50</span>
                    </div>
                  </div>
               


                  <div className="markchat mt-3">
                    <ul className="border-0">
                      {memberGrp.map((mchat) => (
                        <li>
                          <div className="flexb">
                            <div className="newdet">
                              <div>
                                <img src={mchat.img} />
                              </div>
                              <div>
                                <h6 className="h6">{mchat.name}</h6>
                                <p>{mchat.subname}</p>
                              </div>
                            </div>
                            <div>
                            <p className="meber_blue">{mchat.membertype}</p>
                        
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  </div>
                </div>
  :
  <>
  {photoSettings?
  <div className={"newchat"}>
                  <div className="msghead">
                    <h6 className="h6 ms-0">Update Group Photo</h6>
                  </div>
                  <div className="msgbody">

               <div className="profile_phot_sec">
                <div className="profilestat">
               <img src={Images.prof} className='img-fluid br-5 me-1'/>
               <div className="profi_edit">
               <input id="file-upload" type="file"/>

               <i className="fa fa-pencil-alt"></i>
               </div>

               </div>
               <div className="desc_text_photo mt-3">
               <p className="text-blue">Name</p>
               {!nameedit?
                <div className="searchbox pt-0">
                <InputGroup>
                  <InputGroup.Text id="basic-addon2" className="cur_pointer">
                    <i className="fa fa-pencil-alt" onClick={()=>{setNameedit(true)}}></i>
                  </InputGroup.Text>
                  <Form.Control value="Jackson" />
                </InputGroup>
              </div>
              :
              <div className="searchbox pt-0">
              <InputGroup>
                <InputGroup.Text id="basic-addon2" className="cur_pointer">
                  <i className="fa fa-check" onClick={()=>{setNameedit(false)}}></i>
                </InputGroup.Text>
                <Form.Control placeholder="Jackson" />
              </InputGroup>
            </div>
              }
             
            
               <p className="text-blue">Description</p>
               {!descedit?
                <div className="searchbox pt-0">
                <InputGroup>
                  <InputGroup.Text id="basic-addon2" className="cur_pointer">
                    <i className="fa fa-pencil-alt" onClick={()=>{setDescedit(true)}}></i>
                  </InputGroup.Text>
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" value="lorem ipsum dolar sit amet"></textarea>
                  {/* <Form.Control value="Jackson" /> */}
                </InputGroup>
              </div>
              :
              <div className="searchbox pt-0">
              <InputGroup>
                <InputGroup.Text id="basic-addon2" className="cur_pointer">
                  <i className="fa fa-check" onClick={()=>{setDescedit(false)}}></i>
                </InputGroup.Text>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="lorem ipsum dolar sit amet"></textarea>
                {/* <Form.Control placeholder="Jackson" /> */}
              </InputGroup>
            </div>
              }
               </div>

               </div>
               
</div>
             
               


              
                  
                </div>
  :
  <>
 {inviteSettings?
  <div className={"newchat"}>
  <div className="msghead">
    <h6 className="h6 ms-0">Invite Member</h6>
  </div>
  <div className="msgbody">

  <div className="flexb add">
                  <div>
                    <a href="#" className="text-blue">
                      Add Participants
                    </a>
                  </div>
                  <div>
                    <span className="text-blue">0/99</span>
                  </div>
                </div>

                <div className="searchbox">
                  <InputGroup>
                 
                    <Form.Control placeholder="Demo Group" />
                  </InputGroup>
                </div>


            

                <div className="flexb add">
                  <div>
                    <a href="#" className="text-blue">
                    Group Name
                    </a>
                  </div>
                  <div>
                    <span className="text-blue">0/50</span>
                  </div>
                </div>
                <div className="searchbox">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon2">
                      <i className="fa fa-search"></i>
                    </InputGroup.Text>
                    <Form.Control placeholder="Search Member" />
                  </InputGroup>
                </div>



  <div className="markchat mt-3">
    <ul className="border-0">
      {memberGrp.map((mchat) => (
        <li>
          <div className="flexb">
            <div className="newdet">
              <div>
                <img src={mchat.img} />
              </div>
              <div>
                <h6 className="h6">{mchat.name}</h6>
                <p>{mchat.subname}</p>
              </div>
            </div>
            <div>
            <p className="meber_blue">{mchat.membertype}</p>
        
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
  </div>
</div>
 :
 ""
 }
 </>
}

  </>
}


</>

                :
                <>
                <div className={startchat ? "startab" : "d-none"}>
                <div className="innertab">
                  <div className="tabimg">
                    <img src={Images.message2} className="goingimg" />
                  </div>
                  <p>You still do not have any ongoing conversation</p>
                  <div className="mt-4 text-center">
                    <Button className="themebtn" onClick={schat}>
                      Start Chat
                    </Button>
                  </div>
                </div>
              </div>
              <div className={startchattwo ? "d-none" : "newchat"}>
                <div className="msghead">
                  <h6 className="h6 ms-0">New Chat</h6>
                </div>
                <div className="msgbody">

                <div className="flexb add">
                  <div>
                    <a href="#" className="text-blue">
                      Add Participants
                    </a>
                  </div>
                  <div>
                    <span className="text-blue">0/99</span>
                  </div>
                </div>
                <div className="searchbox">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon2">
                      <i className="fa fa-search"></i>
                    </InputGroup.Text>
                    <Form.Control placeholder="Search" />
                  </InputGroup>
                </div>

                <div className="flexb add">
                  <div>
                    <a href="#" className="text-blue">
                    Group Name
                    </a>
                  </div>
                  <div>
                    <span className="text-blue">0/50</span>
                  </div>
                </div>
                <div className="searchbox">
                  <InputGroup>
                 
                    <Form.Control placeholder="Enter group name" />
                  </InputGroup>
                </div>


                <div className="markchat">
                  <ul>
                    {selectchat.map((mchat) => (
                      <li>
                        <div className="flexb">
                          <div className="newdet">
                            <div>
                              <img src={mchat.img} />
                            </div>
                            <div>
                              <h6 className="h6">{mchat.name}</h6>
                              <p>{mchat.subname}</p>
                            </div>
                          </div>
                          <div>
                            <Form>
                              <Form.Check type="checkbox"></Form.Check>
                            </Form>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                </div>
                <div className="newbtn">
  
                  <Button className="btn bordbtn me-2 me-sm-4" onClick={()=>{setStartchat(true);setStartchattwo(true)}}>Cancel</Button>
                  <Button className="themebtn" onClick={usmsg}>
                    Start Chat
                  </Button>
                </div>
              </div>
              {!isGroupchat?

              <div className={userchat ? "d-none" : "userchat"}>
                <div className="msghead">
                  <div className="d-flex align-items-center">
                    <span className="fa fa-user" aria-hidden="true"></span>
                    <div>
                    <h6 className="h6">@Bitcoinworld</h6>
                    </div>
                  </div>
                  <div>
                    <Button className="morebtn" onClick={()=>{setissettings(true)}}>
                      <span className="fa fa-cog" aria-hidden="true"></span>
                    </Button>
                  </div>
                </div>
                <div className="chatbox">
                  <ul>
                    <li className="chat_right">
                      <div>
                        <p>Hi</p>
                      </div>
                      <div>
                        <span className="active">
                          <i className="fa fa-check"></i>
                          <i className="fa fa-check"></i>
                          4.28 PM
                        </span>
                      </div>
                    </li>
                    <li className="chat_left">
                      <div>
                        <p className="delmsg">
                          This message has been deleted
                        </p>
                      </div>
                    </li>
                    <li  className="chat_right">
                      <div>
                        <div>
                          <img src={Images.post1} alt="" />
                        </div>
                        <span>
                          <i className="fa fa-check"></i>
                          4.28 PM
                        </span>
                      </div>
                    </li>
                    <li  className="chat_left">
                      <div>
                        <div>
                          <img src={Images.puppy} alt="" />
                        </div>
                        <span>
                          <i className="fa fa-check"></i>
                          <i className="fa fa-check"></i>

                          4.28 PM
                        </span>
                      </div>
                    </li>

                    <li  className="chat_right">
                      <div>
                      <div className='video_tag_chat'>

<video width="170" height="96" controls muted preLoad="auto">

 <source src={Images.watervideo} type="video/mp4" />
 Your browser does not support the video tag.
 </video>
 </div>
                        <span>
                          <i className="fa fa-check"></i>
                          4.28 PM
                        </span>
                      </div>
                    </li>

                    <li  className="chat_right">
                      <div>
                        <div className="audio_div">
                        <i class="fas fa-play"></i>
                        <span>
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                         

                        </span>
                        <span className="music_time">
                          00:42
                        </span>
                         
                        </div>
                        <span>
                          <i className="fa fa-check"></i>
                          <i className="fa fa-check"></i>

                          4.28 PM
                        </span>
                      </div>
                    </li>
                  </ul>
                  
                </div>
                <div className="message_type">
                  <div className="message_card">
                    <input className="msg_input" placeholder="Type a message" />
                    <div className="msg_icon_sec">
                      <div>
                    <i className="fas fa-paperclip"></i>
                    <i className="fas fa-image"></i>
                    <i className="fas fa-smile"></i>
                    </div>
                    <button className="themebtn btn_sqr_send">
                    <i className="far fa-paper-plane"></i>

                    </button>
                    </div>

                  </div>

                </div>
              </div>

              :

              <div className={userchat ? "d-none" : "userchat"}>
                <div className="msghead">
                  <div className="d-flex align-items-center">
                    <span className="fa fa-users" aria-hidden="true"></span>
                    <div>
                    <h6 className="h6">@Bitcoinworld</h6>
                    <p className="grp_mem">{"@bitcoinworld and @crypto and 1 others"}</p>
                    </div>
                  </div>
                  <div>
                    <Button className="morebtn" onClick={()=>{setisgrpsettings(true)}}>
                      <span className="fa fa-cog" aria-hidden="true"></span>
                    </Button>
                  </div>
                </div>
                <div className="chatbox">
                  <ul>
                    <li className="chat_right">
                      <div>
                        <p>Hi</p>
                      </div>
                      <div>
                        <span className="active">
                          <i className="fa fa-check"></i>
                          <i className="fa fa-check"></i>
                          4.28 PM
                        </span>
                      </div>
                    </li>
                    <li className="chat_left">
                      <div>
                        <p className="delmsg">
                          This message has been deleted
                        </p>
                      </div>
                    </li>
                    <li  className="chat_right">
                      <div>
                        <div>
                          <img src={Images.post1} alt="" />
                        </div>
                        <span>
                          <i className="fa fa-check"></i>
                          4.28 PM
                        </span>
                      </div>
                    </li>
                    <li  className="chat_left">
                      <div>
                        <div>
                          <img src={Images.puppy} alt="" />
                        </div>
                        <span>
                          <i className="fa fa-check"></i>
                          <i className="fa fa-check"></i>

                          4.28 PM
                        </span>
                      </div>
                    </li>

                    <li  className="chat_right">
                      <div>
                      <div className='video_tag_chat'>

<video width="170" height="96" controls muted preLoad="auto">

 <source src={Images.watervideo} type="video/mp4" />
 Your browser does not support the video tag.
 </video>
 </div>
                        <span>
                          <i className="fa fa-check"></i>
                          4.28 PM
                        </span>
                      </div>
                    </li>

                    <li  className="chat_right">
                      <div>
                        <div className="audio_div">
                        <i class="fas fa-play"></i>
                        <span>
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                          <span className="music_bar" />
                         

                        </span>
                        <span className="music_time">
                          00:42
                        </span>
                         
                        </div>
                        <span>
                          <i className="fa fa-check"></i>
                          <i className="fa fa-check"></i>

                          4.28 PM
                        </span>
                      </div>
                    </li>
                  </ul>
                  
                </div>
                <div className="message_type">
                  <div className="message_card">
                    <input className="msg_input" placeholder="Type a message" />
                    <div className="msg_icon_sec">
                      <div>
                    <i className="fas fa-paperclip"></i>
                    <i className="fas fa-image"></i>
                    <i className="fas fa-smile"></i>
                    </div>
                    <button className="themebtn btn_sqr_send">
                    <i className="far fa-paper-plane"></i>

                    </button>
                    </div>

                  </div>

                </div>
              </div>
          
}
</>
}

              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Message;
