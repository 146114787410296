import axios from "../utils/setAuthtoken";
import * as config from "../config/config";



export const resetPassword = async(resetData) => {
    try {
        console.log("resetData")
        let result = await axios.post(config.backurl + "/api/resetPassword", resetData)
        if (result.status == 200) {
            console.log("resetData_res", result);
        }
        return result
    }
    catch (err) {
        console.log("resetData_err", err);
    }
}