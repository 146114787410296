import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../hstyle.scss";


const Captchaa = (props) => {

   
    // function onChange(value) {
    //     console.log("Captcha value:", value);
    // }

    return (
        <>
            <div className="captcha mt-3 mb-2">
                <ReCAPTCHA
                //local
                    // sitekey="6LeNYB4nAAAAALOginXnJkhbXt6tDh6NHgFCpRD3"
                     //live
                    sitekey = "6Lc2HiMnAAAAAFU495SreoXQPMlq5l0Z1LBzx6jv"   
                    onChange={props.onchange}                
                />
            </div>

        </>
    )
}


export default Captchaa;