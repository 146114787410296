import axios from "../utils/setAuthtoken";
import * as config from "../config/config";



export const forgetPass = async(forgetData) => {
    try {
        console.log("forgetData")
        let result = await axios.post(config.backurl + "/api/forgetPassword", forgetData)
        if (result.status == 200) {
            console.log("forgetData_res", result);
        }
        return result
    }
    catch (err) {
        console.log("forgetData_err", err);
    }
}