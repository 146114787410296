import React, { useState } from 'react';
import Images from '../Images';

const Suggestion = () =>{
    return(
        <div id="suggestion">
          <div className='d-flex jc-between align-items-end px-xl-0 px-3 py-xl-0 py-3'>
            <p className='subhead'>Suggestion <span className='dnonein991'>for you</span></p>
            <div> 
                <button className='morebtn small me-1'><span className='fa fa-eye'></span></button>
                <button className='morebtn small'><span className='far fa-bookmark'></span></button>
            </div>
       </div>
       <div className='themebord p-0 showin1199'></div>
       <ul className=' px-xl-0 px-3 py-3 text-start mb-0'>
       <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story8} className='img-fluid user me-2' />
            <div className=''>
                <h6 className='smallhead'>Jessi</h6>
                <p className='f9txt mb-0'>24 Members are currently active</p>
            </div></div>
            <button className='morebtn small'><img src={Images.favuserblue} className='img-fluid' /></button>
          </li>
           <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story2} className='img-fluid user me-2' />
            <div className=''>
                <h6 className='smallhead'>Victoria</h6>
                <p className='f9txt mb-0'>24 Members are currently active</p>
            </div></div>
            <button className='morebtn small'><img src={Images.favuserblue} className='img-fluid' /></button>
          </li>
           <li className='d-flex align-items-center jc-between squareimg mb-2'>
            <div className=' d-flex align-items-center'><img src={Images.story1} className='img-fluid user me-2' />
            <div className=''>
                <h6 className='smallhead'>Bell</h6>
                <p className='f9txt mb-0'>24 Members are currently active</p>
            </div></div>
            <button className='morebtn small'><img src={Images.favuserblue} className='img-fluid' /></button>
          </li>
       </ul>
    </div>
    )
}

export default Suggestion