import React, { useState } from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import Images from '../Images';
import Comments from './Comp/Comments';

const Videomodal = (props) =>{
    const[videomodal, setVideomodal] = useState(true)
    return(
        <>
         <Modal show={videomodal} className='thememodal postmodal' centered>
       
        <Modal.Body>
        <div className="modalbg">
        <Row>
                  <Col xl={9} lg={8} md={12} sm={12} xs={12}>
                    <div className="modalview">
                      <video autoPlay loop>
                        <source src={Images.demo}></source>
                      </video>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={12} sm={12} xs={12}>
                    <Comments />
                  </Col>
                </Row>
              <button className='fa fa-times btn btn-link modalbtn' onClick={props.onDismiss}></button>
              </div>
        </Modal.Body>
      
      </Modal>
        </>
    )
}

export default Videomodal