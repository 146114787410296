import React, { useEffect, useState, useCallback, useRef } from "react";
import { NavLink } from "react-router-dom";
import Images from "./Images";
import { Form, InputGroup, Tab, Tabs, Button } from "react-bootstrap";
import { BsGoogle, BsApple } from "react-icons/bs";
// import OwlCarousel from "react-owl-carousel-rtl";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaRegCopy } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';
import Slider from "react-rangeslider";
import "../../node_modules/react-rangeslider/lib/index.css";
import LeftCarousel from "./LeftCarousel";
import { getData } from '../Actions/setProfilepic';
import * as config from "../config/config";
import ReactCrop from 'react-image-crop';
import { profilePicture } from '../Actions/setProfilepic'
import 'react-image-crop/dist/ReactCrop.css';
import isEmpty from "is-empty";
import { useSelector, useDispatch } from 'react-redux'

const Uploadimage = () => {
  const imgRef = useRef(null);
  const [checked, setChecked] = useState(false);
  const [image, setImage] = useState(null);
  const [srcs, set_src] = useState(null)
  const [upImg, setUpImg] = useState();
  const previewCanvasRef = useRef(null);

  const [crop, setCrop] = useState({ unit: 'px', width: 30, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  // const [crop, setCrop] = useState({aspect: 1 / 1,
  // height: 468,
  // unit: "px",
  // width: 468,
  // x: 0,
  // y: 107,});
  const [slide, setSlide] = useState({
    value: 10,
  });

  const dispatch = useDispatch();

  const history = useNavigate();

  const location = useLocation();
  console.log("location", location)

  const slidechange = (value) => {
    setSlide({
      value: value,
    });
  };

  // const imgsrc = (e) => {
  //   const imgInput = document.querySelector("input.file");
  //   const imgEl = document.querySelector(".img");
  //   const imgName = document.querySelector(".filename");
  //   console.log(e.target.files, "e.target.innerHtml");
  //   console.log(e.target.parentNode, "parent");
  //   var img = e.target.parentNode.previousSibling.firstChild.innerHTML;
  //   console.log(img, "prev sibling");
  //   console.log(document.querySelector(".img"), "prev sibling");
  // };

  // const options1 = {
  //   loop: true,
  //   margin: 20,
  //   nav: true,
  //   dots: false,
  //   smartSpeed: 1200,
  //   navText: [
  //     "<i class='fa fa-arrow-left'></i>",
  //     "<i class='fa fa-arrow-right'></i>",
  //   ],
  //   autoplay: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 1,
  //     },
  //     1000: {
  //       items: 1,
  //     },
  //   },
  // };


  var cropImage = async () => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );


    // Converting to base64
    // const base64Image = canvas.toDataURL('image/jpeg');
    // setImage(canvas);
  }

  const onSubmit = async () => {
    console.log(image,'image')
    // let data={
    //   profilePicture:image
    // }
    
    var formData = new FormData();
    // let base64Data = image.slice(22)
    // let fileType = image.match(/:(.*?);/)[1]
    // var fileName = "image" + `.${fileType.slice(6)}`
    // console.log(base64Data,'base64Data')
    // console.log(fileType)
    // var files = dataURLtoFile(base64Data,fileName,fileType)
    formData.append("profilePicture", image)
    // formData.append("profilePicture", upImg)

    var resp = await profilePicture(formData,dispatch);
    if (resp?.status == 500) {
      toast.error("Error !Try Again...", {
        style: {
          minWidth: '300px',
          minHeight: '55px'
        }
      })
    } else {
      toast.success(`${resp?.data?.data?.message}`, {
        style: {
          minWidth: '300px',
          minHeight: '55px'
        }
      })

      history('/welcome')

    }


  }
  function dataURLtoFile(base64Data, fileName,fileType) {
    var bstr = atob(base64Data.toString());
    console.log("decodede", bstr)
    var n = bstr.length;
    console.log("decode length", n)
    var u8arr = new Uint8Array(n);
    console.log(u8arr)
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:fileType});
}

  useEffect(() => {
    // set_src(URL.createObjectURL())
    if (!isEmpty(location?.state?.profilePicture)) {
      // const imageReader = new FileReader();
      // imageReader.addEventListener('load', () => set_src(imageReader.result))
      // imageReader.readAsDataURL(location.state.profilePicture);
      // imgRef.current = location?.state?.profilePicture
      setUpImg(location?.state?.profilePicture)
      console.log("Image Refelecyecd ", location?.state?.profilePicture)
      // imageReader.onloadend = () => {
      //   set_src(imageReader.result);
      //   // setImage(imageReader.result)
      // };
    }
    // console.log("state",state)
  }, [location])


  /* 
  
  New Crop
  
  
  */

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
  }, [completedCrop]);

  function setCanvasImage(image, canvas, crop) {
    if (!crop || !canvas || !image) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    // refer https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    let fileType = upImg.match(/:(.*?);/)[1]
    const base64Image = canvas.toDataURL(fileType);
    console.log(base64Image,'base64Image')
    setImage(base64Image);
    slidechange(100)
  }




  // const imageLoaded = (image) => {
  //   console.log(image,'imageLoaded')
  //   setImage(image);
  // };
  const imageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);
  function imageCropComplete(crop) {
    // console.log(crop,'crop')
    userCrop(crop);
  }
  async function userCrop(crop) {

    console.log(crop, 'crop', imgRef.current)
    if (imgRef.current && crop.width && crop.height) {

      await getCroppedImage(imgRef.current, crop, "newFile.jpeg");
    }
  }
  function getCroppedImage(image, crop, fileName) {
    const imageCanvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    imageCanvas.width = crop.width;
    imageCanvas.height = crop.height;
    const imgCx = imageCanvas.getContext("2d");
    imgCx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((reject, resolve) => {
      imageCanvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("the image canvas is empty"));
          return;
        }
        blob.name = fileName;
        let imageURL;
        window.URL.revokeObjectURL(imageURL);
        imageURL = window.URL.createObjectURL(blob);
        resolve(imageURL);
        // setImageUrl(blob);
      }, "image1/jpeg");
    });
  }
  return (
    <>
      <div>
        <div className="signup">
          <div className="container">
            <div className="row mh-100">
              <div className="col-md-5 col-lg-6">
                <LeftCarousel />
                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                  <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                </div>

                <div className='carouselnone'>
                  <div className="position-relative pb-5">
                    <img className="imagecloud" src={Images.whitecloud} />
                  </div>
                  <div className="mt-md-5 mt-4">
                    <OwlCarousel className="owl-theme" {...options1}>
                      <div class="item">
                        <h2 className="signup-h">
                          A Block chain<br></br> Social network for you
                        </h2>
                        <p className="signup-para">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                      </div>
                      <div class="item">
                        <h2 className="signup-h">
                          A Block chain<br></br> Social network for you
                        </h2>
                        <p className="signup-para">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                      </div>
                      <div class="item">
                        <h2 className="signup-h">
                          A Block chain<br></br> Social network for you
                        </h2>
                        <p className="signup-para">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                      </div>
                      <div class="item">
                        <h2 className="signup-h">
                          A Block chain<br></br> Social network for you
                        </h2>
                        <p className="signup-para">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                      </div>
                    </OwlCarousel>
                  </div>
                  <div className="position-relative pt-5">
                    <img className="imagecloud1" src={Images.whitecloud} />
                  </div>
                </div> */}
              </div>
              <div className="col-md-7 col-lg-6">
                <div className="signupcard uploadimg">
                  <h4 className="connectheading text-center">
                    Pick your Profile picture
                  </h4>
                  <p className="subheading text-center mb-4">
                    
                  </p>
                  <img className="leftgraycloud" src={Images.gallery} />
                  <img className="graycloud" src={Images.graycloud} />
                  <img className="imagecloudsetup" src={Images.cloud} />

                  <div className="borderedinputbox text-xl-center text-sm-start text-center  mb-4 py-3">
                    <div>
                      {/* `${config.backurl}/images/${image}` */}
                      <div className="d-flex align-items-center gap-2 flex-column">
                        {/* { srcs && <div className="browseimg"> */}
                        {/* <ReactCrop src={srcs} onImageLoaded={imageLoaded}
                                crop={crop} onChange={(crop)=>{setCrop(crop)}} onComplete={imageCropComplete}/> */}
                        {/* <img className="img" src={upImg} /> */}
                        <ReactCrop
                          src={upImg}
                          onImageLoaded={onLoad}
                          crop={crop}
                          onChange={(c) => setCrop(c)}
                          onComplete={(c) => setCompletedCrop(c)}
                        />
                        <canvas
                          ref={previewCanvasRef}
                          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                          style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0),
                          }}
                        />

                        {/* <button onClick={() => { cropImage() }}>Crop</button> */}
                        {/* </div>} */}
                        <div className="my-3">
                          <h5 className="uload_log_size mb-0">Preview</h5>
                        </div>
                      </div>
                      {/* <div className="loaderbox">
                        <div>
                          <div className="reimg">
                            <img src={Images.galleryimg} alt="" />
                          </div>
                        </div>
                        <div>
                          <Slider
                            min={0}
                            max={100}
                            value={slide.value}
                            onChange={slidechange}
                            tooltip={false}
                          />
                        </div>
                        <div>
                          <div className="reimg imgtwo">
                            <img src={Images.galleryimg} alt="" />
                          </div>
                        </div>
                      </div> */}
                      <div className="browseinput">
                        {/* <div className="pt-4 pb-1 text-center position-relative">
                          <Button variant="primary" className="signupwallet" onClick={onSubmit}>
                            Save
                          </Button>
                          <img className="signupicon" src={Images.wallet1} />
                        </div> */}
                        {/* <input
                          type="file"
                          className="file"
                          onClick={() => imgsrc()}
                        /> */}
                      </div>
                    </div>
                  </div>

                  <hr className="hrline" />

                  <div className="allimages">
                    <img className="commenticon" src={Images.all} />
                    <img className="youtubeicon" src={Images.youtube} />
                    {/* <div className="pt-4 pb-4 text-center">
                      <Link to="/latestfollow" className=" position-relative">
                        <Button variant="primary" className="signupwallet signupnext">
                          Next
                        </Button>
                        <img className="nexticon nexticon1" src={Images.nect} />
                      </Link>
                    </div> */}
                    <div className='pt-4 pb-4 text-center mx-5'>
                      <Link onClick={onSubmit} className=' position-relative'> 
                      <Button variant="primary" className='signupwallet'>
                        Upload</Button>
                      <img className='signupicon' src={Images.nect} />
                      </Link>
                    </div>
                  </div>

                  <div className="pt-3">
                    <p className="text-center already mb-0">
                      <span className="loginnow">
                        <Link to="/welcome"> Skip Now </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Uploadimage;
