import React, { useState } from 'react';
import Images from '../Images';

const Story = () =>{
    return(
        <div id="story">
          <div className='d-flex jc-between align-items-end px-xl-0 px-3 py-3'>
            <p className='subhead'>Story</p>
            <div> <button className='morebtn'>More</button></div>
           </div>
           <ul className='storylists '>
            <li className='squareimg'>
                <img src={Images.story1} className='img-fluid user' />
                <p className='name'>David</p>
            </li>
            <li className='squareimg'>
                <img src={Images.story2} className='img-fluid user' />
                <p className='name'>Marvel</p>
            </li>
            <li className='squareimg'>
                <img src={Images.story3} className='img-fluid user' />
                <p className='name'>Greg</p>
            </li>
            <li className='squareimg'>
                <img src={Images.story4} className='img-fluid user' />
                <p className='name'>Gru</p>
            </li>
            <li className='squareimg'>
                <img src={Images.story5} className='img-fluid user' />
                <p className='name'>Adam</p>
            </li>

           </ul>
        </div>
    )
}


export default Story