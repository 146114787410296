import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Images from './Images';
import { Dropdown, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import Linksec from './Rightsection.js/Linksec';
import Bio from './Modals/Biomodal';
import Imagemodal from './Modals/ImageModal';
import Videomodal from './Modals/VideoModal';
import CreatePost from './Modals/CreatePost';
import EditPost from './Modals/EditPost';
import DeletePost from './Modals/DeletePost';
import SettingModal from './Modals/SettingModal';
import Message from './Modals/Message';
import { getData } from '../Actions/setProfilepic';
import * as config from "../config/config";
import isEmpty from "is-empty";
import { useSelector, useDispatch } from 'react-redux'
import EditProfileModal from './Modals/EditProfileModal';

const EditProfile = () =>{
const[width, setWidth] = useState()
const[Edit, setEdit] = useState(false)
const[bio, setBio] = useState(false)

const[imagemodal, setImagemodal] = useState(false)
const[videomodal, setVideomodal] = useState(false)
const[createpost, setCreatePost] = useState(false)
const[editpost, setEditPost] = useState(false)
const[deletepost, setDeletePost] = useState(false)
const[setting, setSetting] = useState(false)
const [backgroundImage, set_backgroundImage] = useState("")

const [messagebox, setMessagebox] = useState(false);
const [fname, set_fname] = useState("")
const [lname, set_lname] = useState("")
const [email, set_email] = useState("")
const [Bio, set_bio] = useState("")

const [propic,set_pro] = useState("")
const [background, set_background] = useState("")
const [userName, set_userName] = useState("")
const [displayName, set_displayName] = useState("")
const userDetails= useSelector(state => state.accountDetails.userDetails)
console.log("userDetails",userDetails)


const onChange = (e) => {
  console.log("event", e)
  if (e.target.id == 'firstName')
  set_fname(e.target.value)
  if (e.target.id == 'lastName')
  set_lname(e.target.value)
  if (e.target.id == 'userbio')
  set_bio(e.target.value)
  if (e.target.id == 'background')
  set_background(e.target.value)

};

useEffect(() => {
  userData()
}, [userDetails])


var userData = async () => {
  try {
  //   var res = await getData(dispatch)
  //   if (!isEmpty(userDetails)) {
  //   console.log("user", res,res.data.data.result)
    set_fname(userDetails.firstName)
    set_lname(userDetails.lastName)
    set_email(userDetails.EmailId)
    set_pro(userDetails.profilePicture)
    set_userName(userDetails.userName)
    set_displayName(userDetails.displayName)
    set_bio(userDetails.Bio)
    set_backgroundImage(userDetails.backgroundImage)
  //   }
  //   else{
  //     console.log("check")
  //   }
  }
  catch (err) {
     console.log(err,"err")
  }
} 

useEffect(() => {
  var bgwidth = document.getElementById("centersection").scrollWidth;
  setWidth(bgwidth)
},[])
const Wrapper = styled.section 
  `
  &:before{
    content: "";
    position: fixed;
    background: #fff;
    height: 109px;
    top: 0;
    // left: 15px;
    // right: 0;
    margin: auto;
    z-index: 9;
    width:${width + "px"};
    border-radius: 0 0 11px 11px;
  }
 
  `
  
  const[editprof, setEditProf] = useState(false)

    return(
      <>
       {imagemodal && <Imagemodal onDismiss={() => setImagemodal(false)}/>}
      {videomodal && <Videomodal onDismiss={() => setVideomodal(false)}/>}
      {createpost && <CreatePost onDismiss={() => setCreatePost(false)}/>}
      {editpost && <EditPost onDismiss={() => setEditPost(false)}/>}
      {deletepost && <DeletePost onDismiss={() => setDeletePost(false)}/>}
      {setting && <SettingModal onDismiss={() => setSetting(false)}/>}
      {editprof && <EditProfileModal onDismiss={() => setEditProf(false)}/> }
       {bio && <Bio onDismiss= {() => setBio(false)}/>}

       {messagebox && <Message onDismiss={() => setMessagebox(false)} />}
       
        <section id='centersection' className='posts profile'>
         
          <div className='showin1199 showrightsec'><Linksec/></div>  
          
             
             <div className='postlists'>
            <div className='whitebox '>
                        <div className='profilewall'>
                          <img src={isEmpty(backgroundImage)? Images.profilebg :`${config.backurl}/images/${backgroundImage}`} className='img-fluid br-5 me-1'/> 
                          <div className='wallcont'>
                              <label>Blockchain crypt</label>
                              <h3>{isEmpty(displayName) ? <>newuser</> : displayName } </h3>
                          </div>
                         
                          {Edit ? <button className='btn btn-link p-0 editbtn'> <input type="file" /><span className='fa fa-camera f-12 text-dark'></span></button>: "" }
                        </div>
                          <div className='d-sm-flex jc-between gap-2 p-sm-3 p-2  pb-2 profilestat'>
                          <div className='d-flex'>
                              <div className='d-sm-flex d-none profileedit'>
                                  <img src={isEmpty(propic)? Images.prof1 :`${config.backurl}/images/${propic}`} className='img-fluid br-5 me-1 profimg br-50'/> 
                                  {Edit ? <button className='btn btn-link p-0 editbtn'> <input type="file" /><span className='fa fa-pencil f-12 text-dark'></span></button>: "" }
                              </div> 
                              <div>
                                <p className='d-flex mb-0'> <h5  className='mb-1' >{isEmpty(displayName) ? <>newuser</> : displayName }   </h5>
                                {/* {Edit ?  <button className='btn btn-link p-0 editbtn'><span className='fa fa-pencil f-12 text-secondary'></span>
                                 </button> : ""} */}
                                 </p>
                                  
                                 <p className='d-flex mb-0'>   <h6 className='mb-0 text-muted' >{isEmpty(userName) ? <>newuser</> : userName }
                                 {/* {Edit ?<button className='btn btn-link p-0 editbtn'><span className='fa fa-pencil f-12 text-secondary'></span></button>
                                 : ""} */}
                                 </h6></p>
                                 <p className='d-flex mb-0'>   <p className='f-12 text-muted mb-sm-3 mb-2'>{Bio}</p></p>
                                  {/* {Edit ?<button className='btn btn-link p-0 editbtn'><span className='fa fa-pencil f-12 text-secondary'></span></button> : ""} */}
                                 <div className='d-flex'>
                                  <button className='btn bordbtn me-2'>0 Followers</button>
                                  <button className='btn bordbtn'>0 Following</button>
                                 </div>
                              </div>
                              </div>
                              <div className='mt-sm-0 mt-2'>
                                <button className='morebtn me-2' onClick={() => setEditProf(true)}>Edit Profile</button>
                                <button className='morebtn me-2' onClick={() => setMessagebox(true)}>Message</button>
                                <button className='morebtn px-2'><span className='fa fa-ellipsis-h'></span></button>
                              </div>
                            </div>
                           
                        </div>
                   {/*       <div className='allposts'>
                            <div className='position-relative'>
                              <img src={Images.post2} className='img-fluid' />
                              <div className='d-flex postdet'>
                                  <div className='d-flex jc-between w-full align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Images.story1} className='img-fluid b-50 me-1' />
                                        <div>
                                            <p className='mb-0 text-light f-14 fw-500'>David</p>
                                            <p className='mb-0 text-light'>Lorem ipsum</p>
                                        </div>
                                     </div>
                                     <div>
                                      <button className='linkbtn text-light'><span className='fas fa-ellipsis-v'></span></button>
                                     </div>
                                  </div>
                              </div>
                            </div>
                            <div className='p-3'> 
                              <div className='dopost mb-sm-3 mb-0'>
                              <div className='d-flex jc-between'>
                                    <div className=''>
                                      
                                    <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                                    <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                                    <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                                    <button className='morebtn ms-2 comment'> <span className='commentcount'>1</span> View all comments</button>
                                    <button className='linkbtn ms-sm-2 ms-0 donate '>
                                      <i class="fas fa-hand-holding-medical showin575"></i>
                                    <span className='hidein575'>Donate</span> </button>
                                    </div>
                                    <div className=''>
                                      <button className='morebtn me-1 donate'> 17 minutes</button>
                                      <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                                      <button className='linkbtn px-0 '><img src={Images.feedblue} /></button>
                                    </div>
                                </div>
                              </div>
                              <div className='mobilecomment'>
                               <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                               <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                              </div>
                              <div className='commenttext'>
                                <p>
                              <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                                consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                                Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                                </p>
                              </div>
                              <div>
                              <div className='d-flex gap-1'>
                                <InputGroup className="graysearch ">
                                    <Form.Control
                                      placeholder="What's happening ?"
                                      aria-label="What's happening ?"
                                      aria-describedby="basic-addon2" />
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                </InputGroup>
                              <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                              </div>
                            </div>
                            </div>
                        </div>
                        <div className='allposts'>
                            <div className='position-relative'>
                              <img src={Images.post3} className='img-fluid' />
                              <div className='d-flex postdet'>
                                  <div className='d-flex jc-between w-full align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Images.story1} className='img-fluid b-50 me-1' />
                                        <div>
                                            <p className='mb-0 text-light f-14 fw-500'>David</p>
                                            <p className='mb-0 text-light'>Lorem ipsum</p>
                                        </div>
                                     </div>
                                     <div>
                                      <button className='linkbtn text-light'><span className='fas fa-ellipsis-v'></span></button>
                                     </div>
                                  </div>
                              </div>
                            </div>
                            <div className='p-3'> 
                              <div className='dopost mb-sm-3 mb-0'>
                              <div className='d-flex jc-between'>
                                    <div className=''>
                                      
                                    <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                                    <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                                    <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                                      <button className='morebtn ms-2 comment'> <span className='commentcount'>1</span> View all comments</button>
                                      <button className='linkbtn ms-sm-2 ms-0 donate '>
                                      <i class="fas fa-hand-holding-medical showin575"></i>
                                    <span className='hidein575'>Donate</span> </button>
                                    </div>
                                    <div className=''>
                                      <button className='morebtn me-1 donate'> 17 minutes</button>
                                      <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                                      <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                                    </div>
                                </div>
                              </div>
                              <div className='mobilecomment'>
                               <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                               <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                              </div>
                              <div className='commenttext'>
                                <p>
                              <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                                consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                                Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                                </p>
                              </div>
                              <div>
                              <div className='d-flex gap-1'>
                                <InputGroup className="graysearch ">
                                    <Form.Control
                                      placeholder="What's happening ?"
                                      aria-label="What's happening ?"
                                      aria-describedby="basic-addon2" />
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                </InputGroup>
                              <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                              </div>
                            </div>
                            </div>
                        </div> */}
           {/* <Wrapper className='wrap'> */}
                <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="my-2 themetab" >
                    <Tab eventKey="top" title="Top"> 
                        <div className='whitebox '>
                            <div className='bordbtm px-sm-3 px-2 topsearch'>
                                <div className='pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start'>
                                <span className='f-14 fw-500'>Create Post</span>
                                </div>
                                </div>
                                <div className='d-flex gap-2 p-sm-3 p-2  pb-2'>
                                <InputGroup className="graysearch">
                                    <Form.Control onClick={() => setCreatePost(true)}
                                        placeholder="What's happening ?"
                                        aria-label="What's happening ?"
                                        aria-describedby="basic-addon2" />
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                </InputGroup>
                                <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                                </div>
                            
                        </div>
                        <div className='allposts'>
                                <div className='position-relative'>
                                <img src={Images.post2} className='img-fluid' />
                                <div className='d-flex postdet'>
                                    <div className='d-flex jc-between w-full align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={Images.story1} className='img-fluid b-50 me-1' />
                                            <div>
                                                <p className='mb-0 text-light f-14 fw-500'>David</p>
                                                <p className='mb-0 text-light'>Lorem ipsum</p>
                                            </div>
                                        </div>
                                        <div>
                                        <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setEditPost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className='p-3'> 
                                <div className='dopost mb-sm-3 mb-0'>
                                <div className='d-flex jc-between'>
                                        <div className=''>
                                        
                                        <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                                        <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                                        <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                                        <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                                        <button className='linkbtn ms-sm-2 ms-0 donate '>
                                      <i class="fas fa-hand-holding-medical showin575"></i>
                                    <span className='hidein575'>Donate</span> </button>
                                        </div>
                                        <div className='pt-2'>
                                        <button className='morebtn me-1 donate'> 17 minutes</button>
                                        <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                                        <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='mobilecomment'>
                                <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                                <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                                </div>
                                <div className='commenttext'>
                                    <p>
                                <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                                    consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                                    Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                                    </p>
                                </div>
                                <div>
                                <div className='d-flex gap-1'>
                                    <InputGroup className="graysearch ">
                                        <Form.Control  onClick={() => setCreatePost(true)}
                                        placeholder="What's happening ?"
                                        aria-label="What's happening ?"
                                        aria-describedby="basic-addon2" />
                                        <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                    </InputGroup>
                                <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                                </div>
                                </div>
                                </div>
                        </div>
                        <div className='allposts'>
                                <div className='position-relative'>
                                <img src={Images.post3} className='img-fluid' />
                                <div className='d-flex postdet'>
                                    <div className='d-flex jc-between w-full align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={Images.story1} className='img-fluid b-50 me-1' />
                                            <div>
                                                <p className='mb-0 text-light f-14 fw-500'>David</p>
                                                <p className='mb-0 text-light'>Lorem ipsum</p>
                                            </div>
                                        </div>
                                        <div>
                                        <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setEditPost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className='p-3'> 
                                <div className='dopost mb-sm-3 mb-0'>
                                <div className='d-flex jc-between'>
                                        <div className=''>
                                        
                                        <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                                        <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                                        <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                                        <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                                        <button className='linkbtn ms-sm-2 ms-0 donate '>
                                      <i class="fas fa-hand-holding-medical showin575"></i>
                                    <span className='hidein575'>Donate</span> </button>
                                        </div>
                                        <div>
                                        <button className='morebtn me-1 donate'> 17 minutes</button>
                                        <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                                        <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='mobilecomment'>
                                <p className='mb-0'> 
                                <button className='f-14 btn btn-link p-0'>View all comment</button>
                               
                                </p>
                                <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                                </div>
                                <div className='commenttext'>
                                    <p>
                                <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                                    consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                                    Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                                    </p>
                                </div>
                                <div>
                                <div className='d-flex gap-1'>
                                    <InputGroup className="graysearch ">
                                        <Form.Control  onClick={() => setCreatePost(true)}
                                        placeholder="What's happening ?"
                                        aria-label="What's happening ?"
                                        aria-describedby="basic-addon2" />
                                        <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                    </InputGroup>
                                <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                                </div>
                                </div>
                                </div>
                        </div>
                    </Tab>
                    <Tab eventKey="all" title="All"> 
                    <div className='whitebox '>
                    <div className='bordbtm px-sm-3 px-2 topsearch'>
                            <div className='pt-sm-4 pt-3 px-2 pb-2 mb-0 text-start'>
                      <span className='f-14 fw-500'>Create Post</span>
                            </div>
                            </div>
                            <div className='d-flex gap-2 p-sm-3 p-2  pb-2'>
                              <InputGroup className="graysearch">
                                  <Form.Control  onClick={() => setCreatePost(true)}
                                    placeholder="What's happening ?"
                                    aria-label="What's happening ?"
                                    aria-describedby="basic-addon2" />
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                              </InputGroup>
                            <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                            </div>
                           
                        </div>
                      
                      
                        <div className='allposts'>
                            <div className='position-relative'>
                              <img src={Images.post2} className='img-fluid' />
                              <div className='d-flex postdet'>
                                  <div className='d-flex jc-between w-full align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Images.story1} className='img-fluid b-50 me-1' />
                                        <div>
                                            <p className='mb-0 text-light f-14 fw-500'>David</p>
                                            <p className='mb-0 text-light'>Lorem ipsum</p>
                                        </div>
                                     </div>
                                     <div>
                                     <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setEditPost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                                     </div>
                                  </div>
                              </div>
                            </div>
                            <div className='p-3'> 
                              <div className='dopost mb-sm-3 mb-0'>
                              <div className='d-flex jc-between'>
                                    <div className=''>
                                      
                                    <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                                    <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                                    <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                                      <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                                      <button className='linkbtn ms-sm-2 ms-0 donate '>
                                      <i class="fas fa-hand-holding-medical showin575"></i>
                                    <span className='hidein575'>Donate</span> </button>
                                    </div>
                                    
                                    <div>
                                      <button className='morebtn me-1 donate'> 17 minutes</button>
                                      <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                                      <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                                    </div>
                                </div>
                              </div>
                              <div className='mobilecomment'>
                               <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                               <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                              </div>
                              <div className='commenttext'>
                                <p>
                              <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                                consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                                Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                                </p>
                              </div>
                              <div>
                              <div className='d-flex gap-1'>
                                <InputGroup className="graysearch ">
                                    <Form.Control  onClick={() => setCreatePost(true)}
                                      placeholder="What's happening ?"
                                      aria-label="What's happening ?"
                                      aria-describedby="basic-addon2" />
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                </InputGroup>
                              <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                              </div>
                            </div>
                            </div>
                        </div>
                        <div className='allposts'>
                            <div className='position-relative'>
                              <img src={Images.post3} className='img-fluid' />
                              <div className='d-flex postdet'>
                                  <div className='d-flex jc-between w-full align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Images.story1} className='img-fluid b-50 me-1' />
                                        <div>
                                            <p className='mb-0 text-light'>David</p>
                                            <p className='mb-0 text-light'>Lorem ipsum</p>
                                        </div>
                                     </div>
                                     <div>
                                     <Dropdown className="postdrop">
                    <Dropdown.Toggle variant="" className="linkbtn text-light noarrow" id="dropdown-basic">
                    <span className="fas fa-ellipsis-v"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setEditPost(true)} className="f-12 text-secondary"><span className="fa fa-pencil me-2"></span> Edit post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setDeletePost(true)} className="f-12 text-secondary"><span className="fa fa-trash me-2"></span> Delete post</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSetting(true)} className="f-12 text-secondary"><span className="fa fa-cog me-2"></span> Settings</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                                     </div>
                                  </div>
                              </div>
                            </div>
                            <div className='p-3'> 
                              <div className='dopost mb-sm-3 mb-0'>
                              <div className='d-flex jc-between'>
                                    <div className=''>
                                      
                                    <button className='linkbtn px-0'> <img src={Images.fireblue} className='img-fluid me-1' /></button>
                                    <button className='linkbtn px-0'> <img src={Images.commentblue}  className='img-fluid me-1'/></button>
                                    <button className='linkbtn px-0'> <img src={Images.shareblue}  className='img-fluid me-1'/></button>
                                      <button className='morebtn ms-5 comment'> <span className='commentcount'>1</span> View all comments</button>
                                      <button className='linkbtn ms-sm-2 ms-0 donate '>
                                      <i class="fas fa-hand-holding-medical showin575"></i>
                                    <span className='hidein575'>Donate</span> </button>
                                    </div>
                                    <div>
                                      <button className='morebtn me-1 donate'> 17 minutes</button>
                                      <button className='linkbtn px-0'> <img src={Images.eyeblue}  className='img-fluid me-1'/></button>
                                      <button className='linkbtn px-0'><img src={Images.feedblue} /></button>
                                    </div>
                                </div>
                              </div>
                              <div className='mobilecomment'>
                               <p className='mb-0'> <button className='f-14 btn btn-link p-0'>View all comment</button></p>
                               <p className='mb-0'><button className='f-14 btn btn-link p-0'>1 hours ago</button></p> 
                              </div>
                              <div className='commenttext'>
                                <p>
                              <span ><b>Lorem</b></span> ipsum dolor sit amet consectetur adipisicing elit. Facilis, id ut. Aut 
                                consectetur iure perspiciatis tempora excepturi quia saepe porro illum? 
                                Vel earum, aut sed laboriosam doloribus provident fugit nisi.<button className='themeclr linkbtn'>See more</button>
                                </p>
                              </div>
                              <div>
                              <div className='d-flex gap-1'>
                                <InputGroup className="graysearch ">
                                    <Form.Control  onClick={() => setCreatePost(true)}
                                      placeholder="What's happening ?"
                                      aria-label="What's happening ?"
                                      aria-describedby="basic-addon2" />
                                    <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'> <img src={Images.paperclipblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.imageblue} /></button></InputGroup.Text>
                                  <InputGroup.Text id="basic-addon2"><button className='linkbtn px-0'><img src={Images.smileyblue} /></button></InputGroup.Text>
                                </InputGroup>
                              <div> <button className='themebtn small blue'><img src={Images.shareblue} /></button></div>
                              </div>
                            </div>
                            </div>
                        </div>
                        </Tab>
                    <Tab eventKey="family" title="Family">No Records ! </Tab>
                    <Tab eventKey="friends" title="Friends">No Records ! </Tab>
                    <Tab eventKey="post" title="My Posts">No Records ! </Tab>
                </Tabs>
              {/* </Wrapper> */}
             </div>
             
        </section>
        </>
    )
}

export default EditProfile