import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Images from './Images';
import { Form, InputGroup, Tab, Tabs, Button } from 'react-bootstrap';
import { BsGoogle, BsApple } from 'react-icons/bs';
import OwlCarousel from "react-owl-carousel-rtl";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaRegCopy } from 'react-icons/fa';
import LeftCarousel from './LeftCarousel';
import {login} from '../Actions/login';
import { getData } from '../Actions/setProfilepic';
import * as config from "../config/config";
import isEmpty from "is-empty";
import { useSelector, useDispatch } from 'react-redux'

const Welcome = () => {

    const [checked, setChecked] = useState(false)
    const [fname, set_fname] = useState("")
    const [lname, set_lname] = useState("")
    const [email, set_email] = useState("")
    const [propic,set_pro] = useState("")
    const [userName, set_userName] = useState("")
    const [displayName, set_displayName] = useState("")
    const [formValue, setFormValue] = useState("")
    console.log("formValue_formValue_formValue_formValue",formValue);
    const userDetails= useSelector(state => state.accountDetails.userDetails)
    console.log("userDetails",userDetails)
    const dispatch = useDispatch();
    // const options1 = {
    //     loop: true,
    //     margin: 20,
    //     nav: true,
    //     dots: false,
    //     smartSpeed: 1200,
    //     navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
    //     autoplay: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // }

   
    useEffect(() => {
        userData()
    }, [userDetails])
    
    // useEffect(() => {
    //     if (!isEmpty(userDetails)) {
    //         setFormValue(userDetails)
    //     }
    // }, [userDetails])

    var userData = async () => {
        try {
        //   var res = await getData(dispatch)
        //   if (!isEmpty(userDetails)) {
        //   console.log("user", res,res.data.data.result)
          set_fname(userDetails.firstName)
          set_lname(userDetails.lastName)
          set_email(userDetails.EmailId)
          set_pro(userDetails.profilePicture)
          set_userName(userDetails.userName)
          set_displayName(userDetails.displayName)

        //   }
        //   else{
        //     console.log("check")
        //   }
        }
        catch (err) {
           console.log(err,"err")
        }
      }
    return (
        <>
            <div>
                <div className='signup'>
                    <div className='container'>
                        <div className='row mh-100'>
                            <div className='col-md-5 col-lg-6'>
                            <LeftCarousel />
                                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                                    <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                                </div>
                                <div className='carouselnone'>
                                <div className='position-relative pb-5'>
                                    <img className='imagecloud' src={Images.whitecloud} />
                                </div>
                                <div className='mt-md-5 mt-4'>
                                    <OwlCarousel className='owl-theme' {...options1}>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                    </OwlCarousel>
                                </div>
                                <div className='position-relative pt-5'>
                                    <img className='imagecloud1' src={Images.whitecloud} />
                                </div>
                                </div> */}
                            </div>
                            <div className='col-md-7 col-lg-6'>
                                <div className='signupcard'>
                                    <h4 className='connectheading text-center'>Welcome to DVG</h4>
                                    <p className='subheading text-center mb-4'></p>
                                    <img className='leftgraycloud1' src={Images.gallery} />
                                    <img className='graycloud' src={Images.graycloud} />
                                    <img className='imagecloudsetup' src={Images.cloud} />

                                    <div className='position-relative'>
                                        <img className='img-fluid editimg' src={Images.editimg} />
                                        <img className='img-fluid profileimg br-50' src={isEmpty(propic)? Images.prof1 :`${config.backurl}/images/${propic}`} />
                                    </div>

                                    <div>
                                        <h5 className='profilename'>{displayName}</h5>
                                        <p className='profileid'> {userName}</p>
                                        <p className='mail'><span><img className='img-fluid mailicon' src={Images.mail} /></span> <span>Email:{email}</span> </p>
                                    </div>

                                    <div className='content'>
                                        <p className='mb-0'> Welcome to DVG</p>
                                    </div>

                                    <div className='allimages'>
                                        <img className='commenticon' src={Images.all} />
                                        <img className='youtubeicon' src={Images.youtube} />
                                        {/* <div className='pt-4 pb-4 text-center'>
                                        <Link to="/" className=" position-relative">
                                            <Button variant="primary" className='signupwallet signupnext'>
                                                Get Started</Button>
                                            <img className='nexticon' src={Images.nect} />
                                            </Link>
                                        </div> */}
                                         <div className='pt-4 pb-4 text-center mx-5'>
                                        <Link to="/profile" className=' position-relative'>
                                            <Button variant="primary" className='signupwallet'>
                                                Get Started</Button>
                                            <img className='signupicon' src={Images.nect} />
                                            </Link>
                                        </div>

                                    </div>

                                    <div className='pt-3'>
                                        <p className='text-center already'><span className='loginnow'><Link to="/profile"> Skip Now </Link></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Welcome;