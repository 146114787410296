import React, { useState } from 'react';
import { Dropdown, InputGroup, Modal, Form } from 'react-bootstrap';
import Images from '../Images';

const Unfollow = (props) =>{
    const[unfollow, setUnfollow] = useState(true)
    return(
        <>
         <Modal show={unfollow} className='thememodal' centered>
        <Modal.Header className='jc-center pb-0'>
            <div className='text-center'>
          <Modal.Title className='mb-3'>Unfollow Group</Modal.Title>
          {/* <p className='f-12 text-muted'>Lorem ipsum is a dummy text</p> */}
          </div>
          <button className='fa fa-times btn btn-link' onClick={props.onDismiss}></button>
        </Modal.Header>
        <Modal.Body className='p-4'>
            <img src={Images.graycloud} className='cloud img' />
            <img src={Images.gallery} className='gallery img' />
            {/* <img src={Images.youtube} className='youtube img'/> */}
            <img src={Images.all} className='all img'/>
         
            <h6 className='f-14'>Are you sure unfollow up this group?</h6>
        
        <div className='text-end'>
           <button className='morebtn dark me-2'>Delete</button>
           <button className='morebtn bordered'>Cancel</button>
          </div>
        </Modal.Body>
       
      
      </Modal>
        </>
    )
}

export default Unfollow