import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Images from './Images';
import { Form, InputGroup, Tab, Tabs, Button } from 'react-bootstrap';
import { BsGoogle, BsApple } from 'react-icons/bs';
// import OwlCarousel from "react-owl-carousel-rtl";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import LeftCarousel from './LeftCarousel';
import { MetamaskWallet, WalletConnect } from '../hooks/useWallet'
import isEmpty from 'is-empty';
import { walletregister } from '../Actions/register';
import toast, { Toaster } from 'react-hot-toast';
import axios, {setAuthToken} from '../utils/setAuthtoken';
import { useSelector, useDispatch } from 'react-redux'

const Connectwallet = () => {

    const [address, set_address] = useState('')
console.log("address_data", address)
    const history = useNavigate()
    const dispatch = useDispatch();

    // const options1 = {
    //     loop: true,
    //     margin: 20,
    //     nav: true,
    //     dots: false,
    //     smartSpeed: 1200,
    //     navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
    //     autoplay: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // }
    // const onRedirect = () => {
    //     history('/setupaccount')
    // }

    useEffect(() => {
        console.log("Checking", sessionStorage.getItem("accountInfo"));
        if (sessionStorage.getItem("accountInfo") !== null) {
            set_address(sessionStorage.getItem("accountInfo"))
        }

    }, [sessionStorage.getItem("accountInfo")])


    const onSet = (data) => {
        console.log("onsetdata", data)
        try {
            set_address(data)
        } catch (err) {
            console.log(err, 'data')
        }
    }
    console.log(isEmpty(address), 'address')


    const onSubmit = async (e) => {
        console.log("connect wallet Data")
        try {   
            var logData={
                walletAddress:address
            }      
            var resp = await walletregister(logData,dispatch)
            console.log("login", resp)
            setAuthToken(resp?.data?.data?.token)
            if (resp?.status == 500) {
              toast.error("Error !Try Again...", {
                style: {
                  minWidth: '300px',
                  minHeight: '55px'
                }
              })
            } else if (!isEmpty(resp?.data?.data?.token) && !isEmpty(resp?.data?.data?.result.profilePicture)) {
              history('/profile')
            }
            // else if(!isEmpty(resp.data?.data?.result.)){
            //     history('/profile')
            // 
            else if(!isEmpty(resp?.data?.data?.token) && isEmpty(resp?.data?.data?.result.profilePicture)) {
                history('/setprofilepicture')
            }
            else {  
                history('/setupaccount')                        
            }
    
        }
        catch (err) {
          console.log("login", err)
        }
    
      };

    return (
        <>
            <div>
                <div className='signup'>
                    <div className='container'>
                        <div className='row mh-100'>
                            <div className='col-md-5 col-lg-6'>
                                <LeftCarousel />
                                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                                    <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                                </div>

                                <div className='carouselnone'>
                                <div className='position-relative pb-5'>
                                    <img className='imagecloud' src={Images.whitecloud} />
                                </div>
                                <div className='mt-4'>
                                    <OwlCarousel className='owl-theme' {...options1}>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <div class='item'>
                                            <h2 className='signup-h'>A Block chain<br></br> Social network for you</h2>
                                            <p className='signup-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                    </OwlCarousel>
                                </div>
                                <div className='position-relative pt-5'>
                                    <img className='imagecloud1' src={Images.whitecloud} />
                                </div>
                                </div> */}
                            </div>
                            <div className='col-md-7 col-lg-6'>
                                <div className='signupcard'>
                                    <h4 className='connectheading text-center'>Join DVG today</h4>
                                    <p className='subheading text-center mb-4'></p>
                                    <img className='leftgraycloud' src={Images.gallery} />
                                    <img className='graycloud' src={Images.graycloud} />
                                    <div className='row justify-content-center mt-2'>
                                       {isEmpty(sessionStorage.getItem("wallet")) ? 
                                            <>
                                                <div onClick={() => { MetamaskWallet(onSet) }} className='col-xl-6 col-lg-12 col-md-12 col-sm-6 col-10'>
                                                    <div className='task d-flex align-items-center mt-3'>
                                                        <img className='metaimg' src={Images.meta} />
                                                        <h6 className='name mb-0' >Metamask</h6>
                                                    </div>
                                                </div>
                                                <div onClick={() => { WalletConnect(onSet) }} className='col-xl-6 col-lg-12 col-md-12 col-sm-6 col-10'>
                                                    <div className='task d-flex align-items-center mt-3'>
                                                        <img className='metaimg' src={Images.walletss} />
                                                        <h6 className='name mb-0'>Wallet connect</h6>
                                                    </div>
                                                </div>
                                            </> 
                                            : 
                                            sessionStorage.getItem("wallet") == 'metamask' ? 
                                            <>
                                            <div onClick={() => { MetamaskWallet(onSet) }} className='col-xl-6 col-lg-12 col-md-12 col-sm-6 col-10'>
                                                    <div className='task d-flex align-items-center mt-3'>
                                                        <img className='metaimg' src={Images.meta} />
                                                        <h6 className='name mb-0' >Metamask</h6>
                                                    </div>
                                                </div>
                                            </> 
                                            :
                                            sessionStorage.getItem("wallet") == 'connect' ? 
                                                    <>
                                                        <div onClick={() => { WalletConnect(onSet) }} className='col-xl-6 col-lg-12 col-md-12 col-sm-6 col-10'>
                                                            <div className='task d-flex align-items-center mt-3'>
                                                                <img className='metaimg' src={Images.walletss} />
                                                                <h6 className='name mb-0'>Wallet connect</h6>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :''
                                       }
                                       
                                        {/* <div className='col-xl-6 col-lg-12 col-md-12 col-sm-6 col-10'>
                                        <div className='task d-flex align-items-center mt-3'>
                                            <img className='metaimg' src={Images.trust} />
                                                <h6 className='name mb-0'>Trust Wallet</h6>
                                            </div>
                                        </div>
                                        <div className='col-xl-6 col-lg-12 col-md-12 col-sm-6 col-10'>
                                        <div className='task d-flex align-items-center mt-3'>
                                            <img className='metaimg' src={Images.coin} />
                                                <h6 className='name mb-0'>Coin Base</h6>
                                            </div>
                                        </div> */}
                                        
                                    </div>

                                    {/* <div className='bordbt'>
                                        <p className='or'>OR</p>
                                    </div> */}
                                    <div className='allimages'>
                                        <img className='imagecloud' src={Images.cloud} />
                                        <img className='darkgraycloud' src={Images.darkgraycloud} />
                                        <img className='commenticon' src={Images.all} />
                                        <img className='youtubeicon' src={Images.youtube} />
                                        <div className='pt-5 pb-4 text-center position-relative'>
                                        
                                            {isEmpty(address) ?
                                            <Link  className='position-relative' onClick={onSubmit}>
                                                <Button  className='signupwallet' disabled>
                                                    Connect
                                                </Button>
                                                <img className='signupicon' src={Images.wallet1} />
                                            </Link> :
                                                   <Link  className='position-relative' onClick={onSubmit}>
                                                    <Button  className='signupwallet' >
                                                        Proceed
                                                    </Button>
                                                    <img className='signupicon' src={Images.wallet1} />
                                                     </Link>
                                                }
                                           

                                        </div>
                                        <div className='pt-3 pb-3 text-center position-relative'>
                                            <Link to="/login" className='position-relative'>
                                                <Button variant="primary" className='passwordss'>
                                                    Cancel</Button>
                                            </Link>
                                            <img className='signupicon1' src={Images.password} />
                                        </div>
                                    </div>

                                    <div className='pt-3'>
                                        <p className='text-center already'><span>Already a Lander?</span> <span className='loginnow'><Link to="/login"> Login Now </Link></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Connectwallet;