import React from 'react';
import Images from '../Images';


const Profilephotos = (props) =>{

 

    return(
        <>
    
                <div className='card card_img'>
                    <div className='card-body p-0'>
                    <img src={props.image} className='img-fluid'/> 

                    </div>
                </div>
         
        </>
    )
}

export default Profilephotos