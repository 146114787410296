import React, { useState, useEffect } from 'react';
import LeftSection from '../leftsection/LeftSection';
import RightSection from '../Rightsection.js/RightSection';
import Images from '../Images';
import Header from '../Header';
import { Link } from 'react-router-dom';
import Linksec from '../Rightsection.js/Linksec';

const AllPlaces = () =>{
    const[showbar, setShowBar] = useState(true)
  useEffect(() => {
    if(window.innerWidth <= 1199 ){
      setShowBar(false)
    }
  },[window.innerWidth])
  useEffect(() => {
    if(window.innerWidth <= 1199){
    if(showbar == false){
      document.getElementById("content").style.overflowY = "auto "
    }else{
      document.getElementById("content").style.overflowY = " hidden"
    }}
  },[showbar])
    return(
        <>
         <Header />
        <section id="home" className='friends allfriends'>
        <button className='bars showin1199 linkbtn' onClick={() => setShowBar(!showbar)}><img src={Images.bars} /></button>
        <div className='themecontainer'>
            <div className='themewidth'>
                <LeftSection />
                    <div id="content" className=' w-full d-flex'>
                        <section id='centersection' className='posts'>
                        <div className='showin1199 showrightsec'><Linksec /></div>  
                        <div className='postlists request'> 
                            <div className=' p-3'>
                                <ul className='hoverlists'>
                                    <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                        <div className='d-flex w-full align-items-center'>
                                            <img src={Images.story3} className='friendimg'/>
                                            <div className='w-full'>
                                                <Link to="/placeprof" className='nostyle text-dark'>
                                                <h6 className='mb-sm-0 mb-2'>London House/Flat/Spare room to rent</h6></Link>
                                                <Link to="/placeprof"  className='nodecore'> <button className='morebtn w-full showin575'>View</button></Link>
                                            <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                                         <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            </div>
                                        </div>
                                        <div>
                                           <Link to="/placeprof"  className='rightbtn'> <button className='morebtn '>View</button></Link>
                                        </div>
                                    </li>
                                    <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                        <div className='d-flex w-full align-items-center'>
                                            <img src={Images.story1} className='friendimg'/>
                                            <div className='w-full'>
                                                <Link to="/placeprof" className='nostyle text-dark'>
                                                <h6 className='mb-sm-0 mb-2'>London House/Flat/Spare room to rent</h6></Link>
                                                <Link to="/placeprof"  className='nodecore'> <button className='morebtn w-full showin575'>View</button></Link>
                                            <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                                         <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            </div>
                                        </div>
                                        <div>
                                           <Link to="/placeprof"  className='rightbtn'> <button className='morebtn '>View</button></Link>
                                        </div>
                                    </li>
                                    <li className='d-flex jc-between align-items-center  gap-2 whitebox p-3'>
                                        <div className='d-flex w-full align-items-center'>
                                            <img src={Images.story2} className='friendimg'/>
                                            <div className='w-full'>
                                                <Link to="/placeprof" className='nostyle text-dark'>
                                                <h6 className='mb-sm-0 mb-2'>London House/Flat/Spare room to rent</h6></Link>
                                                <Link to="/placeprof"  className='nodecore'> <button className='morebtn w-full showin575'>View</button></Link>
                                            <p className='mb-0 f-12 text-muted resultcont'>Public group . 363k members . 10+ posts a day</p>
                                         <p className='mb-0 f-12 text-muted resultcont'>lorem ipsum is a dummy content</p>
                                            </div>
                                        </div>
                                        <div>
                                           <Link to="/placeprof"  className='rightbtn'> <button className='morebtn '>View</button></Link>
                                        </div>
                                    </li>
                          
                                </ul>
                           
                            </div>

                         
                        </div>
                        </section>
                        {showbar && <RightSection />}
                    </div>
            </div>
        </div>
        </section>
        </>
    )
}

export default AllPlaces