import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Images from "./Images";
import { Form, InputGroup, Tab, Tabs, Button } from "react-bootstrap";
import { BsGoogle, BsApple } from "react-icons/bs";
// import OwlCarousel from "react-owl-carousel-rtl";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LeftCarousel from "./LeftCarousel";

const Latestfollow = () => {
  // const options1 = {
  //   loop: true,
  //   margin: 20,
  //   nav: true,
  //   dots: false,
  //   smartSpeed: 1200,
  //   navText: [
  //     "<i class='fa fa-arrow-left'></i>",
  //     "<i class='fa fa-arrow-right'></i>",
  //   ],
  //   autoplay: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 1,
  //     },
  //     1000: {
  //       items: 1,
  //     },
  //   },
  // };

  return (
    <>
      <div>
        <div className="signup">
          <div className="container">
            <div className="row mh-100">
              <div className="col-md-5 col-lg-6">
              <LeftCarousel />
                {/* <div id="logo" className='innersec logosec pt-4 pb-4 d-flex align-items-center'>
                  <Link to="/"><img src={Images.logos} className='img-fluid dvglogo' /><span className='ms-2 title'>DVG</span></Link>
                </div>

                <div className='carouselnone'>
                <div className="position-relative pb-5">
                  <img className="imagecloud" src={Images.whitecloud} />
                </div>
                <div className="mt-md-5 mt-4">
                  <OwlCarousel className="owl-theme" {...options1}>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <div class="item">
                      <h2 className="signup-h">
                        A Block chain<br></br> Social network for you
                      </h2>
                      <p className="signup-para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                  </OwlCarousel>
                </div>
                <div className="position-relative pt-5">
                  <img className="imagecloud1" src={Images.whitecloud} />
                </div>
                </div> */}
              </div>
              <div className="col-md-7 col-lg-6">
                <div className="signupcard">
                  <h4 className="connectheading text-center">Latest Follow</h4>
                  <p className="subheading text-center mb-4">
                    Lorem Ipsum is simply dummy text of the printing
                  </p>
                  <img className="leftgraycloud" src={Images.gallery} />
                  <img className="graycloud" src={Images.graycloud} />
                  <img className="imagecloudsetup" src={Images.cloud} />

                  <div className="followbox">
                    <ul className="follows">
                      <li className="followlist">
                        <div className="flexbox">
                          <div>
                            <div className="flexb">
                              <div>
                                <img className="followimg" src={Images.follower} />
                              </div>
                              <div>
                                <h6 className="h6">David Marvel</h6>
                                <p className="fcontent">
                                  Lorem Ipsum is simply dummy text
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button variant="primary" className="followbtn">
                              Follow
                            </Button>
                          </div>
                        </div>
                      </li>
                      <li className="followlist">
                        <div className="flexbox">
                          <div>
                            <div className="flexb">
                              <div>
                                <img className="followimg" src={Images.follower} />
                              </div>
                              <div>
                                <h6 className="h6">David Marvel</h6>
                                <p className="fcontent">
                                  Lorem Ipsum is simply dummy text
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button variant="primary" className="followbtn">
                              Follow
                            </Button>
                          </div>
                        </div>
                      </li>
                      <li className="followlist">
                        <div className="flexbox">
                          <div>
                            <div className="flexb">
                              <div>
                                <img className="followimg" src={Images.follower} />
                              </div>
                              <div>
                                <h6 className="h6">David Marvel</h6>
                                <p className="fcontent">
                                  Lorem Ipsum is simply dummy text
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button variant="primary" className="followbtn">
                              Follow
                            </Button>
                          </div>
                        </div>
                      </li>
                      <li className="followlist">
                        <div className="flexbox">
                          <div>
                            <div className="flexb">
                              <div>
                                <img className="followimg" src={Images.follower} />
                              </div>
                              <div>
                                <h6 className="h6">David Marvel</h6>
                                <p className="fcontent">
                                  Lorem Ipsum is simply dummy
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button variant="primary" className="followbtn">
                              Follow
                            </Button>
                          </div>
                        </div>
                      </li>
                      <li className="followlist">
                        <div className="flexbox">
                          <div>
                            <div className="flexb">
                              <div>
                                <img className="followimg" src={Images.follower} />
                              </div>
                              <div>
                                <h6 className="h6">David Marvel</h6>
                                <p className="fcontent">
                                  Lorem Ipsum is simply dummy text
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button variant="primary" className="followbtn">
                              Follow
                            </Button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <hr className="hrline" />

                  <div className="allimages">
                    {/* <img className='imagecloud' src={Images.cloud} /> */}
                    {/* <img className='darkgraycloud' src={Images.darkgraycloud} /> */}
                    <img className="commenticon" src={Images.all} />
                    <img className="youtubeicon" src={Images.youtube} />
                    {/* <div className="pt-4 pb-4 text-center">
                      <Link to="/welcome"  className=" position-relative">
                        <Button variant="primary" className="signupwallet signupnext">
                          Next
                        </Button>
                        <img className="nexticon nexticon1" src={Images.nect} />
                      </Link>
                    </div> */}
                     <div className='pt-4 pb-4 text-center mx-5'>
                                        <Link to="/welcome" className=' position-relative'>
                                            <Button variant="primary" className='signupwallet'>
                                                Next</Button>
                                            <img className='signupicon' src={Images.nect} />
                                            </Link>
                                        </div>
                  </div>

                  <div className="pt-3">
                    <p className="text-center already mb-0">
                      <span className="loginnow">
                        <Link to="/welcome"> Skip Now </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Latestfollow;
