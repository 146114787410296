 

// export const frontend = "http://localhost:3000";
// export const backurl = "http://localhost:8080";
export const frontend =  "https://dvg.maticz.in";
export const backurl = "https://backend-dvg.maticz.in";
export const ONEDAYINSECONDS = 86400;

export const BURN = "0x000000000000000000000000000000000000dEaD";

export const BLOCKS_PER_YEAR = 10512000;

export const PROJECT_ID = 'b8a1daa2dd22335a2fe1d2e139980ae0';

export const CHAINS = [


    // {
    //   NAME: "BSC-TEST",
    //   RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
    //   CHAIN_ID: 97,
    //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    //   // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
    //   MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
    //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    // {
    //   NAME: "AVALANCHE",
    //   RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc", // ETH TESTNET
    //   CHAIN_ID: 43113,
    //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    //   MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",

    //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    //   {
    //     NAME: "Polygon-TEST",
    //     RPC_URL:"https://matic-mumbai.chainstacklabs.com",
    //     CHAIN_ID:80001, 
    //     TOKENADDRESS: "0x9433f3BaAE2984C6F5C8DE6989aB6dee23eaEE17",
    //      MASTERCHEF: "0x74962D8c90dCb1e4761DF94115BE449044179e5A", // new 
    //     // MASTERCHEF: "0x1afd408a544e901af55cB8735d8c6B49c47A11b2",//old
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    //   {
    //     NAME: "BSC-MAINNET",
    //     RPC_URL:"https://bsc-dataseed1.binance.org/",
    //     CHAIN_ID:56,
    //     TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781",
    //     MASTERCHEF: "0x825A21c5A32d22b1241C7672EC9D04025960f766", 
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    {
        NAME: "BSC-MAINNET",
        RPC_URL: "https://bsc-dataseed1.binance.org/",
        CHAIN_ID: 56,
        TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781", //irea
        MASTERCHEF: "0x5fb7846a3fD122De5feb59bb1Ef67F742a6804d8",
        NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
        USDT: "0x55d398326f99059ff775485246999027b3197955",
        USDI: "0x91c7eBbDcbdE50559FDA424dd7Dc9AF142B9Ed58"
    },

];
